import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import type { Country } from "./country.model"

type Err = string
type Ok = { countries: Country[] }

export class CountriesStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("CountriesStore", initial)
  }
}
