import { IsArray, IsNotEmpty, IsString } from "class-validator"
import { ProductKind } from "~/proposals/models/product.model"
import { IsNullable } from "~/utils/utils.validation"

export class Instrument {
  @IsNotEmpty()
  @IsString()
  readonly _id: string

  @IsNotEmpty()
  @IsString()
  readonly name: string

  @IsString()
  @IsNullable()
  readonly acronym: string | null

  @IsString()
  @IsNullable()
  readonly url: string | null

  @IsArray()
  readonly products: ProductKind[]
}
