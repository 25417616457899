import { type Language, type Locale, type Region } from "./locale.model"

export const DEFAULT_LANGUAGE: Language = {
  code: "en",
  name: "English",
} as const
export const DEFAULT_REGION: Region = {
  code: "de-de",
  adapterLocale: "de",
  name: "Germany",
} as const
export const DEFAULT_LOCALE: Locale = {
  language: DEFAULT_LANGUAGE,
  region: DEFAULT_REGION,
}

export const languages: Language[] = [
  { code: "en", name: "English" },
  { code: "de", name: "German" },
  { code: "it", name: "Italian" },
  { code: "fr", name: "French" },
  { code: "es", name: "Spanish" },
] as const

export const regions: Region[] = [
  { code: "de-de", adapterLocale: "de", name: "Germany" },
  { code: "it-it", adapterLocale: "it", name: "Italy" },
  { code: "fr-fr", adapterLocale: "fr", name: "France" },
  { code: "es-es", adapterLocale: "es", name: "Spain" },
  { code: "en-gb", adapterLocale: "en-gb", name: "United Kingdom" },
  { code: "en-us", adapterLocale: "en", name: "United States" },
] as const

export const toLanguage = (value: string): Language => {
  const languageCodes = languages.map((language) => language.code)

  if (!languageCodes.includes(value as Language["code"])) {
    return DEFAULT_LANGUAGE
  }

  return languages.find((language) => language.code === value)!
}

export const toRegion = (value: string): Region => {
  const regionCodes = regions.map((region) => region.code)

  if (!regionCodes.includes(value as Region["code"])) {
    return DEFAULT_REGION
  }

  return regions.find((region) => region.code === value)!
}

export const toLocale = (localeString = "en-de"): Locale => {
  const [languageString, regionString] = localeString.toLowerCase().split("-")

  const language = toLanguage(languageString)
  const region = toRegion(regionString)

  return { language, region }
}

export function dateFormatter(
  locale = DEFAULT_LOCALE,
  options?: Intl.DateTimeFormatOptions,
) {
  const formatter = new Intl.DateTimeFormat(
    [locale.region.code, locale.language.code, DEFAULT_REGION.code],
    options,
  )

  return (date: Date) => formatter.format(date)
}

export function numberFormatter(
  locale = DEFAULT_LOCALE,
  options?: Intl.NumberFormatOptions,
) {
  const formatter = new Intl.NumberFormat(
    [locale.region.code, locale.language.code, DEFAULT_REGION.code],
    options,
  )

  return (value: number) => formatter.format(value)
}

export function currencyFormatter(locale = DEFAULT_LOCALE, currency: string) {
  return numberFormatter(locale, {
    style: "currency",
    currency,
  })
}

export function getDecimalSeparator(locale = DEFAULT_LOCALE) {
  const formatter = new Intl.NumberFormat([
    locale.region.code,
    locale.language.code,
    DEFAULT_REGION.code,
  ])
  const result = formatter.format(1.1)

  return result[1]
}

export function getThousandsSeparator(locale = DEFAULT_LOCALE) {
  const formatter = new Intl.NumberFormat([
    locale.region.code,
    locale.language.code,
    DEFAULT_REGION.code,
  ])
  const result = formatter.format(1000)

  return result[1]
}

export function getSeparators(locale = DEFAULT_LOCALE) {
  const decimal = getDecimalSeparator(locale)
  const thousands = getThousandsSeparator(locale)

  return { decimal, thousands }
}
