import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Box, Button } from "@mui/material"
import { useLocation } from "react-router-dom"
import { useAppContext } from "~/app.use-app-context.hook"
import { ProposalKind } from "~/proposals/models/proposal.model"

const baseStyle = {
  px: 1,
  color: "white",
}

const activeStyle = {
  ...baseStyle,
  backgroundColor: "white",
  color: "primary.main",
  "&:hover": { backgroundColor: "white" },
}

export function Navigation() {
  const { companiesModule } = useAppContext()
  const { pathname } = useLocation()
  const proposalKind = pathname.includes(ProposalKind.OFFER.toLowerCase())
    ? ProposalKind.OFFER
    : ProposalKind.REQUEST

  return (
    <Box component="nav" sx={{ display: "flex", gap: 1 }}>
      <Button
        href="/proposals/requests"
        sx={proposalKind === ProposalKind.REQUEST ? activeStyle : baseStyle}
      >
        Requests
      </Button>
      <Button
        href="/proposals/offers"
        sx={proposalKind === ProposalKind.OFFER ? activeStyle : baseStyle}
      >
        Offers
      </Button>
      <Button
        href={companiesModule.config.hubSpotCompaniesUrl}
        rel="noopener"
        target="_blank"
        endIcon={<OpenInNewIcon />}
        sx={{
          color: "white",
          "& .MuiButton-endIcon": { transform: "scale(0.75)" },
        }}
      >
        Companies
      </Button>
    </Box>
  )
}
