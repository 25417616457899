import { IsNotEmpty, IsString } from "class-validator"

export class Registry {
  @IsString()
  @IsNotEmpty()
  readonly _id: string

  @IsNotEmpty()
  @IsString()
  readonly acronym: string

  @IsNotEmpty()
  @IsString()
  readonly description: string
}
