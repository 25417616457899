import { useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"

export function useIsAuthenticated() {
  const { authModule } = useAppContext()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    authModule.service.isAuthenticated,
  )

  authModule.store.addListener(() => {
    setIsAuthenticated(authModule.service.isAuthenticated)
  })

  return { isAuthenticated }
}
