import { HttpModule } from "~/http/http.module"
import { RegionsConfig } from "./regions.config"
import { RegionsService } from "./regions.service"
import { RegionsStore } from "./regions.store"

export class RegionsModule {
  private readonly _regionsConfig: RegionsConfig
  private readonly _regionsStore: RegionsStore
  private readonly _regionsService: RegionsService

  constructor(private readonly httpModule: HttpModule) {
    this._regionsConfig = new RegionsConfig()
    this._regionsStore = new RegionsStore()
    this._regionsService = new RegionsService(
      this.httpModule.service,
      this._regionsConfig,
      this._regionsStore,
    )
  }

  public get service() {
    return this._regionsService
  }

  public get store() {
    return this._regionsStore
  }
}
