import type { HttpModule } from "~/http/http.module"
import { RegistriesConfig } from "./registries.config"
import { RegistriesService } from "./registries.service"
import { RegistriesStore } from "./registries.store"

export class RegistriesModule {
  private readonly _registriesConfig: RegistriesConfig
  private readonly _registriesStore: RegistriesStore
  private readonly _registriesService: RegistriesService

  constructor(private readonly httpModule: HttpModule) {
    this._registriesConfig = new RegistriesConfig()
    this._registriesStore = new RegistriesStore()
    this._registriesService = new RegistriesService(
      this.httpModule.service,
      this._registriesConfig,
      this._registriesStore,
    )
  }

  public get store() {
    return this._registriesStore
  }

  public get service() {
    return this._registriesService
  }
}
