/* eslint-disable @typescript-eslint/no-unused-vars */

import { instanceToPlain, plainToInstance } from "class-transformer"
import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import { useFilesStore } from "~/files/files.use-files-store.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { useUiStore } from "~/ui/ui.use-ui-store.hook"
import { CreateProposalDto, type UpdateProposalDto } from "./dtos/proposal.dto"
import {
  BaseProposal,
  InitialProposal,
  MatchedProposal,
  Proposal,
  ProposalKind,
  State as ProposalState,
} from "./models/proposal.model"
import { ProposalForm } from "./views/form-views/proposal.form.view"

type State = {
  proposals: Proposal[]
  isLoading: boolean
  error: string | null
}

function cloneProposal<T extends BaseProposal>(
  proposal: Proposal,
  action: "duplicate" | "respond" | "update",
): T {
  switch (action) {
    case "duplicate": {
      const { _id, go2Id, createdAt, createdBy, updatedAt, company, ...rest } =
        instanceToPlain(proposal)

      return plainToInstance(BaseProposal, { ...rest, company: null }) as T
    }

    case "respond": {
      const { _id, go2Id, createdAt, createdBy, updatedAt, company, ...rest } =
        instanceToPlain(proposal)
      return plainToInstance(BaseProposal, { ...rest, company: null }) as T
    }

    case "update": {
      switch (proposal.state) {
        case ProposalState.INITIAL: {
          return plainToInstance(
            InitialProposal,
            instanceToPlain(proposal),
          ) as unknown as T
        }

        case ProposalState.MATCHED: {
          return plainToInstance(
            MatchedProposal,
            instanceToPlain(proposal),
          ) as unknown as T
        }

        default: {
          throw new Error("Not implemented")
        }
      }
    }
  }
}

export function useProposalsStore() {
  const {
    proposalsModule: { service, store },
  } = useAppContext()
  const {
    actions: { openDrawer, addMenuItem, clearMenuItems },
  } = useUiStore()
  const {
    actions: { setFiles },
  } = useFilesStore()
  const [state, setState] = useState<State>({
    proposals: withSuccess(store.state, [], ({ proposals }) => proposals),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          proposals: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ proposals }) => {
        setState({ proposals, isLoading: false, error: null })

        clearMenuItems()

        proposals.forEach((proposal) => {
          addMenuItem(proposal._id, {
            label: "Duplicate Proposal",
            action: () => {
              const clone = cloneProposal(proposal, "duplicate")

              setFiles(clone.attachments)

              openDrawer(
                <ProposalForm
                  mode={{
                    kind: "duplicate",
                    proposal: clone,
                  }}
                  kind={proposal.kind}
                />,
              )
            },
          })

          addMenuItem(proposal._id, {
            label:
              proposal.kind === ProposalKind.REQUEST
                ? "Respond with Offer"
                : "Respond with Request",
            action: () => {
              const clone = cloneProposal(proposal, "respond")

              setFiles(clone.attachments)

              openDrawer(
                <ProposalForm
                  mode={{
                    kind: "duplicate",
                    proposal: {
                      ...clone,
                      kind:
                        proposal.kind === ProposalKind.REQUEST
                          ? ProposalKind.OFFER
                          : ProposalKind.REQUEST,
                    },
                  }}
                  kind={
                    proposal.kind === ProposalKind.REQUEST
                      ? ProposalKind.OFFER
                      : ProposalKind.REQUEST
                  }
                />,
              )
            },
          })
        })
      },
    }),
  )

  const fetchAllProposals = useCallback(async () => {
    await service.fetchAll()
  }, [service])

  const fetchManyProposals = useCallback(
    async (ids: string[]) => {
      await service.fetchMany(ids)
    },
    [service],
  )

  const fetchProposal = useCallback(
    async (id: string) => {
      await service.fetchOne(id)
    },
    [service],
  )

  const createProposal = useCallback(
    async (proposal: CreateProposalDto) => {
      await service.create(proposal)
    },
    [service],
  )

  const updateProposal = useCallback(
    async (_id: string, proposal: UpdateProposalDto) => {
      await service.update(_id, proposal)
    },
    [service],
  )

  const archiveProposal = useCallback(
    async (proposal: Proposal) => {
      await service.archive(proposal)
    },
    [service],
  )

  return {
    state,
    actions: {
      fetchAllProposals,
      fetchManyProposals,
      fetchProposal,
      archiveProposal,
      cloneProposal,
      createProposal,
      updateProposal,
    },
  }
}
