import { HttpModule } from "~/http/http.module"
import { FilesService } from "./files.service"
import { FilesStore } from "./files.store"

export class FilesModule {
  private readonly filesStore: FilesStore
  private readonly filesService: FilesService

  constructor(private readonly httpModule: HttpModule) {
    this.filesStore = new FilesStore()
    this.filesService = new FilesService(
      this.httpModule.service,
      this.filesStore,
    )
  }

  get store() {
    return this.filesStore
  }

  get service() {
    return this.filesService
  }
}
