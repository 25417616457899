import { AppBar, Box, Link, Toolbar, Typography } from "@mui/material"
import { useIsAuthenticated } from "~/auth/auth.use-is-authenticated.hook"
import { Navigation } from "~/navigation/navigation.view"
import { NotificationMenu } from "~/notifications/components/NotificationMenu"
import { UserMenu } from "~/user/user.user-menu.component"
import { Logo } from "./ui.logo.component"

export function Header() {
  const { isAuthenticated } = useIsAuthenticated()

  return (
    <AppBar
      sx={{
        maxWidth: "1920px",
        position: "fixed",
        top: 0,
        right: "auto",
        height: "4rem",
        width: "100%",
        px: "1.5rem",
        zIndex: 10,
      }}
    >
      <Toolbar disableGutters>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link
            href="/"
            id="logo"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              width: "8rem",
            }}
          >
            <Logo />
            <Typography
              variant="h6"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              TradingOS
            </Typography>
          </Link>
          {isAuthenticated && <Navigation />}
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            {isAuthenticated && <NotificationMenu />}
            {isAuthenticated && <UserMenu />}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
