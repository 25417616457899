import type { Language, Locale, Region } from "./locale.model"
import type { LocaleStore } from "./locale.store"

export class LocaleService {
  constructor(private readonly localeStore: LocaleStore) {
    this.hydrateLocale()
  }

  public setLanguage(language: Language) {
    this.localeStore.state = { language }
    localStorage.setItem("locale", JSON.stringify(this.localeStore.state))
  }

  public setRegion(region: Region) {
    this.localeStore.state = { region }
    localStorage.setItem("locale", JSON.stringify(this.localeStore.state))
  }

  private hydrateLocale() {
    const locale = localStorage.getItem("locale")

    if (locale) {
      this.localeStore.state = JSON.parse(locale) as Locale
    }
  }
}
