import { UiService } from "./ui.service"
import { UiStore } from "./ui.store"

export class UiModule {
  private readonly _store: UiStore
  private readonly _service: UiService

  constructor() {
    this._store = new UiStore()
    this._service = new UiService(this._store)
  }

  get store() {
    return this._store
  }

  get service() {
    return this._service
  }
}
