import { FormControlLabel, Switch } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "../../proposals.context"

export function TransferabilityField() {
  const { control } = useContext(ProposalFormContext)

  return (
    <Controller
      control={control}
      name="product.transferability"
      render={({ field: { value, onChange } }) => {
        return (
          <FormControlLabel
            control={
              <Switch
                value={value ?? false}
                onChange={(_, newValue) => onChange(newValue)}
                color="primary"
              />
            }
            label="Transferability"
          />
        )
      }}
    />
  )
}
