import { HttpModule } from "~/http/http.module"
import { CompaniesConfig } from "./companies.config"
import { CompaniesService } from "./companies.service"
import { CompaniesStore } from "./companies.store"

export class CompaniesModule {
  private readonly _companiesConfig: CompaniesConfig
  private readonly _companiesStore: CompaniesStore
  private readonly _companiesService: CompaniesService

  constructor(private readonly httpModule: HttpModule) {
    this._companiesConfig = new CompaniesConfig()
    this._companiesStore = new CompaniesStore()
    this._companiesService = new CompaniesService(
      this.httpModule.service,
      this._companiesConfig,
      this._companiesStore,
    )
  }

  get config() {
    return this._companiesConfig
  }

  get store() {
    return this._companiesStore
  }

  get service() {
    return this._companiesService
  }
}
