import { Type } from "class-transformer"
import { IsArray, ValidateNested } from "class-validator"
import type { Attachment } from "~/files/attachment.model"
import type { HttpDto } from "~/http/http.models"
import {
  BiogasProduct,
  CarbonProduct,
  GuaranteeOfOriginProduct,
  IntRecProduct,
  ProductKind,
  UsRecProduct,
  WhiteCertProduct,
} from "../models/product.model"
import {
  BaseProposal,
  InitialProposal,
  MatchedProposal,
  ProposalKind,
  State,
  type Proposal,
} from "../models/proposal.model"
import {
  FirmPriceRequest,
  IndicativePriceRequest,
  OngoingPriceRequest,
  PriceRequestKind,
  type PriceRequest,
  type RfqPriceRequest,
} from "../price-requests/price-request.model"

export class InitialProposalDto implements HttpDto<InitialProposal> {
  @ValidateNested()
  @Type(() => InitialProposal)
  declare readonly data: InitialProposal
}

export class MatchedProposalDto implements HttpDto<MatchedProposal> {
  @ValidateNested()
  @Type(() => MatchedProposal)
  declare readonly data: MatchedProposal
}

export class ProposalDto implements HttpDto<Proposal> {
  @ValidateNested()
  @Type(() => BaseProposal, {
    discriminator: {
      property: "state",
      subTypes: [
        { value: InitialProposal, name: State.INITIAL },
        { value: MatchedProposal, name: State.MATCHED },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  declare readonly data: Proposal
}

export class ProposalsDto implements HttpDto<Proposal[]> {
  @ValidateNested({ each: true })
  @Type(() => BaseProposal, {
    discriminator: {
      property: "state",
      subTypes: [
        { value: InitialProposal, name: State.INITIAL },
        { value: MatchedProposal, name: State.MATCHED },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @IsArray()
  declare readonly data: Proposal[]
}

export class CreateProposalDto {
  readonly state: State
  readonly kind: ProposalKind
  readonly attachments: Attachment[]
  readonly createdBy: string
  readonly company: string
  readonly countries: string[]
  readonly notes: string | null
  readonly volume: number
  readonly priceRequest:
    | (Omit<FirmPriceRequest, "currency"> & { currency: string })
    | (Omit<RfqPriceRequest, "currency"> & { currency: null })
    | (Omit<IndicativePriceRequest, "currency"> & {
        currency: string | null
      })
    | (Omit<OngoingPriceRequest, "currency"> & { currency: string | null })
  readonly product:
    | (Omit<BiogasProduct, "labels" | "input"> & {
        labels: string[]
        input: string
      })
    | (Omit<
        CarbonProduct,
        "instruments" | "technologies" | "labels" | "sdgs"
      > & {
        instruments: string[]
        technologies: string[]
        labels: string[]
        sdgs: string[]
      })
    | (Omit<WhiteCertProduct, "instruments" | "technologies"> & {
        instruments: string[]
        technologies: string[]
      })
    | (Omit<GuaranteeOfOriginProduct, "labels" | "technologies"> & {
        labels: string[]
        technologies: string[]
      })
    | (Omit<IntRecProduct, "labels" | "technologies"> & {
        labels: string[]
        technologies: string[]
      })
    | (Omit<UsRecProduct, "labels" | "technologies" | "registries"> & {
        labels: string[]
        technologies: string[]
        registries: string[]
      })

  constructor(data: BaseProposal) {
    this.state = State.INITIAL
    this.kind = data.kind
    this.attachments = data.attachments
    this.createdBy = data.createdBy._id
    this.company = data.company.id
    this.countries = data.countries.map((country) => country._id)
    this.notes = data.notes
    this.volume = data.volume
    this.priceRequest = this.mapPriceRequest(data.priceRequest)
    this.product = this.mapProduct(data.product)
  }

  private mapPriceRequest(data: PriceRequest) {
    if (data.kind === PriceRequestKind.FIRM) {
      return {
        ...data,
        currency: data.currency._id,
      }
    }

    if (data.kind === PriceRequestKind.RFQ) {
      return {
        ...data,
        currency: null,
      }
    }

    return {
      ...data,
      currency: data.currency?._id ?? null,
    }
  }

  private mapProduct(
    data:
      | BiogasProduct
      | CarbonProduct
      | WhiteCertProduct
      | GuaranteeOfOriginProduct
      | IntRecProduct
      | UsRecProduct,
  ) {
    switch (data.kind) {
      case ProductKind.BIOGAS:
        return {
          ...data,
          labels: data.labels.map((label) => label._id),
          input: data.input._id,
        }
      case ProductKind.CARBON:
        return {
          ...data,
          instruments: data.instruments.map((instrument) => instrument._id),
          technologies: data.technologies.map((technology) => technology._id),
          labels: data.labels.map((label) => label._id),
          sdgs: data.sdgs.map((sdg) => sdg._id),
        }
      case ProductKind.GUARANTEE_OF_ORIGIN:
        return {
          ...data,
          labels: data.labels.map((label) => label._id),
          technologies: data.technologies.map((technology) => technology._id),
        }
      case ProductKind.INT_REC:
        return {
          ...data,
          labels: data.labels.map((label) => label._id),
          technologies: data.technologies.map((technology) => technology._id),
        }
      case ProductKind.US_REC:
        return {
          ...data,
          labels: data.labels.map((label) => label._id),
          technologies: data.technologies.map((technology) => technology._id),
          registries: data.registries.map((registry) => registry._id),
        }
      case ProductKind.WHITE_CERTIFICATE:
        return {
          ...data,
          instruments: data.instruments.map((instrument) => instrument._id),
          technologies: data.technologies.map((technology) => technology._id),
        }
    }
  }
}

export class UpdateProposalDto extends CreateProposalDto {
  readonly state: State
  readonly go2Id: string

  constructor(data: Proposal) {
    super(data)
    this.state = data.state
    this.go2Id = data.go2Id
  }
}
