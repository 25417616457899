import { HttpModule } from "~/http/http.module"
import { TechnologiesConfig } from "./technologies.config"
import { TechnologiesService } from "./technologies.service"
import { TechnologiesStore } from "./technologies.store"

export class TechnologiesModule {
  private readonly _technologiesConfig: TechnologiesConfig
  private readonly _technologiesStore: TechnologiesStore
  private readonly _technologiesService: TechnologiesService

  constructor(private readonly httpModule: HttpModule) {
    this._technologiesConfig = new TechnologiesConfig()
    this._technologiesStore = new TechnologiesStore()
    this._technologiesService = new TechnologiesService(
      this.httpModule.service,
      this._technologiesConfig,
      this._technologiesStore,
    )
  }

  public get store() {
    return this._technologiesStore
  }

  public get service() {
    return this._technologiesService
  }
}
