import { Box } from "@mui/material"
import { useContext } from "react"
import { CurrencyField } from "~/currencies/views/currency.form-input.view"
import { ProductKind } from "~/proposals/models/product.model"
import { DeadlineField } from "~/proposals/price-requests/views/price-request.deadline-field.view"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { VolumeField } from "~/proposals/views/form-views/proposal.volume-field.view"
import { PriceRequestKind } from "../price-request.model"
import { DeliveryField } from "./price-request.delivery-field.view"
import { KindField } from "./price-request.kind-field.view"
import { PriceField } from "./price-request.price-field.view"
import { ValidityField } from "./price-request.validity-field.view"

export const PriceRequestFields = () => {
  const { getValues, watch } = useContext(ProposalFormContext)
  const priceRequestKind = watch(
    "priceRequest.kind",
    getValues("priceRequest.kind"),
  )
  const productKind = watch("product.kind", getValues("product.kind"))

  const getVolumeUnit = () => {
    switch (productKind) {
      case ProductKind.CARBON:
        return "Tons"
      default:
        return "MWh"
    }
  }

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <KindField />
      </Box>
      {priceRequestKind !== PriceRequestKind.RFQ && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <CurrencyField
            required={priceRequestKind === PriceRequestKind.FIRM}
          />
          <PriceField required={priceRequestKind === PriceRequestKind.FIRM} />
        </Box>
      )}
      <Box sx={{ display: "flex", gap: 2 }}>
        <DeadlineField />
        <DeliveryField />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <ValidityField />
        <VolumeField unit={getVolumeUnit()} />
        {/* <Box sx={{ width: "50%" }} /> */}
      </Box>
    </>
  )
}
