export class RegionsConfig {
  private readonly _regionsApiUrl: string

  constructor() {
    this._regionsApiUrl = "/api/regions"
  }

  public get regionsApiUrl() {
    return this._regionsApiUrl
  }
}
