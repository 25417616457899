import { LocaleService } from "./locale.service"
import { LocaleStore } from "./locale.store"

export class LocaleModule {
  private readonly _store: LocaleStore
  private readonly _service: LocaleService

  constructor() {
    this._store = new LocaleStore()
    this._service = new LocaleService(this._store)
  }

  get service() {
    return this._service
  }

  get store() {
    return this._store
  }
}
