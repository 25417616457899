import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import type { CountriesConfig } from "./countries.config"
import type { CountriesStore } from "./countries.store"
import { CountriesDto } from "./dtos/countries.dto"

export class CountriesService {
  private readonly name = "CountriesService"

  constructor(
    private readonly httpService: HttpService,
    private readonly countriesConfig: CountriesConfig,
    private readonly countriesStore: CountriesStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.countriesStore.state)) {
      return Logger.info(
        `${id} Countries are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.countriesStore.state)) {
      return Logger.info(`${id} Countries found in cache, not fetching again.`)
    }

    try {
      this.countriesStore.state = loading

      const { errors, data: countries } = await this.httpService.get(
        this.countriesConfig.countriesApiUrl,
        CountriesDto,
      )

      if (errors.length) {
        const msg = "Invalid countries"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.countriesStore.state = failure(msg)
        return
      }

      this.countriesStore.state = success({ countries })
    } catch (error) {
      const msg = "Failed to fetch countries"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.countriesStore.state = failure(msg)
    }
  }
}
