import type { HttpModule } from "~/http/http.module"
import { CountriesConfig } from "./countries.config"
import { CountriesService } from "./countries.service"
import { CountriesStore } from "./countries.store"

export class CountriesModule {
  private readonly _countriesConfig: CountriesConfig
  private readonly _countriesStore: CountriesStore
  private readonly _countriesService: CountriesService

  constructor(private readonly httpModule: HttpModule) {
    this._countriesConfig = new CountriesConfig()
    this._countriesStore = new CountriesStore()
    this._countriesService = new CountriesService(
      this.httpModule.service,
      this._countriesConfig,
      this._countriesStore,
    )
  }

  public get store() {
    return this._countriesStore
  }

  public get service() {
    return this._countriesService
  }
}
