import { plainToInstance } from "class-transformer"
import { validateSync, type ValidationError } from "class-validator"
import type { AuthService } from "~/auth/auth.service"
import type { AuthStore } from "~/auth/auth.store"
import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { UserDto } from "./dtos/user.dto"
import type { UserConfig } from "./user.config"
import { GraphProfile } from "./user.model"
import type { UserStore } from "./user.store"

type GraphData =
  | {
      ok: false
      errors: ValidationError[]
      data: null
    }
  | {
      ok: true
      errors: null
      data: GraphProfile
    }

export class UserService {
  private readonly name = "UserService"

  constructor(
    private readonly authService: AuthService,
    private readonly authStore: AuthStore,
    private readonly httpService: HttpService,
    private readonly userConfig: UserConfig,
    private readonly userStore: UserStore,
  ) {
    this.authStore.addListener(async (account) => {
      if (isSuccess(account)) {
        await this.fetchUserData()
      }
    })
  }

  private async fetchGraphData(): Promise<GraphData> {
    const response = await this.authService.fetch(
      this.userConfig.graphMeEndpoint,
    )
    const data = await response.json()
    const graphData = plainToInstance(GraphProfile, data)
    const validationErrors = validateSync(graphData)

    if (validationErrors.length) {
      return { ok: false, errors: validationErrors, data: null }
    }

    return { ok: true, errors: null, data: graphData }
  }

  private async fetchAvatar() {
    const response = await this.authService.fetch(
      this.userConfig.avatarEndpoint,
    )
    const blob = await response.blob()
    const avatar = await new Promise<string>((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(blob)
    })

    return avatar
  }

  private async fetchUserData() {
    const id = `[${this.name}::fetchUserData]`

    if (isLoading(this.userStore.state)) {
      return Logger.info(`${id} User data is already loading`)
    }

    if (isSuccess(this.userStore.state)) {
      return Logger.info(`${id} User data was already fetched`)
    }

    try {
      this.userStore.state = loading

      const {
        ok,
        errors: graphErrors,
        data: graphData,
      } = await this.fetchGraphData()

      if (!ok) {
        const msg = "Invalid graph data."
        Logger.error(`${id} ${msg}`)
        Logger.error(graphErrors)
        this.userStore.state = failure(msg)
        return
      }

      const avatar = await this.fetchAvatar()

      const { errors, data: user } = await this.httpService.post(
        `${this.userConfig.userEndpoint}`,
        {
          name: graphData.displayName,
          email: graphData.mail,
          avatar,
          hubSpotId: null, // TODO: Add hubSpotId
        },
        UserDto,
      )

      if (errors.length) {
        const msg = "Invalid user data"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.userStore.state = failure(msg)
        return
      }

      this.userStore.state = success({ user })
    } catch (error) {
      const msg = "Error fetching user data"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.userStore.state = failure(msg)
    }
  }
}
