import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Technology } from "./technology.model"

type Err = string
type Ok = {
  technologies: Technology[]
}

export class TechnologiesStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("TechnologiesStore", initial)
  }
}
