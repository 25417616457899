import type { HttpModule } from "~/http/http.module"
import { FeatureFlagsService } from "./feature-flags.service"
import { FeatureFlagsStore } from "./feature-flags.store"

export class FeatureFlagsModule {
  private readonly _store: FeatureFlagsStore
  private readonly _service: FeatureFlagsService

  constructor(private readonly httpModule: HttpModule) {
    this._store = new FeatureFlagsStore()
    this._service = new FeatureFlagsService(
      this.httpModule.service,
      this._store,
    )
  }

  get store() {
    return this._store
  }

  get service() {
    return this._service
  }
}
