import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { LabelsDto } from "./dtos/labels.dto"
import type { LabelsConfig } from "./labels.config"
import type { LabelsStore } from "./labels.store"

export class LabelsService {
  private readonly name = "LabelsService"

  constructor(
    private readonly httpService: HttpService,
    private readonly labelsConfig: LabelsConfig,
    private readonly labelsStore: LabelsStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.labelsStore.state)) {
      return Logger.info(
        `${id} Labels are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.labelsStore.state)) {
      return Logger.info(`${id} Labels found in cache, not fetching again.`)
    }

    try {
      this.labelsStore.state = loading

      const { errors, data: labels } = await this.httpService.get(
        this.labelsConfig.apiUrl,
        LabelsDto,
      )

      if (errors.length) {
        const msg = "Invalid labels"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.labelsStore.state = failure(msg)
        return
      }

      this.labelsStore.state = success({ labels })
    } catch (error) {
      const msg = "Failed to fetch labels"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.labelsStore.state = failure(msg)
    }
  }
}
