import { plainToInstance } from "class-transformer"
import { ValidationError, validate } from "class-validator"
import { AuthService } from "~/auth/auth.service"
import { HttpDto } from "./http.models"

type RequestBody = object | FormData
type HttpResponse<R> = Promise<{ errors: ValidationError[]; data: R }>

export class HttpService {
  constructor(private readonly authService: AuthService) {}

  public async get<R extends object>(
    endpoint: RequestInfo,
    instance: new () => HttpDto<R>,
    options?: RequestInit & { encoding?: "json" | "blob" },
  ): HttpResponse<R> {
    const response = await this.authService.fetch(endpoint, options)

    if (options?.encoding === "blob") {
      const blob = (await response.blob()) as unknown as R
      return { errors: [], data: blob }
    }

    const json = await response.json()
    const { data } = plainToInstance(instance, json)
    const errors = Array.isArray(data)
      ? (await Promise.all(data.map((x) => validate(x)))).flat()
      : await validate(data)

    return { errors, data }
  }

  public async post<T extends RequestBody, R extends object>(
    endpoint: RequestInfo,
    body: T,
    instance: new () => HttpDto<R>,
    options?: RequestInit,
  ): HttpResponse<R> {
    const response = await this.authService.fetch(endpoint, {
      ...options,
      method: "POST",
      body: body instanceof FormData ? body : JSON.stringify(body),
      headers:
        body instanceof FormData ? {} : { "Content-Type": "application/json" },
    })
    const json = await response.json()
    const { data } = plainToInstance(instance, json)

    const errors = Array.isArray(data)
      ? (await Promise.all(data.map((x) => validate(x)))).flat()
      : await validate(data)

    return { errors, data }
  }

  public async put<T extends object | FormData, R extends object>(
    endpoint: RequestInfo,
    body: T,
    instance: new () => HttpDto<R>,
    options?: RequestInit,
  ): HttpResponse<R> {
    const response = await this.authService.fetch(endpoint, {
      ...options,
      method: "PUT",
      body: body instanceof FormData ? body : JSON.stringify(body),
      headers:
        body instanceof FormData ? {} : { "Content-Type": "application/json" },
    })
    const json = await response.json()
    const { data } = plainToInstance(instance, json)
    const errors = Array.isArray(data)
      ? (await Promise.all(data.map((x) => validate(x)))).flat()
      : await validate(data)

    return { errors, data }
  }

  public async delete<R extends object>(
    endpoint: RequestInfo,
    instance: new () => HttpDto<R>,
    options?: RequestInit,
  ): HttpResponse<R> {
    const response = await this.authService.fetch(endpoint, {
      ...options,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
    const json = await response.json()
    const { data } = plainToInstance(instance, json)
    const errors = Array.isArray(data)
      ? (await Promise.all(data.map((x) => validate(x)))).flat()
      : await validate(data)

    return { errors, data }
  }
}
