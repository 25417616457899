import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { RegionsDto } from "./dtos/regions.dto"
import type { RegionsConfig } from "./regions.config"
import type { RegionsStore } from "./regions.store"

export class RegionsService {
  private readonly name = "RegionsService"

  constructor(
    private readonly httpService: HttpService,
    private readonly regionsConfig: RegionsConfig,
    private readonly regionsStore: RegionsStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.regionsStore.state)) {
      return Logger.info(
        `${id} Regions are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.regionsStore.state)) {
      return Logger.info(`${id} Regions found in cache, not fetching again.`)
    }

    try {
      this.regionsStore.state = loading

      const { errors, data: regions } = await this.httpService.get(
        this.regionsConfig.regionsApiUrl,
        RegionsDto,
      )

      if (errors.length) {
        const msg = "Invalid regions"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.regionsStore.state = failure(msg)
        return
      }

      this.regionsStore.state = success({ regions })
    } catch (error) {
      const msg = "Failed to fetch regions"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.regionsStore.state = failure(msg)
    }
  }
}
