import { Box, Button, Toolbar, useMediaQuery, useTheme } from "@mui/material"
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useCompaniesStore } from "~/companies/companies.use-companies-store.hook"
import { useCountriesStore } from "~/countries/countries.use-countries-store"
import { useCurrenciesStore } from "~/currencies/currencies.use-currencies-store.hook"
import { useInputsStore } from "~/inputs/inputs.use-inputs-store.hook"
import { useInstrumentsStore } from "~/instruments/instruments.use-instruments-store.hook"
import { useLabelsStore } from "~/labels/labels.use-labels-store.hook"
import { useLocaleStore } from "~/locale/locale.use-locale-store.hook"
import { Proposal, ProposalKind } from "~/proposals/models/proposal.model"
import { useRegionsStore } from "~/regions/regions.use-region-store"
import { useRegistriesStore } from "~/registries/registries.use-registries-store.hook"
import { useSdgsStore } from "~/sdgs/sdgs.use-sdgs-store.hook"
import { useTechnologiesStore } from "~/technologies/technologies.use-technologies-store.hook"
import { DataGrid } from "~/ui/components/ui.data-grid.component"
import { LoadingSpinner } from "~/ui/components/ui.loading-spinner.component"
import { useUiStore } from "~/ui/ui.use-ui-store.hook"
import { format } from "~/utils/utils.string"
import { getColumnVisibilityModel, getColumns } from "../../proposal.utils"
import { useProposalsStore } from "../../proposals.use-proposals-store.hook"
import { DetailsView } from "../detail-views/proposal.details.view"
import { ProposalForm } from "../form-views/proposal.form.view"
import { ProductMenu } from "../product-menu.view"
import { PriceRequestMenu } from "./price-request-menu.view"

export const Proposals = ({ proposalKind }: { proposalKind: ProposalKind }) => {
  const { state: locale } = useLocaleStore()

  const {
    state: { isLoading: countriesLoading },
    actions: { fetchCountries },
  } = useCountriesStore()
  const {
    state: { isLoading: regionsLoading },
    actions: { fetchRegions },
  } = useRegionsStore()
  const {
    actions: { fetchCurrencies },
  } = useCurrenciesStore()
  const {
    state: { isLoading: inputsLoading },
    actions: { fetchInputs },
  } = useInputsStore()
  const {
    state: { isLoading: instrumentsLoading },
    actions: { fetchInstruments },
  } = useInstrumentsStore()
  const {
    state: { isLoading: labelsLoading },
    actions: { fetchLabels },
  } = useLabelsStore()
  const {
    state: { proposals, isLoading: proposalsLoading },
    actions: { fetchAllProposals },
  } = useProposalsStore()
  const {
    state: { isLoading: sdgsLoading },
    actions: { fetchSdgs },
  } = useSdgsStore()
  const {
    state: { isLoading: technologiesLoading },
    actions: { fetchTechnologies },
  } = useTechnologiesStore()
  const {
    state: { isLoading: registriesLoading },
    actions: { fetchRegistries },
  } = useRegistriesStore()
  const {
    config: { hubSpotCompanyUrl },
  } = useCompaniesStore()
  const {
    state: { drawer, quickFilters },
    actions: {
      openDrawer,
      triggerAlert,
      clearAlert,
      setFooterContent,
      hydrateQuickFilters,
      setQuickFilters,
    },
  } = useUiStore()
  const isLoading = [
    countriesLoading,
    regionsLoading,
    inputsLoading,
    instrumentsLoading,
    labelsLoading,
    proposalsLoading,
    sdgsLoading,
    technologiesLoading,
    registriesLoading,
  ].some((loading) => loading)
  const [rows, setRows] = useState<Proposal[]>(proposals)
  const columns = getColumns<Proposal>(hubSpotCompanyUrl, locale, quickFilters)
  const params = useParams()
  const navigate = useNavigate()
  const columnsBtnRef = useRef<HTMLButtonElement | null>(null)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("xl"))

  useEffect(() => {
    hydrateQuickFilters()
  }, [])

  useEffect(() => {
    const filteredRows = proposals
      .filter(
        (proposal) => proposal._id === (params.proposalId ?? proposal._id),
      )
      .filter((proposal) => proposal.kind === proposalKind)
      .filter((proposal) => proposal.product.kind === quickFilters.productKind)
      .filter((proposal) => {
        return (
          quickFilters.priceRequestKinds.length === 0 ||
          quickFilters.priceRequestKinds.includes(proposal.priceRequest.kind)
        )
      })

    setRows(filteredRows)
  }, [quickFilters, proposalKind, proposals, params.proposalId])

  useEffect(() => {
    fetchCountries()
    fetchCurrencies()
    fetchInputs()
    fetchInstruments()
    fetchLabels()
    fetchRegions()
    fetchSdgs()
    fetchTechnologies()
    fetchRegistries()
  }, [])

  useEffect(() => {
    fetchAllProposals()
  }, [proposalKind])

  useEffect(() => {
    if (params.proposalId) {
      setQuickFilters({
        ...quickFilters,
        productKind:
          proposals.find(({ _id }) => _id === params.proposalId)?.product
            .kind ?? quickFilters.productKind,
      })

      triggerAlert({
        action: (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              clearAlert()
              navigate(
                `/proposals/${format(proposalKind, { case: "lower", form: "plural" })}`,
              )
            }}
          >
            View all
          </Button>
        ),
        message: `You are viewing a single ${format(proposalKind)}.`,
        severity: "info",
      })
    }
  }, [params.proposalId])

  useEffect(() => {
    setFooterContent(
      <Button variant="contained" onClick={handleCreateClick}>
        Create
      </Button>,
    )
  }, [proposalKind])

  const GridToolbar = useCallback(() => {
    return (
      <>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <ProductMenu />
          <GridToolbarContainer sx={{ p: 0 }}>
            <GridToolbarColumnsButton ref={columnsBtnRef} />
            <GridToolbarFilterButton />
            {matches && <GridToolbarDensitySelector />}
            <GridToolbarExport
              printOptions={{
                disableToolbarButton: true,
              }}
            />
          </GridToolbarContainer>
          <PriceRequestMenu />
        </Toolbar>
      </>
    )
  }, [matches])

  const handleRowClick = useCallback(({ row: proposal }: { row: Proposal }) => {
    openDrawer(<DetailsView proposal={proposal} />)
  }, [])

  const handleCreateClick = useCallback(() => {
    openDrawer(<ProposalForm kind={proposalKind} mode={{ kind: "create" }} />)
  }, [proposalKind])

  if (isLoading && !drawer.open) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <DataGrid
          columns={columns}
          columnVisibilityModel={getColumnVisibilityModel(
            quickFilters.productKind,
          )}
          density={matches ? "standard" : "compact"}
          rows={rows}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            panel: {
              anchorEl: columnsBtnRef.current,
            },
          }}
          onRowClick={handleRowClick}
        />
      </Box>
    </>
  )
}
