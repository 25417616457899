import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Proposal } from "./models/proposal.model"

export type ProposalState = {
  proposals: Proposal[]
  updatedAt: number
}

type Err = string
type Ok = ProposalState

export class ProposalsStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("ProposalsStore", initial)
  }
}
