import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Region } from "./region.model"

type Err = string
type Ok = { regions: Region[] }

export class RegionsStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("RegionsStore", initial)
  }
}
