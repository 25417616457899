import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import type { Registry } from "./registry.model"

type State = {
  registries: Registry[]
  isLoading: boolean
  error: string | null
}

export const useRegistriesStore = () => {
  const {
    registriesModule: { service, store },
  } = useAppContext()

  const [state, setState] = useState<State>({
    registries: withSuccess(store.state, [], ({ registries }) => registries),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          registries: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ registries }) => {
        setState({ registries, isLoading: false, error: null })
      },
    }),
  )

  const fetchRegistries = useCallback(async () => {
    const registires = await service.fetchAll()
    return registires
  }, [service])

  return {
    state,
    actions: { fetchRegistries },
  }
}
