export class InputsConfig {
  private readonly _apiUrl: string

  constructor() {
    this._apiUrl = "/api/inputs"
  }

  public get apiUrl() {
    return this._apiUrl
  }
}
