import { HttpModule } from "~/http/http.module"
import { LabelsConfig } from "./labels.config"
import { LabelsService } from "./labels.service"
import { LabelsStore } from "./labels.store"

export class LabelsModule {
  private readonly _labelsConfig: LabelsConfig
  private readonly _labelsStore: LabelsStore
  private readonly _labelsService: LabelsService

  constructor(private readonly httpModule: HttpModule) {
    this._labelsConfig = new LabelsConfig()
    this._labelsStore = new LabelsStore()
    this._labelsService = new LabelsService(
      this.httpModule.service,
      this._labelsConfig,
      this._labelsStore,
    )
  }

  public get store() {
    return this._labelsStore
  }

  public get service() {
    return this._labelsService
  }
}
