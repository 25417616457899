import "./instrument"

import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import "reflect-metadata"
import { AppProvider } from "./app.context"
import { app } from "./app.module"
import App from "./app.view"
import { theme } from "./ui/theme/theme"
import FeedBucket from "./utils/utils.feedbucket"

app.initialize().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  )
  root.render(
    <StrictMode>
      <Router>
        <AppProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <FeedBucket />
            <App />
          </ThemeProvider>
        </AppProvider>
      </Router>
    </StrictMode>,
  )
})
