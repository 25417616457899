export class CountriesConfig {
  private readonly _countriesApiUrl: string

  constructor() {
    this._countriesApiUrl = "/api/countries"
  }

  public get countriesApiUrl() {
    return this._countriesApiUrl
  }
}
