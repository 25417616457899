import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  Link,
  ListItem,
  TextField,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { useCompaniesStore } from "./companies.use-companies-store.hook"
import { Company } from "./company.model"

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Company,
) => {
  if (option.properties.domain) {
    return (
      <ListItem {...props} key={option.id}>
        {option.properties.name} ({option.properties.domain})
      </ListItem>
    )
  }

  return (
    <ListItem {...props} key={option.id}>
      {option.properties.name}
    </ListItem>
  )
}

export const CompanyField = () => {
  const {
    state: { companies, isLoading },
    actions: { searchCompanies },
    config: { hubSpotCompaniesUrl },
  } = useCompaniesStore()
  const { control } = useContext(ProposalFormContext)
  const [input, setInput] = useState("")
  const options = companies.map((company) => ({
    label: company.properties.name,
    ...company,
  }))

  useEffect(() => {
    searchCompanies(input)
  }, [input])

  return (
    <Controller
      name="company"
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        const renderInput = (params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            required
            error={Boolean(error)}
            helperText={error?.message}
            label="Company"
            onChange={(e) => setInput(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoading ? (
                <CircularProgress color="primary" size={20} />
              ) : null,
            }}
          />
        )

        return (
          <Box>
            <Autocomplete
              options={options}
              size="small"
              fullWidth
              loading={isLoading}
              getOptionLabel={(option) => option.properties.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={renderInput}
              renderOption={renderOption}
              value={value}
              onChange={(_, value) => {
                onChange(value)
              }}
            />
            <Link
              href={hubSpotCompaniesUrl}
              rel="noopener"
              target="_blank"
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                pl: "0.5rem",
                pt: "0.5rem",
                fontSize: "0.75rem",
                lineHeight: "1rem",
              }}
            >
              <Box sx={{ pr: "0.25rem" }}>Add new company</Box>
              <OpenInNewIcon sx={{ fontSize: "0.75rem" }} />
            </Link>
          </Box>
        )
      }}
    />
  )
}
