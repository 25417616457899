import { useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { User } from "./user.model"

type State = {
  user: User | null
  isLoading: boolean
  error: string | null
}

export function useUserStore() {
  const {
    userModule: { store },
  } = useAppContext()
  const [state, setState] = useState<State>({
    user: withSuccess(store.state, null, ({ user }) => user),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          user: null,
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ user }) => {
        setState({ user, isLoading: false, error: null })
      },
    }),
  )

  return { state }
}
