import { AbsurdError } from "~/errors/errors.absurd-error"
import { AsyncState, Failure, Initial, Loading, Success } from "./async-store"

// ==================
//    Constructors
// ==================

export const initial: Initial = {
  phase: "initial",
}

export const loading: Loading = {
  phase: "loading",
}

export const success = <Ok extends Record<string, unknown>>(
  data: Ok,
): Success<Ok> => ({
  phase: "success",
  data,
})

export const failure = <Err>(error: Err): Failure<Err> => ({
  phase: "failure",
  error,
})

// ==================
//    Predicates
// ==================

export const isInitial = <Err, Ok extends Record<string, unknown>>(
  state: AsyncState<Err, Ok>,
): state is Initial => state.phase === "initial"

export const isLoading = <Err, Ok extends Record<string, unknown>>(
  state: AsyncState<Err, Ok>,
): state is Loading => state.phase === "loading"

export const isFailure = <Err, Ok extends Record<string, unknown>>(
  state: AsyncState<Err, Ok>,
): state is Failure<Err> => state.phase === "failure"

export const isSuccess = <Err, Ok extends Record<string, unknown>>(
  state: AsyncState<Err, Ok>,
): state is Success<Ok> => state.phase === "success"

// ==================
//   Deconstructors
// ==================

type MatchHandlers<Err, Ok extends Record<string, unknown>, R> = {
  onInitial: () => R
  onLoading: () => R
  onFailure: (error: Err) => R
  onSuccess: (data: Ok) => R
}

export const match =
  <Err, Ok extends Record<string, unknown>, R>({
    onInitial,
    onLoading,
    onFailure,
    onSuccess,
  }: MatchHandlers<Err, Ok, R>) =>
  (state: AsyncState<Err, Ok>): R => {
    switch (state.phase) {
      case "initial":
        return onInitial()
      case "loading":
        return onLoading()
      case "failure":
        return onFailure(state.error)
      case "success":
        return onSuccess(state.data)
    }
  }

type ExtractErrs<T> = {
  [P in keyof T]: T[P] extends AsyncState<infer E, Record<string, unknown>>
    ? E
    : never
}
type ExtractOks<T> = {
  [P in keyof T]: T[P] extends AsyncState<unknown, infer S> ? S : never
}

type MatchManyHandlers<
  States extends AsyncState<unknown, Record<string, unknown>>[],
  R,
> = {
  onInitial: () => R
  onLoading: () => R
  onFailure: (errors: ExtractErrs<States>) => R
  onSuccess: (data: ExtractOks<States>) => R
}

export function matchMany<
  States extends [
    AsyncState<unknown, Record<string, unknown>>,
    ...AsyncState<unknown, Record<string, unknown>>[],
  ],
  R,
>(
  states: [...States],
  { onInitial, onLoading, onFailure, onSuccess }: MatchManyHandlers<States, R>,
): R {
  if (states.some(isInitial)) {
    return onInitial()
  }

  if (states.some(isLoading)) {
    return onLoading()
  }

  if (states.some(isFailure)) {
    return onFailure(
      states.filter(isFailure).map(({ error }) => error) as ExtractErrs<States>,
    )
  }

  if (states.every(isSuccess)) {
    return onSuccess(
      states.filter(isSuccess).map(({ data }) => data) as ExtractOks<States>,
    )
  }

  throw new AbsurdError(
    "[AsyncStoreUtils::matchMany] A state has managed to escape the type system.",
  )
}

export const withSuccess = <Err, Ok extends Record<string, unknown>, R>(
  state: AsyncState<Err, Ok>,
  fallback: R,
  onSuccess: (data: Ok) => R,
): R => {
  if (isSuccess(state)) {
    return onSuccess(state.data)
  }

  return fallback
}

export const withFailure = <Err, Ok extends Record<string, unknown>, R>(
  state: AsyncState<Err, Ok>,
  fallback: R,
  onFailure: (error: Err) => R,
): R => {
  if (isFailure(state)) {
    return onFailure(state.error)
  }

  return fallback
}
