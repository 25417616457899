import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { Notification } from "./notification.model"

type State = {
  notifications: Notification[]
  isLoading: boolean
  error: string | null
}

export function useNotificationsStore() {
  const {
    notificationsModule: { service, store },
  } = useAppContext()

  const [state, setState] = useState<State>({
    notifications: withSuccess(
      store.state,
      [],
      ({ notifications }) => notifications,
    ),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          notifications: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ notifications }) => {
        setState({ notifications, isLoading: false, error: null })
      },
    }),
  )

  const fetchNotifications = useCallback(async () => {
    await service.fetchAll()
  }, [service])

  const markAsRead = useCallback(
    async (id: string) => {
      await service.markAsRead(id)
    },
    [service],
  )

  const clearNotification = useCallback(
    async (id: string) => {
      await service.delete(id)
    },
    [service],
  )

  const clearNotifications = useCallback(async () => {
    await service.deleteAll()
  }, [service])

  return {
    state,
    actions: {
      clearNotification,
      clearNotifications,
      fetchNotifications,
      markAsRead,
    },
  }
}
