import { HttpModule } from "~/http/http.module"
import { SdgsConfig } from "./sdgs.config"
import { SdgsService } from "./sdgs.service"
import { SdgsStore } from "./sdgs.store"

export class SdgsModule {
  private readonly _sdgsConfig: SdgsConfig
  private readonly _sdgsStore: SdgsStore
  private readonly _sdgsService: SdgsService

  constructor(private readonly httpModule: HttpModule) {
    this._sdgsConfig = new SdgsConfig()
    this._sdgsStore = new SdgsStore()
    this._sdgsService = new SdgsService(
      this.httpModule.service,
      this._sdgsConfig,
      this._sdgsStore,
    )
  }

  public get store() {
    return this._sdgsStore
  }

  public get service() {
    return this._sdgsService
  }
}
