import { Box, useTheme } from "@mui/material"
import { PropsWithChildren } from "react"

export function Main({ children }: PropsWithChildren) {
  const theme = useTheme()

  return (
    <Box
      component="main"
      sx={{
        marginTop: "4rem",
        height: "calc(100vh - 8rem)",
        overflow: "auto",
        position: "relative",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {children}
    </Box>
  )
}
