import { TextField } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { NumericFormat } from "react-number-format"
import { useLocaleStore } from "~/locale/locale.use-locale-store.hook"
import { getSeparators } from "~/locale/locale.utils"
import { ProposalFormContext } from "~/proposals/proposals.context"

type Props = {
  required?: boolean
}

export const PriceField = ({ required = false }: Props) => {
  const { state: locale } = useLocaleStore()
  const { decimal, thousands } = getSeparators(locale)
  const { control } = useContext(ProposalFormContext)

  return (
    <Controller
      control={control}
      name="priceRequest.price"
      render={({ fieldState: { error }, field: { name, value, onChange } }) => {
        return (
          <NumericFormat
            allowedDecimalSeparators={[".", ","]}
            customInput={TextField}
            decimalScale={2}
            decimalSeparator={decimal}
            error={Boolean(error)}
            fixedDecimalScale
            helperText={error?.message}
            label="Price"
            name={name}
            required={required}
            size="small"
            thousandSeparator={thousands}
            value={value}
            onValueChange={({ floatValue }) => {
              onChange(floatValue ?? null)
            }}
            sx={{ width: "50%" }}
          />
        )
      }}
    />
  )
}
