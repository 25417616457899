import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { Company } from "./company.model"

type State = {
  companies: Company[]
  isLoading: boolean
  error: string | null
}

export function useCompaniesStore() {
  const {
    companiesModule: { config, service, store },
  } = useAppContext()
  const [state, setState] = useState<State>({
    companies: withSuccess(store.state, [], ({ companies }) => companies),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          companies: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ companies }) => {
        setState({ companies, isLoading: false, error: null })
      },
    }),
  )

  const searchCompanies = useCallback(
    async (query: string) => {
      await service.search(query)
    },
    [service],
  )

  return {
    config,
    state,
    actions: { searchCompanies },
  }
}
