import { Box, IconButton, Menu, MenuItem } from "@mui/material"
import { GridMoreVertIcon } from "@mui/x-data-grid-pro"
import { useCallback, useState } from "react"
import { useUiStore } from "../ui.use-ui-store.hook"

export const Actions = ({ id }: { id: string }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {
    state: { menuItems },
  } = useUiStore()

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <GridMoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems[id]?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(event) => {
              event.stopPropagation()
              item.action()
              handleClose()
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
