import { Typography } from "@mui/material"
import Box from "@mui/material/Box"

type Props = {
  count: number
  size?: "small" | "medium" | "large"
}

export function MatchCount({ count, size = "medium" }: Props) {
  const dimensions = {
    small: "1.5rem",
    medium: "1.75rem",
    large: "2.5rem",
  }

  const fontSize = {
    small: "0.8rem",
    medium: "0.9rem",
    large: "1.5rem",
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: dimensions[size],
        height: dimensions[size],
        borderRadius: "9999px",
        backgroundColor: theme.palette.green[500],
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      })}
    >
      <Typography variant="body2" fontSize={fontSize[size]} color="white">
        {count}
      </Typography>
    </Box>
  )
}
