import { DateTimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "~/proposals/proposals.context"

export const DeadlineField = () => {
  const { control, getValues, watch } = useContext(ProposalFormContext)
  const priceRequestKind = watch(
    "priceRequest.kind",
    getValues("priceRequest.kind"),
  )

  return (
    <Controller
      control={control}
      name="priceRequest.deadline"
      render={({
        fieldState: { error },
        field: { name, value, ref, onChange },
      }) => {
        return (
          <DateTimePicker
            label="Deadline"
            value={value ? dayjs(value) : null}
            inputRef={ref}
            onChange={(value) => onChange(value?.toDate() ?? null)}
            slotProps={{
              textField: {
                name,
                error: Boolean(error),
                helperText: error?.message,
                size: "small",
                fullWidth: true,
                required: priceRequestKind === "FIRM",
                sx: { width: "50%" },
              },
            }}
          />
        )
      }}
    />
  )
}
