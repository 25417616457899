import {
  AccountInfo,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser"
import { UnauthenticatedError } from "~/errors/errors.unauthenticated-error"
import { NavigationService } from "~/navigation/navigation.service"
import { initial, isSuccess, loading, success } from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { AuthConfig } from "./auth.config"
import { AuthStore } from "./auth.store"

type AuthRequest = SilentRequest | RedirectRequest

export class AuthService {
  private readonly msalInstance: PublicClientApplication
  private _accessToken: string | null = null

  constructor(
    private readonly navigationService: NavigationService,
    private readonly authConfig: AuthConfig,
    private readonly authStore: AuthStore,
  ) {
    this.msalInstance = new PublicClientApplication(this.authConfig.msalConfig)
    this.msalInstance.setNavigationClient(this.navigationService)
  }

  // =====================
  //    Public methods
  // =====================

  public async initialize() {
    await this.msalInstance.initialize()

    const response = await this.msalInstance.handleRedirectPromise()

    if (response !== null) {
      this.setAccount(response.account)
    }

    const accounts = this.msalInstance.getAllAccounts()

    if (accounts.length > 0) {
      this.setAccount(accounts[0])
    }
  }

  public setAccount(account: AccountInfo) {
    this.msalInstance.setActiveAccount(account)
    this.authStore.state = success({ account })
    this.authStore.notifyListeners()
  }

  public get isAuthenticated(): boolean {
    return isSuccess(this.authStore.state)
  }

  public async login() {
    return this.msalInstance.loginRedirect(this.authConfig.loginRequest)
  }

  public async logout() {
    if (!isSuccess(this.authStore.state)) {
      return
    }

    const account = this.authStore.state.data.account

    this.authStore.state = loading

    await this.msalInstance.logoutRedirect({
      account,
      postLogoutRedirectUri:
        this.authConfig.msalConfig.auth.postLogoutRedirectUri,
    })

    this.authStore.state = initial
  }

  public async fetch(endpoint: RequestInfo, options?: RequestInit) {
    const authRequest = endpoint.toString().startsWith("/api")
      ? this.authConfig.apiRequest
      : this.authConfig.graphRequest

    try {
      const headers = await this.getAuthHeaders(
        authRequest,
        new Headers(options?.headers),
      )

      const init: RequestInit = {
        ...options,
        headers,
      }

      const response = await fetch(endpoint, init)
      return response
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        Logger.error("[fetch] Unauthenticated error")
        this.navigationService.navigateInternal("/login")
      }

      throw error
    }
  }

  // =====================
  //    Private methods
  // =====================

  private async acquireToken(authRequest: AuthRequest): Promise<void> {
    if (!isSuccess(this.authStore.state)) {
      throw new UnauthenticatedError(
        "[AuthService::acquireToken] No account found",
      )
    }

    try {
      const result = await this.msalInstance.acquireTokenSilent({
        ...authRequest,
        account: this.authStore.state.data.account,
      })

      this._accessToken = result.accessToken
    } catch (error) {
      Logger.error("[AuthService::acquireToken] Error acquiring token")
      Logger.error(error)

      if (error instanceof InteractionRequiredAuthError) {
        await this.msalInstance.acquireTokenRedirect({
          ...authRequest,
          account: this.authStore.state.data.account,
        })
      }
    }
  }

  private async getAuthHeaders(
    authRequest: AuthRequest,
    headers: Headers,
  ): Promise<Headers> {
    await this.acquireToken(authRequest)

    if (this._accessToken == null) {
      throw new UnauthenticatedError(
        "[AuthService::getAuthHeaders] No access token found",
      )
    }

    const bearer = `Bearer ${this._accessToken}`

    headers.append("Authorization", bearer)

    return headers
  }
}
