import { Type } from "class-transformer"
import { IsArray, ValidateNested } from "class-validator"
import { HttpDto } from "~/http/http.models"
import { Country } from "../country.model"

export class CountriesDto implements HttpDto<Country[]> {
  @Type(() => Country)
  @IsArray()
  @ValidateNested({ each: true })
  readonly data: Country[]
}
