import { NavigationService } from "./navigation.service"

export class NavigationModule {
  private readonly navigationService: NavigationService

  constructor() {
    this.navigationService = new NavigationService()
  }

  get service() {
    return this.navigationService
  }
}
