import { HttpModule } from "~/http/http.module"
import { CurrenciesConfig } from "./currencies.config"
import { CurrenciesService } from "./currencies.service"
import { CurrenciesStore } from "./currencies.store"

export class CurrenciesModule {
  private readonly _currenciesConfig: CurrenciesConfig
  private readonly _currenciesStore: CurrenciesStore
  private readonly _currenciesService: CurrenciesService

  constructor(private readonly httpModule: HttpModule) {
    this._currenciesConfig = new CurrenciesConfig()
    this._currenciesStore = new CurrenciesStore()
    this._currenciesService = new CurrenciesService(
      this.httpModule.service,
      this._currenciesConfig,
      this._currenciesStore,
    )
  }

  public get store() {
    return this._currenciesStore
  }

  public get service() {
    return this._currenciesService
  }
}
