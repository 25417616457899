import { AccountInfo } from "@azure/msal-browser"
import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"

type Err = string
type Ok = { account: AccountInfo }

export class AuthStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("AuthStore", initial)
  }
}
