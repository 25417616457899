type FormatOptions = {
  case: "lower" | "upper" | "title"
  form: "singular" | "plural"
}

const defaultFormatOptions: FormatOptions = {
  case: "lower",
  form: "singular",
}

const formatCase = (value: string, options: FormatOptions) => {
  switch (options.case) {
    case "lower":
      return value.toLowerCase().replace("_", " ")
    case "upper":
      return value.toUpperCase().replace("_", " ")
    case "title":
      return value
        .toLowerCase()
        .split("_")
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(" ")
  }
}

const formatForm = (value: string, options: FormatOptions) => {
  switch (options.form) {
    case "singular":
      return value
    case "plural":
      return `${value}s`
  }
}

export const format = (value: string, options = defaultFormatOptions) => {
  return formatForm(formatCase(value, options), options)
}
