import { Autocomplete, TextField } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "../../proposals/proposals.context"
import { useInputsStore } from "../inputs.use-inputs-store.hook"

export function InputField() {
  const { control } = useContext(ProposalFormContext)
  const {
    state: { inputs },
  } = useInputsStore()

  return (
    <Controller
      control={control}
      name="product.input"
      render={({ fieldState: { error }, field: { name, value, onChange } }) => {
        return (
          <Autocomplete
            options={inputs}
            getOptionLabel={(input) => input.name}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                label="Input"
                error={Boolean(error)}
                helperText={error?.message}
                required
              />
            )}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            value={value}
            size="small"
            fullWidth
            sx={{ width: "50%" }}
          />
        )
      }}
    />
  )
}
