import { Type } from "class-transformer"
import { ArrayNotEmpty, IsArray, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Input } from "../input.model"

export class InputsDto implements HttpDto<Input[]> {
  @Type(() => Input)
  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  declare readonly data: Input[]
}
