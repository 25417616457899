import SelectAllIcon from "@mui/icons-material/Add"
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  ListItem,
  TextField,
  type AutocompleteRenderGetTagProps,
  type AutocompleteRenderInputParams,
  type FilterOptionsState,
} from "@mui/material"
import React, { useCallback, useContext } from "react"
import { Controller, type FieldError } from "react-hook-form"
import { ProposalFormContext } from "../../proposals/proposals.context"
import type { Sdg } from "../sdg.model"
import { useSdgsStore } from "../sdgs.use-sdgs-store.hook"

export function SdgsField() {
  const { control, getValues, setValue, watch } =
    useContext(ProposalFormContext)
  const {
    state: { sdgs: allSdgs },
  } = useSdgsStore()
  const selectedSdgs = watch("product.sdgs", getValues("product.sdgs"))

  const selectAll = useCallback(() => {
    setValue("product.sdgs", allSdgs)
  }, [allSdgs])

  const filterOptions = useCallback(
    (options: Sdg[], { inputValue }: FilterOptionsState<Sdg>) => {
      return options.filter((option) => {
        return (
          option.goalNumber === Number(inputValue) ||
          option.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      })
    },
    [],
  )

  const renderInput = useCallback(
    (name: string, error: FieldError | undefined) =>
      (params: AutocompleteRenderInputParams) => {
        const showSelectAllBtn = selectedSdgs.length < allSdgs.length
        let endAdornment = null

        if (React.isValidElement(params.InputProps.endAdornment)) {
          endAdornment = React.cloneElement(params.InputProps.endAdornment, {
            ...params.InputProps.endAdornment.props,
            children: showSelectAllBtn
              ? [
                  <IconButton
                    key={`${name}-MuiAutocomplete-selectAllIndicator`}
                    size="medium"
                    onClick={selectAll}
                    aria-label="Select all"
                    title="Select all"
                    className="MuiAutocomplete-selectAllIndicator"
                    sx={{ visibility: "hidden", p: "3px", mr: "-2px" }}
                  >
                    <SelectAllIcon sx={{ fontSize: "1.35rem" }} />
                  </IconButton>,
                  ...params.InputProps.endAdornment.props.children,
                ]
              : params.InputProps.endAdornment.props.children,
          })
        }

        return (
          <TextField
            {...params}
            name={name}
            label="SDGs"
            error={Boolean(error)}
            helperText={error?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment,
            }}
          />
        )
      },
    [allSdgs, selectedSdgs, selectAll],
  )

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: Sdg) => {
      return (
        <ListItem
          key={option._id}
          {...props}
          sx={{
            display: "flex",
            "&.MuiAutocomplete-option": {
              justifyContent: "space-between",
            },
          }}
        >
          <Box>{`${option.goalNumber}. ${option.name}`}</Box>
          <Box
            sx={{
              minWidth: "1rem",
              minHeight: "1rem",
              backgroundColor: option.color,
              borderRadius: "0.25rem",
              marginLeft: "1rem",
            }}
          />
        </ListItem>
      )
    },
    [],
  )

  const renderTags = useCallback(
    (sdgs: Sdg[], getTagProps: AutocompleteRenderGetTagProps) => {
      return sdgs.map(({ goalNumber, name }, index) => {
        const tagProps = getTagProps({ index })
        return (
          <Chip
            {...tagProps}
            key={name}
            label={`${goalNumber}. ${name}`}
            size="small"
          />
        )
      })
    },
    [],
  )

  return (
    <Controller
      control={control}
      name="product.sdgs"
      render={({
        fieldState: { error },
        field: { name, value: selectedSdgs, onChange },
      }) => {
        return (
          <Autocomplete
            options={allSdgs}
            getOptionLabel={(sdg) => sdg.name}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            filterOptions={filterOptions}
            renderInput={renderInput(name, error)}
            renderOption={renderOption}
            renderTags={renderTags}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            multiple
            disableCloseOnSelect
            size="small"
            fullWidth
            sx={{
              width: "50%",
              ".Mui-focused .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedSdgs.length < allSdgs.length ? "visible" : "hidden",
              },
              "&:hover .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedSdgs.length === allSdgs.length ? "hidden" : "visible",
              },
            }}
            value={selectedSdgs}
          />
        )
      }}
    />
  )
}
