import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Link } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-pro"
import {
  Proposal,
  type InitialProposal,
} from "~/proposals/models/proposal.model"

export const getCompaniesGridColDef = <T extends Proposal = InitialProposal>(
  hubSpotCompanyUrl: string,
): GridColDef<T> => {
  return {
    field: "company",
    headerName: "Company",
    type: "string",
    sortable: true,
    valueGetter: ({ row: { company } }) => company.properties.name,
    renderCell: ({ row: { company } }) => {
      return (
        <Link
          href={`${hubSpotCompanyUrl}/${company.id}`}
          rel="noopener"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          sx={{ display: "flex", alignItems: "center", gap: "0.25rem" }}
        >
          {company.properties.name}
          <OpenInNewIcon sx={{ fontSize: "0.75rem" }} />
        </Link>
      )
    },
  }
}
