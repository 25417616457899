import type { LinkProps } from "@mui/material/Link"
import { createTheme } from "@mui/material/styles"
import { colors } from "./colors"
import { LinkBehavior } from "./components/LinkBehavior"

export const theme = createTheme({
  typography: {
    fontFamily: [
      '"Hanken Grotesk"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(", "),
  },
  spacing: 16,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Hanken Grotesk';
          font-style: normal;
          font-weight: 400;
        }
      `,
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    primary: {
      main: colors.green[600],
      "50": colors.green[50],
      "100": colors.green[100],
      "200": colors.green[200],
      "300": colors.green[300],
      "400": colors.green[400],
      "500": colors.green[500],
      "600": colors.green[600],
      "700": colors.green[700],
      "800": colors.green[800],
      "900": colors.green[900],
    },
    secondary: {
      main: colors.white,
    },
    error: {
      main: colors.red[400],
      "50": colors.red[50],
      "100": colors.red[100],
      "200": colors.red[200],
      "300": colors.red[300],
      "400": colors.red[400],
      "500": colors.red[500],
      "600": colors.red[600],
      "700": colors.red[700],
      "800": colors.red[800],
      "900": colors.red[900],
    },
    green: colors.green,
    grey: colors.grey,
    red: colors.red,
    yellow: colors.yellow,
    blue: colors.blue,
    purple: colors.purple,
    orange: colors.orange,
  },
})
