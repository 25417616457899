import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Notification } from "./notification.model"

type Err = string
type Ok = {
  notifications: Notification[]
  updatedAt: number
}

export class NotificationStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("NotificationStore", initial)
  }
}
