import { Box, Tooltip } from "@mui/material"
import { take } from "~/utils/utils.array"
import type { Country } from "../country.model"

type Props = {
  _id: string
  countries: Country[]
}

export function CountriesDataGrid({ _id, countries }: Props) {
  return (
    <Box>
      {take(3, countries).map((country) => (
        <Box
          key={`${_id}-${country.code}`}
          sx={{ display: "flex", columnGap: "0.5rem", verticalAlign: "middle" }}
        >
          <span>{country.unicodeFlag}</span>
          {country.acronym ? (
            <Tooltip title={country.name}>
              <span>{country.acronym}</span>
            </Tooltip>
          ) : (
            <span>{country.name}</span>
          )}
        </Box>
      ))}
      {countries.length > 3 && `+${countries.length - 3} more`}
    </Box>
  )
}
