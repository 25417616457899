import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { InputsDto } from "./dtos/inputs.dto"
import type { InputsConfig } from "./inputs.config"
import type { InputsStore } from "./inputs.store"

export class InputsService {
  private readonly name = "InputsService"

  constructor(
    private readonly httpService: HttpService,
    private readonly inputsConfig: InputsConfig,
    private readonly inputsStore: InputsStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.inputsStore.state)) {
      return Logger.info(
        `${id} Inputs are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.inputsStore.state)) {
      return Logger.info(`${id} Inputs found in cache, not fetching again.`)
    }

    try {
      this.inputsStore.state = loading

      const { errors, data: inputs } = await this.httpService.get(
        this.inputsConfig.apiUrl,
        InputsDto,
      )

      if (errors.length) {
        const msg = "Invalid inputs"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.inputsStore.state = failure(msg)
        return
      }

      this.inputsStore.state = success({ inputs })
    } catch (error) {
      const msg = "Failed to fetch inputs"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.inputsStore.state = failure(msg)
    }
  }
}
