import { HttpModule } from "~/http/http.module"
import { ProposalService } from "./proposal.service"
import { ProposalsStore } from "./proposals.store"

export class ProposalsModule {
  private readonly _store: ProposalsStore
  private readonly _service: ProposalService

  constructor(private readonly httpModule: HttpModule) {
    this._store = new ProposalsStore()

    this._service = new ProposalService(this.httpModule.service, this._store)
  }

  public get service() {
    return this._service
  }

  public get store() {
    return this._store
  }
}
