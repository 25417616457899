import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { Instrument } from "./instrument.model"

type State = {
  instruments: Instrument[]
  isLoading: boolean
  error: string | null
}

export const useInstrumentsStore = () => {
  const {
    instrumentsModule: { service, store },
  } = useAppContext()
  const [state, setState] = useState<State>({
    instruments: withSuccess(store.state, [], ({ instruments }) => instruments),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          instruments: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ instruments }) => {
        setState({ instruments, isLoading: false, error: null })
      },
    }),
  )

  const fetchInstruments = useCallback(async () => {
    await service.fetchAll()
  }, [service])

  return {
    state,
    actions: { fetchInstruments },
  }
}
