import { Box, Chip } from "@mui/material"
import { useCallback, useContext, useMemo } from "react"
import { useCountriesStore } from "~/countries/countries.use-countries-store"
import { ProductKind } from "~/proposals/models/product.model"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { Region } from "../region.model"
import { useRegionsStore } from "../regions.use-region-store"

enum RegionType {
  GEOGRAPHICAL,
  GEOPOLITICAL,
}

export const RegionsField = () => {
  const {
    state: { countries: allCountries },
  } = useCountriesStore()
  const {
    state: { regions: allRegions },
  } = useRegionsStore()
  const { setValue, watch, getValues } = useContext(ProposalFormContext)
  const selectedCountries = watch("countries", getValues("countries"))
  const productKind = watch("product.kind", getValues("product.kind"))

  const productRegions = allRegions.filter((region) => {
    switch (productKind) {
      case ProductKind.BIOGAS: {
        return (
          region.name !== "Developing Countries" &&
          region.name !== "Undeveloped Countries" &&
          region.acronym !== "AIB"
        )
      }
      case ProductKind.CARBON: {
        return region.name !== "United Kingdom" && region.acronym !== "AIB"
      }
      case ProductKind.WHITE_CERTIFICATE: {
        return false
      }
      case ProductKind.GUARANTEE_OF_ORIGIN: {
        return (
          region.acronym === "AIB" ||
          region.name === "Grid Connected" ||
          region.name === "Nordics" ||
          region.name === "Alpine" ||
          region.name === "United Kingdom"
        )
      }
      case ProductKind.INT_REC: {
        return (
          region.name !== "Europe" &&
          region.name !== "North America" &&
          region.name !== "United Kingdom" &&
          region.acronym !== "AIB"
        )
      }
      case ProductKind.US_REC: {
        return region.name === "North America"
      }
    }
  })

  const handleRegionChange = useCallback(
    (region: Region, regionType: RegionType) => {
      const isRegionGeopolitical = regionType === RegionType.GEOPOLITICAL

      // Geopolitical regions are considered selected only when fully, exclusively selected.
      const isRegionSelected = isRegionGeopolitical
        ? region.countries.some((country) => {
            return selectedCountries.map(({ _id }) => _id).includes(country._id)
          }) && region.countries.length === selectedCountries.length
        : region.countries.some((country) => {
            return selectedCountries.map(({ _id }) => _id).includes(country._id)
          })

      // If region is already selected, deselect it
      if (isRegionSelected) {
        setValue(
          "countries",
          selectedCountries.filter(
            ({ regions }) =>
              !regions.map(({ _id }) => _id).includes(region._id),
          ),
        )
        return
      }

      const nextCountries = allCountries.filter(({ regions }) =>
        regions.map(({ _id }) => _id).includes(region._id),
      )

      // Geopolitical regions are only single-select: no mixing.
      if (isRegionGeopolitical) {
        setValue("countries", nextCountries)
      } else {
        setValue(
          "countries",
          Array.from(new Set([...selectedCountries, ...nextCountries])),
        )
      }
    },
    [selectedCountries],
  )

  const isContinentalRegion = useCallback((region: Region): boolean => {
    const continents = [
      "Asia",
      "Africa",
      "Europe",
      "North America",
      "Oceania",
      "Latin America",
      "Middle East",
    ]

    return continents.includes(region.name)
  }, [])

  const continentalRegions = useMemo(
    () => productRegions.filter((region) => isContinentalRegion(region)),
    [productRegions],
  )

  const geoPoliticalRegions = useMemo(
    () => productRegions.filter((region) => !isContinentalRegion(region)),
    [productRegions],
  )

  const getRegionChip = useCallback(
    (region: Region, regionType: RegionType) => {
      // Geopolitical regions are considered fully selected only when also exclusively selected.
      const isRegionFullySelected =
        regionType === RegionType.GEOPOLITICAL
          ? region.countries.every((country) => {
              return selectedCountries
                .map(({ _id }) => _id)
                .includes(country._id)
            }) && region.countries.length === selectedCountries.length
          : region.countries.every((country) => {
              return selectedCountries
                .map(({ _id }) => _id)
                .includes(country._id)
            })

      const isRegionPartiallySelected =
        regionType === RegionType.GEOPOLITICAL
          ? false
          : region.countries.some((country) => {
              return selectedCountries
                .map(({ _id }) => _id)
                .includes(country._id)
            })

      return (
        <Chip
          key={region._id}
          label={region.acronym ?? region.name}
          onClick={() => handleRegionChange(region, regionType)}
          color="primary"
          variant={isRegionFullySelected ? "filled" : "outlined"}
          sx={(theme) => ({
            background: isRegionFullySelected
              ? theme.palette.primary.main
              : isRegionPartiallySelected
                ? `repeating-linear-gradient(45deg, ${theme.palette.green[600]}, ${theme.palette.green[600]} 9px, ${theme.palette.green[100]} 1px, ${theme.palette.green[200]} 10px)`
                : theme.palette.common.white,
            color:
              isRegionFullySelected || isRegionPartiallySelected
                ? theme.palette.common.white
                : theme.palette.primary.main,
            "&:hover": {
              background: isRegionFullySelected
                ? theme.palette.primary.dark
                : isRegionPartiallySelected
                  ? `repeating-linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.dark} 9px, ${theme.palette.green[100]} 1px, ${theme.palette.green[200]} 10px)`
                  : theme.palette.red[700],
            },
          })}
        />
      )
    },
    [selectedCountries, handleRegionChange],
  )

  if (productRegions.length <= 1) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        width: "100%",
      }}
    >
      {continentalRegions.length > 0 && (
        <Box
          sx={{
            display: "flex",
            height: "fit-content",
            gap: "0.5rem",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "50%",
          }}
        >
          {continentalRegions.map((region) =>
            getRegionChip(region, RegionType.GEOGRAPHICAL),
          )}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          height: "fit-content",
          gap: "0.5rem",
          flexWrap: "wrap",
          justifyContent: "center",
          width: continentalRegions.length > 0 ? "50%" : "100%",
        }}
      >
        {geoPoliticalRegions.map((region) =>
          getRegionChip(region, RegionType.GEOPOLITICAL),
        )}
      </Box>
    </Box>
  )
}
