import { Type } from "class-transformer"
import { IsArray } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Attachment } from "../attachment.model"

export class AttachmentsDto implements HttpDto<Attachment[]> {
  @Type(() => Attachment)
  @IsArray()
  readonly data: Attachment[]
}
