import { Button, ButtonGroup } from "@mui/material"
import { useContext } from "react"
import { Controller, useFormState } from "react-hook-form"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { PriceRequestKind } from "../price-request.model"

const kinds: Array<{ label: string; value: PriceRequestKind }> = [
  { label: "Firm", value: PriceRequestKind.FIRM },
  { label: "RFQ", value: PriceRequestKind.RFQ },
  { label: "Indicative", value: PriceRequestKind.INDICATIVE },
  { label: "Ongoing", value: PriceRequestKind.ONGOING },
]

export const KindField = () => {
  const { control, trigger } = useContext(ProposalFormContext)
  const { isSubmitted } = useFormState({ control })

  return (
    <Controller
      control={control}
      name="priceRequest.kind"
      render={({ field: { value, onChange } }) => {
        return (
          <ButtonGroup sx={{ width: "50%" }}>
            {kinds.map((kind) => (
              <Button
                key={kind.value}
                variant={kind.value === value ? "contained" : "outlined"}
                onClick={() => {
                  onChange(kind.value)

                  if (isSubmitted) {
                    trigger([
                      "priceRequest.deadline",
                      "priceRequest.currency",
                      "priceRequest.price",
                      "priceRequest.validity",
                      "priceRequest.delivery",
                    ])
                  }
                }}
              >
                {kind.label}
              </Button>
            ))}
          </ButtonGroup>
        )
      }}
    />
  )
}
