import { HttpModule } from "~/http/http.module"
import { InstrumentsConfig } from "./instruments.config"
import { InstrumentsService } from "./instruments.service"
import { InstrumentsStore } from "./instruments.store"

export class InstrumentsModule {
  private readonly _instrumentsConfig: InstrumentsConfig
  private readonly _instrumentsStore: InstrumentsStore
  private readonly _instrumentsService: InstrumentsService

  constructor(private readonly httpModule: HttpModule) {
    this._instrumentsConfig = new InstrumentsConfig()
    this._instrumentsStore = new InstrumentsStore()
    this._instrumentsService = new InstrumentsService(
      this.httpModule.service,
      this._instrumentsConfig,
      this._instrumentsStore,
    )
  }

  public get store() {
    return this._instrumentsStore
  }

  public get service() {
    return this._instrumentsService
  }
}
