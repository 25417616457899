import { Type } from "class-transformer"
import { IsArray, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Company } from "../company.model"

export class CompaniesDto implements HttpDto<Company[]> {
  @Type(() => Company)
  @IsArray()
  @ValidateNested({ each: true })
  readonly data: Company[]
}
