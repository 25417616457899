import { Type } from "class-transformer"
import {
  IsDate,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator"
import { IsNullable } from "~/utils/utils.validation"

export class CompanyProperties {
  @IsString()
  @IsNullable()
  @IsOptional()
  domain: string | null

  @IsString()
  name: string
}

export class Company {
  @ValidateNested()
  @Type(() => CompanyProperties)
  @IsNotEmpty()
  properties: CompanyProperties

  @IsNotEmpty()
  @IsString()
  id: string

  @IsDate()
  @Type(() => Date)
  @IsNotEmpty()
  createdAt: Date

  @IsDate()
  @Type(() => Date)
  updatedAt: Date
}
