export const findOrThrow = <T>(
  array: readonly T[],
  predicate: (value: T) => boolean,
  error: Error,
): T => {
  const found = array.find(predicate)

  if (!found) {
    throw error
  }

  return found
}

export const pickRandomElement = <T>(array: readonly T[]): T =>
  array[Math.floor(Math.random() * array.length)]

export const pickRandomElements = <T>(array: readonly T[]): T[] => {
  const shuffled = [...array].sort(() => 0.5 - Math.random())
  const numberOfElements = Math.min(3, Math.ceil(Math.random() * array.length))
  return shuffled.slice(0, numberOfElements)
}

export const take = <T>(count: number, array: readonly T[]): T[] =>
  array.slice(0, count)

export const unique = <T>(array: readonly T[]): T[] => [...new Set(array)]
