import { Box } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { InstrumentsField } from "~/instruments/views/instruments-field.view"
import { LabelsField } from "~/labels/views/labels-field.view"
import { ProposalKind } from "~/proposals/models/proposal.model"
import { SdgsField } from "~/sdgs/views/sdgs-field.view"
import { TechnologiesField } from "~/technologies/views/technologies-field.view"
import { ProposalFormContext } from "../../proposals.context"
import { ProjectIdField } from "./proposal.project-id-field.view"
import { TransferabilityField } from "./proposal.transferability-field.view"
import { YearField } from "./proposal.year-field"

export function CarbonFields() {
  const { control, getValues, watch } = useContext(ProposalFormContext)
  const proposalKind = watch("kind", getValues("kind"))
  const instruments = watch(
    "product.instruments",
    getValues("product.instruments"),
  )

  const isCDM = instruments
    .map((instrument) => instrument.acronym)
    .includes("CDM")

  return (
    <>
      {isCDM && <TransferabilityField />}
      <Box sx={{ display: "flex", gap: 2 }}>
        <InstrumentsField />
        <TechnologiesField />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <LabelsField />
        <SdgsField />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        {proposalKind === ProposalKind.OFFER && <ProjectIdField />}
        <Box sx={{ width: "50%" }}>
          <Controller
            control={control}
            name="product.vintage.from"
            render={({ fieldState, field: { value, onChange } }) => {
              return (
                <YearField
                  error={fieldState.error?.message}
                  label={
                    proposalKind === ProposalKind.REQUEST
                      ? "Vintage (from)"
                      : "Vintage"
                  }
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </Box>

        {proposalKind === ProposalKind.REQUEST && (
          <Box sx={{ width: "50%" }}>
            <Controller
              control={control}
              name="product.vintage.to"
              render={({ fieldState, field: { value, onChange } }) => {
                return (
                  <YearField
                    error={fieldState.error?.message}
                    label="Vintage (to)"
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
