import type { HttpService } from "~/http/http.service"
import { success, withSuccess } from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { FeatureFlagDto } from "./feature-flags.dto"
import type { FeatureFlagsStore } from "./feature-flags.store"

export class FeatureFlagsService {
  constructor(
    private readonly httpService: HttpService,
    private readonly store: FeatureFlagsStore,
  ) {}

  public async findOne(id: string) {
    const name = "[FeatureFlagsService::findOne]"

    const { featureFlags: prevFeatureFlags } = withSuccess(
      this.store.state,
      { featureFlags: {}, updatedAt: 0 },
      (data) => data,
    )

    try {
      const { errors, data: featureFlag } = await this.httpService.get(
        `/api/feature-flags/${id}`,
        FeatureFlagDto,
      )

      if (errors.length) {
        const msg = "Invalid feature flag"
        Logger.error(`${name} ${msg}`)
        Logger.error(errors)
        return
      }

      this.store.state = success({
        featureFlags: { ...prevFeatureFlags, ...featureFlag },
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to fetch feature flag"
      Logger.error(`${name} ${msg}`)
      Logger.error(error)
    }
  }
}
