import { Type } from "class-transformer"
import { IsObject, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { User } from "../user.model"

export class UserDto implements HttpDto<User> {
  @Type(() => User)
  @IsObject()
  @ValidateNested()
  declare readonly data: User
}
