import { classValidatorResolver } from "@hookform/resolvers/class-validator"
import { Box } from "@mui/material"
import {
  GridColDef,
  type GridColumnVisibilityModel,
} from "@mui/x-data-grid-pro"
import { getCompaniesGridColDef } from "~/companies/companies.utils"
import { getCountriesGridColDef } from "~/countries/countries.utils"
import type { Locale } from "~/locale/locale.model"
import { dateFormatter } from "~/locale/locale.utils"
import { getRegionsGridColDef } from "~/regions/region.utils"
import { Actions } from "~/ui/components/ui.actions.component"
import type { QuickFilters } from "~/ui/ui.models"
import { Avatar } from "~/user/user.avatar.component"
import { take } from "~/utils/utils.array"
import {
  guaranteeOfOriginSupportTypeLabels,
  ProductKind,
} from "./models/product.model"
import {
  BaseProposal,
  Proposal,
  ProposalKind,
  State,
  type InitialProposal,
} from "./models/proposal.model"
import { getPriceRequestsGridColDef } from "./price-requests/price-requests.utils"
import { MatchCount } from "./views/grid-views/proposal.match-count.view"
import { Volume } from "./views/grid-views/proposal.volume.view"

export const resolver = classValidatorResolver(BaseProposal)

type FormatOptions = {
  case: "lower" | "title"
  form: "noun" | "verb"
}

const defaultFormatOptions: FormatOptions = {
  case: "title",
  form: "noun",
}

export const formatProposalKind = (
  value: ProposalKind,
  options = defaultFormatOptions,
) => {
  switch (value) {
    case ProposalKind.OFFER: {
      if (options.form === "noun") {
        return options.case === "lower" ? "offer" : "Offer"
      } else {
        return options.case === "lower" ? "offering" : "Offering"
      }
    }

    case ProposalKind.REQUEST: {
      if (options.form === "noun") {
        return options.case === "lower" ? "request" : "Request"
      } else {
        return options.case === "lower" ? "requesting" : "Requesting"
      }
    }
  }
}

export const formatProductKind = (
  value: ProductKind,
  options = defaultFormatOptions,
) => {
  switch (value) {
    case ProductKind.BIOGAS:
      return options.case === "lower" ? "biogas" : "Biogas"
    case ProductKind.CARBON:
      return options.case === "lower" ? "carbon" : "Carbon"
    case ProductKind.WHITE_CERTIFICATE:
      return options.case === "lower" ? "white cert" : "White Cert"
    case ProductKind.GUARANTEE_OF_ORIGIN:
      return "GO"
    case ProductKind.INT_REC:
      return "I-REC"
    case ProductKind.US_REC:
      return "US-REC"
  }
}

// Formats notes by replacing escape and ambigous characters
// with appropriate HTML counterparts.
//
// Reminder that to properly render such a string with React,
// the `dangerourslySetInnerHTML` prop must be used.
export const formatNotes = (notes: string): string => {
  return (
    notes
      // Newlines
      .replace(/\n/g, "<br/>")
      // Tabs
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      // Dash
      .replace(/-/g, "&#8208;")
      // Double quotes
      .replace(/\\"/g, "&quot;")
      // Single quotes
      .replace(/\\'/g, "&apos;")
  )
}

function getProductColumns<
  T extends Proposal = InitialProposal,
>(): GridColDef<T>[] {
  return [
    {
      field: "input",
      headerName: "Input",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { product } }) => {
        if (product.kind !== ProductKind.BIOGAS) return null

        return product.input.name
      },
    },
    {
      field: "instruments",
      headerName: "Instruments",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { product } }) => {
        if (
          product.kind !== ProductKind.CARBON &&
          product.kind !== ProductKind.WHITE_CERTIFICATE
        ) {
          return null
        }

        return product.instruments
          .map(({ acronym, name }) => (acronym ? `${name} (${acronym})` : name))
          .sort()
          .join(", ")
      },
      valueFormatter: ({ value }: { value: string | null }) => {
        if (value === null) return null

        const instruments = value.split(", ").map((instrument) => {
          const acronym = instrument.match(/\((.*?)\)/)
          return acronym ? acronym[1] : instrument
        })

        return instruments.join(", ")
      },
      renderCell: ({ row: { product, _id } }) => {
        if (
          product.kind !== ProductKind.CARBON &&
          product.kind !== ProductKind.WHITE_CERTIFICATE
        ) {
          return null
        }

        return (
          <Box>
            {product.instruments.map(({ acronym, name }) => (
              <Box key={`${_id}-${name}`}>{acronym ? acronym : name}</Box>
            ))}
          </Box>
        )
      },
    },
    {
      field: "labels",
      headerName: "Labels",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { product } }) => {
        if (product.kind === ProductKind.WHITE_CERTIFICATE) return null

        return product.labels
          .map(({ acronym, name }) => (acronym ? `${name} (${acronym})` : name))
          .sort()
          .join(", ")
      },
      valueFormatter: ({ value }: { value: string | null }) => {
        if (value === null) return null

        const acronym = value.match(/\((.*?)\)/)
        return acronym ? acronym[1] : value
      },
      renderCell: ({ row: { product, _id } }) => {
        if (product.kind === ProductKind.WHITE_CERTIFICATE) return null

        return (
          <Box>
            {product.labels.map(({ acronym, name }) => (
              <Box key={`${_id}-${name}`}>{acronym ? acronym : name}</Box>
            ))}
          </Box>
        )
      },
    },
    {
      field: "technologies",
      headerName: "Technologies",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { product } }) => {
        if (product.kind === ProductKind.BIOGAS) return null

        return product.technologies
          .map(({ acronym, name }) => (acronym ? `${name} (${acronym})` : name))
          .sort()
          .join(", ")
      },
      valueFormatter: ({ value }: { value: string | null }) => {
        if (value === null) return null

        const acronym = value.match(/\((.*?)\)/)
        return acronym ? acronym[1] : value
      },
      renderCell: ({ row: { product, _id } }) => {
        if (product.kind === ProductKind.BIOGAS) return null

        return (
          <Box>
            {take(3, product.technologies).map(({ acronym, name }) => (
              <Box key={`${_id}-${name}`}>{acronym ? acronym : name}</Box>
            ))}
            {product.technologies.length > 3 &&
              `+${product.technologies.length - 3} more`}
          </Box>
        )
      },
    },
    {
      field: "supportTypes",
      headerName: "Support",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { product } }) => {
        if (product.kind !== ProductKind.GUARANTEE_OF_ORIGIN) return null

        return product.supportTypes.sort().join(", ")
      },
      renderCell: ({ row: { product, _id } }) => {
        if (product.kind !== ProductKind.GUARANTEE_OF_ORIGIN) return null

        return (
          <Box>
            {take(3, product.supportTypes).map((supportType) => (
              <Box key={`${_id}-${supportType}`}>
                {guaranteeOfOriginSupportTypeLabels.get(supportType)}
              </Box>
            ))}
          </Box>
        )
      },
    },
    {
      field: "sdgs",
      headerName: "SDGs",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { product } }) => {
        if (product.kind !== ProductKind.CARBON) return null

        return product.sdgs
          .map(({ goalNumber, name }) => `${goalNumber}. ${name}`)
          .sort()
          .join(", ")
      },
    },
  ]
}

export const getColumnVisibilityModel = (
  selectedProduct: ProductKind,
): GridColumnVisibilityModel => {
  const defaultColumns = {
    validity: false,
    delivery: false,
    createdAt: false,
    supportTypes: false,
  }

  switch (selectedProduct) {
    case ProductKind.BIOGAS: {
      return {
        input: true,
        instruments: false,
        labels: false,
        technologies: false,
        sdgs: false,
        ...defaultColumns,
      }
    }

    case ProductKind.CARBON: {
      return {
        input: false,
        instruments: true,
        labels: false,
        technologies: false,
        sdgs: false,
        ...defaultColumns,
      }
    }

    case ProductKind.WHITE_CERTIFICATE: {
      return {
        input: false,
        instruments: true,
        labels: false,
        technologies: false,
        sdgs: false,
        ...defaultColumns,
      }
    }

    case ProductKind.GUARANTEE_OF_ORIGIN: {
      return {
        input: false,
        instruments: false,
        labels: false,
        technologies: false,
        sdgs: false,
        ...defaultColumns,
      }
    }

    case ProductKind.INT_REC: {
      return {
        input: false,
        instruments: false,
        labels: false,
        technologies: false,
        sdgs: false,
        ...defaultColumns,
      }
    }

    case ProductKind.US_REC: {
      return {
        input: false,
        instruments: false,
        labels: false,
        technologies: false,
        sdgs: false,
        ...defaultColumns,
      }
    }
  }
}

export const getColumns = <T extends Proposal = InitialProposal>(
  hubSpotCompaniesUrl: string,
  locale: Locale,
  quickFilters: QuickFilters,
): GridColDef<T>[] => {
  const formatDate = dateFormatter(locale)

  return [
    {
      field: "matches",
      headerName: "Matches",
      type: "number",
      align: "left",
      headerAlign: "left",
      sortable: true,
      valueGetter: ({ row }) => {
        return row.state === State.MATCHED ? row.matches.length : 0
      },
      renderCell: ({ row }) => {
        return (
          <MatchCount
            count={row.state === State.MATCHED ? row.matches.length : 0}
          />
        )
      },
    },
    {
      field: "go2Id",
      headerName: "GO2 ID",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { go2Id } }) => go2Id,
    },
    getCompaniesGridColDef<T>(hubSpotCompaniesUrl),
    getRegionsGridColDef<T>(),
    getCountriesGridColDef<T>(),
    ...getProductColumns<T>(),
    ...getPriceRequestsGridColDef<T>(locale, quickFilters),
    {
      field: "volume",
      headerName: "Volume",
      type: "number",
      sortable: true,
      valueGetter: ({ row: { volume } }) => volume,
      renderCell: ({ row: { volume } }) => <Volume volume={volume} />,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      type: "date",
      sortable: true,
      valueGetter: ({ row: { createdAt } }) => createdAt,
      valueFormatter: ({ value }: { value: Date | null }) => {
        if (value === null) {
          return null
        }

        return formatDate(value)
      },
    },
    {
      field: "updatedAt",
      headerName: "Last modified",
      type: "date",
      valueGetter: ({ row: { updatedAt } }) => updatedAt,
      valueFormatter: ({ value }: { value: Date | null }) => {
        if (value === null) {
          return null
        }

        return formatDate(value)
      },
    },
    {
      field: "createdBy",
      headerName: "Created by",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { createdBy } }) => {
        return createdBy.name
      },
      renderCell: ({ row: { createdBy } }) => <Avatar user={createdBy} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <Actions id={params.row._id} />
      },
    },
  ]
}
