import {
  IsBoolean,
  IsNotEmpty,
  IsObject,
  IsOptional,
  IsString,
} from "class-validator"
import type { Proposal } from "~/proposals/models/proposal.model"

export class Notification {
  @IsNotEmpty()
  @IsString()
  readonly _id: string

  @IsString()
  @IsNotEmpty()
  readonly title: string

  @IsString()
  @IsNotEmpty()
  readonly message: string

  @IsBoolean()
  @IsNotEmpty()
  readonly isRead: boolean

  @IsString()
  @IsNotEmpty()
  readonly entraId: string

  @IsObject()
  @IsOptional()
  readonly data: Pick<Proposal, "_id" | "kind">
}
