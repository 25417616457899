import { Box, Chip } from "@mui/material"
import { GridColDef, type GridValueFormatterParams } from "@mui/x-data-grid-pro"
import dayjs from "dayjs"
import type { Locale } from "~/locale/locale.model"
import { currencyFormatter, dateFormatter } from "~/locale/locale.utils"
import type { QuickFilters } from "~/ui/ui.models"
import { format } from "~/utils/utils.string"
import { ProductKind } from "../models/product.model"
import {
  Proposal,
  ProposalKind,
  type InitialProposal,
} from "../models/proposal.model"
import { PriceRequestKind } from "./price-request.model"
import { Price } from "./price-requests.data-grid.component"

export const formatPriceRequestKind = (kind: PriceRequestKind) => {
  switch (kind) {
    case PriceRequestKind.FIRM:
      return "Firm"
    case PriceRequestKind.INDICATIVE:
      return "Indicative"
    case PriceRequestKind.ONGOING:
      return "Ongoing"
  }
}

export const getPriceRequestsGridColDef = <
  T extends Proposal = InitialProposal,
>(
  locale: Locale,
  quickFilters: QuickFilters,
): GridColDef<T>[] => {
  return [
    {
      field: "priceRequest.kind",
      headerName: "Status",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { priceRequest } }) => {
        return priceRequest.kind
      },
      valueFormatter: ({ value }: { value: string }) => {
        return format(value, { case: "title", form: "singular" })
      },
      renderCell: ({ row: { priceRequest } }) => {
        return getPriceRequestChip(priceRequest.kind, true)
      },
    },
    {
      field: "deadline",
      headerName: "Deadline",
      type: "dateTime",
      sortable: true,
      valueGetter: ({ row: { priceRequest } }) => priceRequest.deadline,
      valueFormatter: ({ value }: { value: Date | null }) => {
        if (value === null) return null

        const formatDate = dateFormatter(locale, {
          dateStyle: "short",
          timeStyle: "short",
        })

        return formatDate(value)
      },
      renderCell: ({ row: { priceRequest } }) => {
        if (priceRequest.deadline === null) return null
        const deadline = dayjs(priceRequest.deadline).startOf("day")
        const now = dayjs().startOf("day")

        let color: string
        switch (deadline.diff(now, "days")) {
          case 0: {
            color = "red.600"
            break
          }
          case 1: {
            color = "orange.600"
            break
          }
          default: {
            color = "text.primary"
          }
        }

        const formatDate = dateFormatter(locale, {
          dateStyle: "short",
          timeStyle: "short",
        })

        return <Box color={color}>{formatDate(priceRequest.deadline)}</Box>
      },
    },
    {
      field: "validity",
      headerName: "Validity",
      type: "dateTime",
      sortable: true,
      valueGetter: ({ row: { priceRequest } }) => priceRequest.validity,
      valueFormatter: ({ value }: { value: Date | null }) => {
        if (value === null) return null

        const formatDate = dateFormatter(locale, {
          dateStyle: "short",
          timeStyle: "short",
        })

        return formatDate(value)
      },
    },
    {
      field: "delivery",
      headerName: "Delivery",
      type: "date",
      sortable: true,
      valueGetter: ({ row: { priceRequest } }) => priceRequest.delivery,
      valueFormatter: ({ value }: { value: Date | null }) => {
        if (value === null) return null

        const formatDate = dateFormatter(locale, {
          year: "numeric",
          month: "long",
        })

        return formatDate(value)
      },
    },
    {
      field:
        quickFilters.productKind === ProductKind.CARBON
          ? "vintage"
          : "productionYear",
      headerName:
        quickFilters.productKind === ProductKind.CARBON
          ? "Vintage"
          : "Production year",
      type: "string",
      sortable: true,
      valueGetter: ({ row: { kind, product } }) => {
        if (kind === ProposalKind.REQUEST) {
          if (product.kind === ProductKind.CARBON) {
            return `${product.vintage.from.getFullYear()}${product.vintage.to ? " - " + product.vintage.to.getFullYear() : ""}`
          } else {
            return `${product.productionYear.from.getFullYear()}${product.productionYear.to ? " - " + product.productionYear.to.getFullYear() : ""}`
          }
        } else if (kind === ProposalKind.OFFER) {
          if (product.kind === ProductKind.CARBON) {
            return `${product.vintage.from.getFullYear()}`
          } else {
            return `${product.productionYear.from.getFullYear()}`
          }
        }
      },
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      sortable: true,
      valueGetter: ({ row: { priceRequest } }) => priceRequest.price,
      valueFormatter: ({
        value: price,
        api,
        id,
      }: GridValueFormatterParams<number>) => {
        if (id !== undefined) {
          const currency = api.getRow<Proposal>(id)?.priceRequest.currency

          if (currency != null) {
            return currencyFormatter(locale, currency.code)(price)
          }
        }

        return null
      },
      renderCell: ({
        row: {
          priceRequest: { currency, price },
        },
      }) => {
        if (currency === null || price === null) return null

        return <Price currencyCode={currency.code} price={price} />
      },
    },
  ]
}

export const getPriceRequestChip = (
  priceRequestKind: PriceRequestKind,
  active: boolean,
  onClick?: () => void,
) => {
  const PriceRequestKinds = {
    [PriceRequestKind.FIRM]: {
      label: "Firm",
      color: "green.500",
    },
    [PriceRequestKind.RFQ]: {
      label: "RFQ",
      color: "green.500",
    },
    [PriceRequestKind.INDICATIVE]: {
      label: "Indicative",
      color: "green.500",
    },
    [PriceRequestKind.ONGOING]: {
      label: "Ongoing",
      color: "green.500",
    },
  }

  return (
    <Chip
      key={priceRequestKind}
      label={PriceRequestKinds[priceRequestKind].label}
      size="small"
      onClick={onClick}
      sx={{
        backgroundColor: active
          ? PriceRequestKinds[priceRequestKind].color
          : "white",
        border: `1px solid`,
        borderColor: active
          ? "white"
          : PriceRequestKinds[priceRequestKind].color,
        color: active ? "white" : "primary.main",
        "&:hover": onClick
          ? {
              backgroundColor: PriceRequestKinds[priceRequestKind].color,
              color: "white",
            }
          : {},
      }}
    />
  )
}
