import SelectAllIcon from "@mui/icons-material/Add"
import {
  Autocomplete,
  Chip,
  IconButton,
  ListItem,
  TextField,
  type AutocompleteRenderGetTagProps,
  type AutocompleteRenderInputParams,
  type FilterOptionsState,
} from "@mui/material"
import React, { useCallback, useContext } from "react"
import { Controller, type FieldError } from "react-hook-form"
import { ProposalKind } from "~/proposals/models/proposal.model"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { Tooltip } from "~/ui/ui.tooltip.component"
import { useRegistriesStore } from "../registries.use-registries-store.hook"
import type { Registry } from "../registry.model"

export function RegistriesField() {
  const {
    state: { registries: allRegistries },
  } = useRegistriesStore()
  const { control, getValues, setValue, watch } =
    useContext(ProposalFormContext)
  const proposalKind = watch("kind", getValues("kind"))
  const isRequest = proposalKind === ProposalKind.REQUEST
  const selectedRegistries = watch(
    "product.registries",
    getValues("product.registries"),
  )

  const selectAll = useCallback(() => {
    setValue("product.registries", allRegistries)
  }, [allRegistries])

  const filterOptions = useCallback(
    (options: Registry[], { inputValue }: FilterOptionsState<Registry>) => {
      return options.filter((option) => {
        return (
          option.description.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.acronym?.toLowerCase().includes(inputValue.toLowerCase())
        )
      })
    },
    [],
  )

  const renderInput = useCallback(
    (name: string, error: FieldError | undefined) =>
      (params: AutocompleteRenderInputParams) => {
        const showSelectAllBtn =
          isRequest && selectedRegistries.length < allRegistries.length
        let endAdornment = null

        if (React.isValidElement(params.InputProps.endAdornment)) {
          endAdornment = React.cloneElement(params.InputProps.endAdornment, {
            ...params.InputProps.endAdornment.props,
            children: showSelectAllBtn
              ? [
                  <IconButton
                    key={`${name}-MuiAutocomplete-selectAllIndicator`}
                    size="medium"
                    onClick={selectAll}
                    aria-label="Select all"
                    title="Select all"
                    className="MuiAutocomplete-selectAllIndicator"
                    sx={{ visibility: "hidden", p: "3px", mr: "-2px" }}
                  >
                    <SelectAllIcon sx={{ fontSize: "1.35rem" }} />
                  </IconButton>,
                  ...params.InputProps.endAdornment.props.children,
                ]
              : params.InputProps.endAdornment.props.children,
          })
        }

        return (
          <TextField
            {...params}
            name={name}
            label="Registries"
            error={Boolean(error)}
            helperText={error?.message}
            required
            InputProps={{
              ...params.InputProps,
              endAdornment,
            }}
          />
        )
      },
    [allRegistries, selectedRegistries, selectAll],
  )

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: Registry) => {
      if (option.acronym) {
        return (
          <Tooltip key={option._id} title={option.description} placement="left">
            <ListItem {...props}>{option.acronym}</ListItem>
          </Tooltip>
        )
      }

      return (
        <ListItem key={option._id} {...props}>
          {option.acronym}
        </ListItem>
      )
    },
    [],
  )

  const renderTags = useCallback(
    (value: Registry[], getTagProps: AutocompleteRenderGetTagProps) => {
      return value.map(({ acronym, description }, index) => {
        const tagProps = getTagProps({ index })
        return (
          <Chip
            {...tagProps}
            key={acronym}
            label={acronym ?? description}
            size="small"
          />
        )
      })
    },
    [],
  )

  return (
    <Controller
      control={control}
      name={"product.registries"}
      render={({
        fieldState: { error },
        field: { name, value: selectedRegistries, onChange },
      }) => {
        return (
          <Autocomplete
            options={allRegistries}
            getOptionLabel={(registry) => registry.acronym}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            filterOptions={filterOptions}
            renderInput={renderInput(name, error)}
            renderOption={renderOption}
            renderTags={renderTags}
            onChange={(_, newValue) => {
              if (newValue == null) {
                return onChange([])
              }

              if (Array.isArray(newValue)) {
                return onChange(newValue)
              }

              return onChange([newValue])
            }}
            multiple={isRequest}
            disableCloseOnSelect={isRequest}
            size="small"
            fullWidth
            sx={{
              width: "50%",
              ".Mui-focused .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedRegistries.length < allRegistries.length
                    ? "visible"
                    : "hidden",
              },
              "&:hover .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedRegistries.length === allRegistries.length
                    ? "hidden"
                    : "visible",
              },
            }}
            value={
              isRequest ? selectedRegistries : selectedRegistries[0] ?? null
            }
          />
        )
      }}
    />
  )
}
