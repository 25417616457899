import { HttpDelete } from "~/http/http.models"
import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
  withSuccess,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import {
  InitialProposalDto,
  ProposalDto,
  ProposalsDto,
  type CreateProposalDto,
  type UpdateProposalDto,
} from "./dtos/proposal.dto"
import { State, type Proposal } from "./models/proposal.model"
import type { ProposalState, ProposalsStore } from "./proposals.store"

export class ProposalService {
  constructor(
    private readonly httpService: HttpService,
    private readonly proposalsStore: ProposalsStore,
  ) {}

  public async fetchAll() {
    const id = "[ProposalService::fetchAll]"

    if (isLoading(this.proposalsStore.state)) {
      return Logger.info(
        `${id} Proposals are already being fetched, not fetching again.`,
      )
    }

    if (
      isSuccess(this.proposalsStore.state) &&
      Date.now() - this.proposalsStore.state.data.updatedAt < 60000
    ) {
      return Logger.info(`${id} Proposals found in cache, not fetching again.`)
    }

    try {
      this.proposalsStore.state = loading

      const { errors, data: proposals } = await this.httpService.get(
        `/api/proposals`,
        ProposalsDto,
      )

      if (errors.length) {
        const msg = "Invalid proposals"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.proposalsStore.state = failure(msg)
        return
      }

      this.proposalsStore.state = success({
        proposals,
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to fetch proposals"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.proposalsStore.state = failure(msg)
    }
  }

  public async fetchMany(ids: string[]) {
    const name = "[ProposalService::fetchMany]"

    if (isLoading(this.proposalsStore.state)) {
      Logger.info(
        `${name} Proposals are already being fetched, not fetching again.`,
      )
      return
    }

    const { proposals: prevProposals } = withSuccess(
      this.proposalsStore.state,
      { proposals: [], updatedAt: 0 },
      (data) => data,
    )

    try {
      this.proposalsStore.state = loading

      const { errors, data: proposals } = await this.httpService.get(
        `/api/proposals/findMany?ids=${ids.join(",")}`,
        ProposalsDto,
      )

      if (errors.length) {
        const msg = "Invalid proposals"
        Logger.error(`${name} ${msg}`)
        Logger.error(errors)
        this.proposalsStore.state = failure(msg)
        return
      }

      this.proposalsStore.state = success({
        proposals: [
          ...prevProposals.filter(
            (prevProposal) => !ids.includes(prevProposal._id),
          ),
          ...proposals,
        ],
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to fetch proposals"
      Logger.error(`${name} ${msg}`)
      Logger.error(error)
      this.proposalsStore.state = failure(msg)
    }
  }

  public async fetchOne(id: string) {
    const name = "[ProposalService::fetchOne]"

    if (isLoading(this.proposalsStore.state)) {
      Logger.info(
        `${name} Proposal is already being fetched, not fetching again.`,
      )
      return
    }

    let cachedProposal = null
    if (isSuccess(this.proposalsStore.state)) {
      cachedProposal = this.proposalsStore.state.data.proposals.find(
        (p) => p._id === id,
      )
      if (cachedProposal) {
        Logger.info(`${name} Proposal found in cache, not fetching again.`)
        return cachedProposal
      }
    }

    const { proposals: prevProposals } = withSuccess<
      string,
      ProposalState,
      ProposalState
    >(
      this.proposalsStore.state,
      { proposals: [], updatedAt: 0 },
      (data) => data,
    )

    try {
      this.proposalsStore.state = loading

      const { errors, data: proposal } = await this.httpService.get(
        `/api/proposals/${id}`,
        InitialProposalDto,
      )

      if (errors.length) {
        const msg = "Invalid proposal"
        Logger.error(`${name} ${msg}`)
        Logger.error(errors)
        this.proposalsStore.state = failure(msg)
        return
      }

      const proposals = [
        ...prevProposals.filter((proposal) => proposal._id !== id),
        proposal,
      ]

      this.proposalsStore.state = success({
        proposals,
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to fetch proposal"
      Logger.error(`${name} ${msg}`)
      Logger.error(error)
      this.proposalsStore.state = failure(msg)
    }
  }

  public async create(proposal: CreateProposalDto) {
    const id = "[ProposalService::create]"

    const { proposals: prevProposals } = withSuccess(
      this.proposalsStore.state,
      { proposals: [], updatedAt: 0 },
      (data) => data,
    )

    try {
      Logger.info(`${id} creating proposal`, proposal)

      this.proposalsStore.state = loading

      const { errors, data: createdProposal } = await this.httpService.post(
        "/api/proposals",
        proposal,
        ProposalDto,
      )

      if (errors.length) {
        const msg = "Invalid proposal"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.proposalsStore.state = failure(msg)
        return
      }

      const proposals = [createdProposal, ...prevProposals]

      this.proposalsStore.state = success({
        proposals,
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to create proposal"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.proposalsStore.state = failure(msg)
    }
  }

  public async update(_id: string, proposal: UpdateProposalDto) {
    const id = "[ProposalService::create]"

    const { proposals: prevProposals } = withSuccess(
      this.proposalsStore.state,
      { proposals: [], updatedAt: 0 },
      (data) => data,
    )

    try {
      Logger.info(`${id} updating proposal`, proposal)

      this.proposalsStore.state = loading

      const { errors, data: updatedProposal } = await this.httpService.put(
        `/api/proposals/${_id}`,
        proposal,
        ProposalDto,
      )

      if (errors.length) {
        const msg = "Invalid proposal"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.proposalsStore.state = failure(msg)
        return
      }

      const proposals = [
        updatedProposal,
        ...prevProposals.filter((p) => p._id !== updatedProposal._id),
      ]

      this.proposalsStore.state = success({
        proposals,
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to update proposal"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.proposalsStore.state = failure(msg)
    }
  }

  public async archive(proposal: Proposal) {
    const name = "[ProposalService::archive]"
    let matches: string[] = []

    const { proposals: prevProposals } = withSuccess(
      this.proposalsStore.state,
      { proposals: [], updatedAt: 0 },
      (data) => data,
    )

    if (proposal.state === State.MATCHED) {
      matches = proposal.matches
    }

    try {
      this.proposalsStore.state = loading

      const { errors, data } = await this.httpService.delete(
        `/api/proposals/${proposal._id}`,
        HttpDelete,
      )

      if (errors.length || !data.ok) {
        const msg = "Failed to archive proposal"
        Logger.error(`${name} ${msg}`)
        Logger.error(errors)
        this.proposalsStore.state = failure(msg)
        return
      }

      const proposals = prevProposals.filter((p) => p._id !== proposal._id)

      this.proposalsStore.state = success({
        proposals,
        updatedAt: Date.now(),
      })

      if (matches.length) {
        this.fetchMany(matches)
      }
    } catch (error) {
      const msg = "Failed to archive proposal"
      Logger.error(`${name} ${msg}`)
      Logger.error(error)
      this.proposalsStore.state = failure(msg)
    }
  }
}
