import { Box } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { InputField } from "~/inputs/views/input-field.view"
import { LabelsField } from "~/labels/views/labels-field.view"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { YearField } from "./proposal.year-field"

export function BiogasFields() {
  const { control } = useContext(ProposalFormContext)

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <InputField />
        <LabelsField />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Controller
          control={control}
          name="product.productionYear.from"
          render={({ fieldState, field: { value, onChange } }) => {
            return (
              <Box sx={{ width: "50%" }}>
                <YearField
                  error={fieldState.error?.message}
                  label="Production year"
                  value={value}
                  onChange={onChange}
                />
              </Box>
            )
          }}
        />
        <Box sx={{ width: "50%" }} />
      </Box>
    </>
  )
}
