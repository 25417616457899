import type { HttpService } from "~/http/http.service"
import {
  failure,
  loading,
  success,
  withSuccess,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import type { Attachment } from "./attachment.model"
import { AttachmentsDto } from "./dtos/attachments.dto"
import { FileDto } from "./dtos/file.dto"
import type { FilesStore } from "./files.store"

export class FilesService {
  private readonly name = "FilesService"

  constructor(
    private readonly httpService: HttpService,
    private readonly filesStore: FilesStore,
  ) {}

  public async uploadFiles(files: File[]) {
    const id = `[${this.name}::uploadFiles]`
    const prev = withSuccess(
      this.filesStore.state,
      [],
      ({ attachments }) => attachments,
    )

    try {
      this.filesStore.state = loading

      const formData = new FormData()
      for (const file of files) {
        // See https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
        // @ts-expect-error According to MDN, FormData#append can accept a File.
        formData.append("files", file, file.names)
      }

      const [{ errors, data: attachments }] = await Promise.all([
        this.httpService.post("/api/files/upload", formData, AttachmentsDto),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ])

      if (errors.length) {
        const msg = "Failed to upload files"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.filesStore.state = failure(msg)
        return
      }

      this.filesStore.state = success({
        attachments: prev.concat(attachments),
      })
    } catch (error) {
      const msg = "Failed to upload files"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.filesStore.state = failure(msg)
    }
  }

  public async downloadFile(fileId: string) {
    const id = `[${this.name}::downloadFile]`

    const attachments = withSuccess(
      this.filesStore.state,
      [],
      ({ attachments }) => attachments,
    )

    try {
      this.filesStore.state = loading

      const { errors, data } = await this.httpService.get(
        `/api/files/download?fileId=${encodeURIComponent(fileId)}`,
        FileDto,
        { encoding: "blob" },
      )

      if (errors.length) {
        const msg = "Failed to download file"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.filesStore.state = failure(msg)
        return
      }

      this.filesStore.state = success({ attachments })

      return data
    } catch (error) {
      const msg = "Failed to download file"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.filesStore.state = failure(msg)
    }
  }

  public setFiles(attachments: Attachment[]) {
    this.filesStore.state = success({ attachments })
  }

  public clearFiles() {
    this.filesStore.state = success({ attachments: [] })
  }
}
