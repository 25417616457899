import {
  Tooltip as MuiTooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material"

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[10],
  },
}))
