export class UserConfig {
  private readonly _graphMeEndpoint: string
  private readonly _avatarEndpoint: string
  private readonly _userEndpoint: string

  constructor() {
    this._graphMeEndpoint = "https://graph.microsoft.com/v1.0/me"
    this._avatarEndpoint = `${this._graphMeEndpoint}/photo/$value`
    this._userEndpoint = "/api/user"
  }

  get graphMeEndpoint() {
    return this._graphMeEndpoint
  }

  get avatarEndpoint() {
    return this._avatarEndpoint
  }

  get userEndpoint() {
    return this._userEndpoint
  }
}
