import CheckIcon from "@mui/icons-material/Check"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import NotificationsIcon from "@mui/icons-material/Notifications"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { format } from "~/utils/utils.string"
import type { Notification } from "../notification.model"
import { useNotificationsStore } from "../notifications.use-notification-store"

export function NotificationMenu() {
  const {
    state: { notifications },
    actions: {
      clearNotification,
      clearNotifications,
      fetchNotifications,
      markAsRead,
    },
  } = useNotificationsStore()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const badgeCount = notifications.filter((n) => !n.isRead).length

  useEffect(() => {
    fetchNotifications()
  }, [])

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAction = useCallback(
    (notification: Notification) => {
      markAsRead(notification._id)
      setAnchorEl(null)
      navigate(
        `/proposals/${format(notification.data.kind, { case: "lower", form: "plural" })}/${notification.data._id}`,
      )
    },
    [markAsRead],
  )

  const handleClear = useCallback(
    (id: string) => {
      clearNotification(id)
    },
    [clearNotification],
  )

  const handleClearAll = useCallback(() => {
    clearNotifications()
    setAnchorEl(null)
  }, [])

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <Badge badgeContent={badgeCount} color="primary">
          <NotificationsIcon
            sx={(theme) => ({
              cursor: "pointer",
              color: theme.palette.common.white,
            })}
          />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={isOpen}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            backgroundColor: "primary.main",
            color: "secondary.main",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.5rem 1rem",
            minWidth: "20rem",
            minHeight: "3.5rem",
          }}
        >
          <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
            Notifications
          </Typography>

          {notifications.length > 0 && (
            <Tooltip title="Clear all">
              <Box>
                <IconButton
                  onClick={() => {
                    handleClearAll()
                  }}
                >
                  <DoneAllIcon sx={{ color: "secondary.main" }} />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>
        <Divider />
        {notifications.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem",
            }}
          >
            <TaskAltIcon sx={{ fontSize: "2.5rem", color: "grey.500" }} />
            <Typography
              sx={(theme) => ({
                fontSize: "0.9rem",
                paddingY: "0.5rem",
                paddingX: "0.25rem",
                color: theme.palette.grey[600],
              })}
            >
              Nothing to see here!
            </Typography>
          </Box>
        )}
        {notifications.map((notification) => {
          return (
            <Box key={notification._id}>
              <Box
                sx={{
                  padding: "1rem",
                  opacity: notification.isRead ? 0.5 : 1,
                }}
              >
                <Typography
                  sx={{
                    paddingTop: 0,
                    paddingBottom: "0.25rem",
                    paddingX: "0.25rem",
                    fontSize: "1.10rem",
                    fontWeight: "bold",
                  }}
                >
                  {notification.title}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    paddingY: "0.5rem",
                    paddingX: "0.25rem",
                    color: theme.palette.grey[600],
                  })}
                >
                  {notification.message}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 0,
                  }}
                >
                  <Button
                    onClick={() => handleAction(notification)}
                    size="small"
                  >
                    View Details
                  </Button>
                  <Tooltip title="Clear">
                    <IconButton onClick={() => handleClear(notification._id)}>
                      <CheckIcon sx={{ color: "primary", fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Divider />
            </Box>
          )
        })}
      </Popover>
    </>
  )
}
