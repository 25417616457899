import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/de"
import "dayjs/locale/en"
import "dayjs/locale/en-gb"
import "dayjs/locale/es"
import "dayjs/locale/fr"
import "dayjs/locale/it"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { useEffect } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useAppContext } from "./app.use-app-context.hook"
import { Login } from "./auth/auth.login.view"
import { ProtectedRoutes } from "./auth/auth.protected-routes.component"
import { useLocaleStore } from "./locale/locale.use-locale-store.hook"
import { ProposalKind } from "./proposals/models/proposal.model"
import { Proposals } from "./proposals/views/grid-views/proposals.view"
import { Drawer } from "./ui/components/ui.drawer.component"
import { AppShell } from "./ui/ui.app-shell.layout"

dayjs.extend(utc)
dayjs.extend(timezone)

export default function App() {
  const { navigationModule } = useAppContext()
  const { state: locale } = useLocaleStore()
  const navigate = useNavigate()

  useEffect(() => {
    navigationModule.service.navigateFunction = navigate
  }, [navigate])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={locale.region.adapterLocale}
    >
      <AppShell>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/proposals/requests/:proposalId?"
              element={<Proposals proposalKind={ProposalKind.REQUEST} />}
            />
            <Route
              path="/proposals/offers/:proposalId?"
              element={<Proposals proposalKind={ProposalKind.OFFER} />}
            />
            <Route
              path="*"
              element={<Navigate to="/proposals/requests" replace />}
            />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </AppShell>
      <Drawer />
    </LocalizationProvider>
  )
}
