export class CurrenciesConfig {
  private readonly _currenciesApiUrl: string

  constructor() {
    this._currenciesApiUrl = "/api/currencies"
  }

  public get currenciesApiUrl() {
    return this._currenciesApiUrl
  }
}
