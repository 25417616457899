import { nanoid } from "nanoid"
import { debounce } from "~/utils/utils.debounce"

export class SyncStore<State extends Record<string, unknown>> {
  private _state: State
  private readonly _listeners: Record<string, (state: State) => void> = {}
  private _cancelNotify = () => {}

  constructor(state: State) {
    this._state = state
  }

  public get state(): State {
    return this._state
  }

  public set state(partial: Partial<State>) {
    this._state = { ...this._state, ...partial }
    this.notifyListeners()
  }

  public addListener(listener: (state: State) => void) {
    const key = nanoid()
    this._listeners[key] = listener
  }

  public removeListener(key: string) {
    delete this._listeners[key]
  }

  private notifyListeners() {
    this._cancelNotify()

    const [debouncedFn, cancelFn] = debounce(() => {
      return this.debouncedNotifyListeners.call(this)
    }, 10)

    this._cancelNotify = cancelFn
    return debouncedFn()
  }

  private debouncedNotifyListeners() {
    for (const listener of Object.values(this._listeners)) {
      listener(this._state)
    }
  }
}
