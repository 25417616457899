import { ProductKind } from "~/proposals/models/product.model"
import { PriceRequestKind } from "~/proposals/price-requests/price-request.model"
import { SyncStore } from "~/store/sync-store"
import { State } from "./ui.models"

export class UiStore extends SyncStore<State> {
  constructor() {
    super({
      drawer: { open: false },
      alert: null,
      footerContent: null,
      menuItems: {},
      quickFilters: {
        priceRequestKinds: [
          PriceRequestKind.FIRM,
          PriceRequestKind.RFQ,
          PriceRequestKind.INDICATIVE,
          PriceRequestKind.ONGOING,
        ],
        productKind: ProductKind.GUARANTEE_OF_ORIGIN,
      },
    })
  }
}
