import ClockIcon from "@mui/icons-material/AccessTime"
import { Box, Divider, Typography } from "@mui/material"
import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useLocaleStore } from "~/locale/locale.use-locale-store.hook"
import {
  currencyFormatter,
  dateFormatter,
  numberFormatter,
} from "~/locale/locale.utils"
import { ProductKind } from "~/proposals/models/product.model"
import {
  State,
  type MatchedProposal,
  type Proposal,
} from "~/proposals/models/proposal.model"
import { useProposalsStore } from "~/proposals/proposals.use-proposals-store.hook"
import { useUiStore } from "~/ui/ui.use-ui-store.hook"
import { Avatar } from "~/user/user.avatar.component"
import { format } from "~/utils/utils.string"
import { MatchCount } from "../grid-views/proposal.match-count.view"

type Props = {
  proposal: Proposal
}

export function Matches({ proposal }: Props) {
  const {
    state: { proposals },
    actions: { fetchManyProposals },
  } = useProposalsStore()
  const navigate = useNavigate()
  const {
    actions: { closeDrawer },
  } = useUiStore()
  const { state: locale } = useLocaleStore()
  const formatDate = dateFormatter(locale, {
    dateStyle: "medium",
    timeStyle: "short",
  })
  const formatNumber = numberFormatter(locale)

  const handleRowClick = useCallback(
    (proposal: Proposal) => {
      navigate(
        `/proposals/${format(proposal.kind, { case: "lower", form: "plural" })}/${proposal._id}`,
      )
      setTimeout(closeDrawer, 500)
    },
    [closeDrawer],
  )

  useEffect(() => {
    if (proposal.state === State.MATCHED) {
      fetchManyProposals(proposal.matches)
    }
  }, [proposal, fetchManyProposals])

  if (proposal.state !== State.MATCHED) {
    return (
      <Box width="100%">
        <Typography variant="h6">Matches</Typography>
        <Typography>No matches</Typography>
      </Box>
    )
  }

  const matches = proposals.filter(({ _id }) => {
    return proposal.matches.includes(_id)
  }) as MatchedProposal[]

  return (
    <Box width="100%">
      <Box display="flex" gap={1} alignItems="center" mb={1}>
        <Typography variant="h6">Matches</Typography>
        <MatchCount count={matches.length} size="small" />
      </Box>
      {matches.map((match) => {
        const formatCurrency = currencyFormatter(
          locale,
          match.priceRequest.currency?.code ?? "EUR",
        )

        return (
          <Box
            key={match._id}
            sx={(theme) => ({
              border: `1px solid ${theme.palette.grey[300]}`,
              mb: "1rem",
              p: "1rem",
              "&:hover": {
                backgroundColor: theme.palette.grey[100],
                cursor: "pointer",
              },
            })}
            display="flex"
            flexDirection="column"
            gap={1}
            onClick={() => handleRowClick(match)}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <Typography variant="h6">
                  {formatNumber(match.volume)}{" "}
                  {match.product.kind === ProductKind.CARBON ? "Tons" : "MWh"}{" "}
                  at{" "}
                  {match.priceRequest.price ? (
                    <>
                      {formatCurrency(match.priceRequest.price)}
                      {match.product.kind === ProductKind.CARBON
                        ? " per Ton"
                        : " per MWh"}
                    </>
                  ) : (
                    "best price"
                  )}
                </Typography>
              </Box>
              <Typography variant="subtitle2" color="textSecondary">
                Last modified at {formatDate(match.updatedAt)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1.5}>
              <Typography variant="subtitle2" color="textSecondary">
                {match.go2Id}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={(theme) => ({
                  borderColor: theme.palette.grey[400],
                  my: "0.5rem",
                })}
              />
              <Typography variant="subtitle2" color="textSecondary">
                {match.company.properties.name}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={(theme) => ({
                  borderColor: theme.palette.grey[400],
                  my: "0.5rem",
                })}
              />
              <Typography
                display="flex"
                variant="subtitle2"
                color="textSecondary"
                alignItems="center"
                gap={0.5}
              >
                <ClockIcon sx={{ fontSize: "1.25rem" }} />
                {match.priceRequest.deadline
                  ? formatDate(match.priceRequest.deadline)
                  : "Ongoing"}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={(theme) => ({
                  borderColor: theme.palette.grey[400],
                  my: "0.5rem",
                })}
              />
              <Box display="flex" gap={0.5} alignItems="center">
                {match.createdBy.avatar && <Avatar user={match.createdBy} />}
                <Typography variant="subtitle2" color="textSecondary">
                  {match.createdBy.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
