import {
  DataGridPro,
  useGridApiRef,
  type DataGridProProps,
  type GridApiPro,
  type GridColDef,
  type GridColumnVisibilityModel,
} from "@mui/x-data-grid-pro"
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro"
import { useEffect, useState } from "react"
import { debounce } from "~/utils/utils.debounce"

type Props<T extends { _id: string }> = {
  // Required
  columns: GridColDef[]
  rows: T[]

  // Optional
  autoPageSize?: boolean
  initialState?: GridInitialStatePro
  gridRef?: React.MutableRefObject<GridApiPro>
} & DataGridProProps<T>

export function DataGrid<T extends { _id: string }>({
  columns,
  columnVisibilityModel: incomingColumnVisibilityModel,
  rows,
  initialState,
  ...props
}: Props<T>) {
  const ref = useGridApiRef()
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    incomingColumnVisibilityModel,
  )
  const [debouncedResize, cancelResize] = debounce(() => {
    ref.current?.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
      expand: true,
    })
  }, 500)

  const handleColumnVisibilityModelChange = (
    newColumnVisibilityModel: GridColumnVisibilityModel,
  ) => {
    setColumnVisibilityModel(newColumnVisibilityModel)
  }

  useEffect(() => {
    setColumnVisibilityModel(incomingColumnVisibilityModel)
  }, [incomingColumnVisibilityModel])

  useEffect(() => {
    window.addEventListener("resize", () => {
      cancelResize()
      debouncedResize()
    })

    return () => {
      window.removeEventListener("resize", () => {
        cancelResize()
      })
    }
  }, [ref])

  useEffect(() => {
    ref.current?.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
      expand: true,
    })
  }, [columns, columnVisibilityModel])

  return (
    <DataGridPro
      {...props}
      apiRef={ref}
      autosizeOnMount
      autosizeOptions={{
        expand: true,
      }}
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      disableRowSelectionOnClick
      getRowHeight={() => "auto"}
      initialState={initialState}
      // onRowClick={({ row: { id } }: GridRowParams<T & { id: string }>) => {
      //   Logger.info(id)
      // }}
      pagination
      pageSizeOptions={[100, 500, { value: 1000, label: "1,000" }]}
      rows={rows.map((row) => ({ id: row._id, ...row }))} // Required by DataGridPro
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell": { cursor: "pointer" },
        "&.MuiDataGrid-root .MuiDataGrid-cell:first-of-type": { pl: "1.5rem" },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": { outline: "none" },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:first-of-type": {
          pl: "1.5rem",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "0.5rem",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "1rem" },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "1.5rem",
        },
      }}
    />
  )
}
