import { IsEmail, IsNotEmpty, IsString } from "class-validator"
import { IsNullable } from "~/utils/utils.validation"

export type CreateUserDto = Omit<User, "_id" | "entraId">
export type UpdateUserDto = Partial<CreateUserDto>

export class User {
  @IsString()
  @IsNotEmpty()
  _id: string

  @IsString()
  @IsNotEmpty()
  entraId: string

  @IsString()
  @IsNotEmpty()
  name: string

  @IsEmail()
  @IsNotEmpty()
  email: string

  @IsString()
  @IsNullable()
  avatar: string | null

  @IsString()
  @IsNullable()
  hubSpotId: string | null
}

export class GraphProfile {
  @IsString()
  @IsNotEmpty()
  id: string

  @IsString()
  @IsNotEmpty()
  displayName: string

  @IsEmail()
  @IsNotEmpty()
  mail: string
}
