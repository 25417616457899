import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Input } from "./input.model"

type Err = string
type Ok = {
  inputs: Input[]
}

export class InputsStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("InputsStore", initial)
  }
}
