import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import type { CurrenciesConfig } from "./currencies.config"
import type { CurrenciesStore } from "./currencies.store"
import { CurrenciesDto } from "./dtos/currencies.dto"

export class CurrenciesService {
  private readonly name = "CurrenciesService"

  constructor(
    private readonly httpService: HttpService,
    private readonly currenciesConfig: CurrenciesConfig,
    private readonly currenciesStore: CurrenciesStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.currenciesStore.state)) {
      return Logger.info(
        `${id} Currencies are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.currenciesStore.state)) {
      return Logger.info(`${id} Currencies found in cache, not fetching again.`)
    }

    try {
      this.currenciesStore.state = loading

      const { errors, data: currencies } = await this.httpService.get(
        this.currenciesConfig.currenciesApiUrl,
        CurrenciesDto,
      )

      if (errors.length) {
        const msg = "Invalid currencies"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.currenciesStore.state = failure(msg)
        return
      }

      this.currenciesStore.state = success({ currencies })
    } catch (error) {
      const msg = "Failed to fetch currencies"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.currenciesStore.state = failure(msg)
    }
  }
}
