import { Drawer as MuiDrawer } from "@mui/material"
import { useUiStore } from "../ui.use-ui-store.hook"

export function Drawer() {
  const {
    state: { drawer },
  } = useUiStore()

  return (
    <MuiDrawer
      anchor="right"
      open={drawer.open}
      sx={{
        "& .MuiDrawer-paper": {
          width: "60%",
          height: "100%",
        },
      }}
    >
      {drawer.open ? drawer.content : null}
    </MuiDrawer>
  )
}
