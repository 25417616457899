import { TextField } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "../../proposals.context"

export function ProjectIdField() {
  const { control } = useContext(ProposalFormContext)

  return (
    <Controller
      control={control}
      name="product.projectId"
      render={({ fieldState: { error }, field: { name, onChange } }) => {
        return (
          <TextField
            error={Boolean(error)}
            helperText={error?.message}
            label="Project Id"
            name={name}
            size="small"
            onChange={onChange}
            sx={{ width: "50%" }}
          />
        )
      }}
    />
  )
}
