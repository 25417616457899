import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { User } from "./user.model"

type Err = string
type Ok = { user: User }

export class UserStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("UserStore", initial)
  }
}
