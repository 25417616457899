import { Type } from "class-transformer"
import { IsObject, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Notification } from "../notification.model"

export class NotificationDto implements HttpDto<Notification> {
  @ValidateNested()
  @IsObject()
  @Type(() => Notification)
  declare readonly data: Notification
}
