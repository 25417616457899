import { useLocaleStore } from "~/locale/locale.use-locale-store.hook"
import { currencyFormatter } from "~/locale/locale.utils"

type Props = {
  currencyCode: string
  price: number
}

export function Price({ currencyCode, price }: Props) {
  const { state: locale } = useLocaleStore()
  const formatCurrency = currencyFormatter(locale, currencyCode)

  return formatCurrency(price)
}
