import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"

type Err = string
type Ok = {
  featureFlags: Record<string, boolean>
  updatedAt: number
}

export class FeatureFlagsStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("FeatureFlagsStore", initial)
  }
}
