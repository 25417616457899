import { Type } from "class-transformer"
import { ArrayNotEmpty, IsArray, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Instrument } from "../instrument.model"

export class InstrumentsDto implements HttpDto<Instrument[]> {
  @Type(() => Instrument)
  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  declare readonly data: Instrument[]
}
