export class AuthConfig {
  private readonly clientId: string
  private readonly authority: string
  private readonly redirectUri: string
  private readonly postLogoutRedirectUri: string
  private readonly clientCapabilities: string[]
  private readonly scopes: { read: string[]; write: string[] }

  constructor() {
    this.clientId = "01df0659-b70f-46b8-8ed7-f99373deb4f4"
    this.authority =
      "https://login.microsoftonline.com/caf418f6-d24f-440c-9f0c-c7427fdd85bc"
    this.redirectUri = "/proposals/requests"
    this.postLogoutRedirectUri = "/login"
    this.clientCapabilities = ["CP1"]
    this.scopes = {
      read: ["api://f01b02bc-37cf-4116-a9fd-0a797490fd96/Proposals.Read"],
      write: ["api://f01b02bc-37cf-4116-a9fd-0a797490fd96/Proposals.ReadWrite"],
    }
  }

  public get msalConfig() {
    return {
      auth: {
        clientId: this.clientId,
        authority: this.authority,
        redirectUri: this.redirectUri,
        postLogoutRedirectUri: this.postLogoutRedirectUri,
        clientCapabilities: this.clientCapabilities,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    }
  }

  public get protectedResources() {
    return {
      apiProposals: {
        endpoint: "/api/proposals",
        scopes: this.scopes,
      },
    }
  }

  public get loginRequest() {
    return {
      scopes: ["User.Read", ...this.scopes.read, ...this.scopes.write],
      redirectUri: this.redirectUri,
    }
  }

  public get graphRequest() {
    return {
      scopes: ["User.Read"],
    }
  }

  public get apiRequest() {
    return {
      scopes: [...this.scopes.read, ...this.scopes.write],
    }
  }
}
