import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Currency } from "./currency.model"

type Err = string
type Ok = {
  currencies: Currency[]
}

export class CurrenciesStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("CurrenciesStore", initial)
  }
}
