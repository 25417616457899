import { AlertProps } from "./components/ui.alert.component"
import type { ActionItem, QuickFilters } from "./ui.models"
import { UiStore } from "./ui.store"

export class UiService {
  constructor(private readonly uiStore: UiStore) {}

  public openDrawer(content: React.ReactNode) {
    this.uiStore.state = { drawer: { open: true, content } }
  }

  public closeDrawer() {
    this.uiStore.state = { drawer: { open: false } }
  }

  public triggerAlert(alert: AlertProps) {
    this.uiStore.state = { alert }
  }

  public clearAlert() {
    this.uiStore.state = { alert: null }
  }

  public setFooterContent(footerContent: React.ReactNode) {
    this.uiStore.state = { footerContent }
  }

  public setQuickFilters(quickFilters: QuickFilters) {
    this.uiStore.state = { quickFilters }
    localStorage.setItem("quickFilters", JSON.stringify(quickFilters))
  }

  public setMenuItems(items: Record<string, ActionItem[]>) {
    this.uiStore.state = {
      ...this.uiStore.state,
      menuItems: { ...this.uiStore.state.menuItems, ...items },
    }
  }

  public addMenuItem(id: string, item: ActionItem) {
    this.uiStore.state = {
      ...this.uiStore.state,
      menuItems: {
        ...this.uiStore.state.menuItems,
        [id]: [...(this.uiStore.state.menuItems[id] ?? []), item],
      },
    }
  }

  public removeMenuItem(id: string, item: ActionItem) {
    this.uiStore.state = {
      ...this.uiStore.state,
      menuItems: {
        ...this.uiStore.state.menuItems,
        [id]: (this.uiStore.state.menuItems[id] ?? []).filter(
          (menuItem) => menuItem.label !== item.label,
        ),
      },
    }
  }

  public clearMenuItems() {
    this.uiStore.state = { menuItems: {} }
  }

  public hydrateQuickFilters() {
    const data = localStorage.getItem("quickFilters")

    if (data) {
      const quickFilters = JSON.parse(data) as QuickFilters
      this.uiStore.state = { quickFilters }
    }
  }
}
