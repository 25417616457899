export class InstrumentsConfig {
  private readonly _apiUrl: string

  constructor() {
    this._apiUrl = "/api/instruments"
  }

  public get apiUrl() {
    return this._apiUrl
  }
}
