import { IsNotEmpty, IsString } from "class-validator"

export class Input {
  @IsNotEmpty()
  @IsString()
  _id: string

  @IsNotEmpty()
  @IsString()
  name: string
}
