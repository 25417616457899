import type { GridColDef } from "@mui/x-data-grid-pro"
import type {
  InitialProposal,
  Proposal,
} from "~/proposals/models/proposal.model"
import { CountriesDataGrid } from "./views/countries.data-grid.view"

export const getCountriesGridColDef = <
  T extends Proposal = InitialProposal,
>(): GridColDef<T> => ({
  field: "countries",
  headerName: "Countries",
  type: "string",
  sortable: true,
  valueGetter: ({ row: { countries } }) => {
    return countries
      .map(({ acronym, name }) => (acronym ? `${name} (${acronym})` : name))
      .sort()
      .join(", ")
  },
  valueFormatter: ({ value }) => {
    return value
  },
  renderCell: ({ row: { _id, countries } }) => (
    <CountriesDataGrid _id={_id} countries={countries} />
  ),
})
