import { HttpModule } from "~/http/http.module"
import { InputsConfig } from "./inputs.config"
import { InputsService } from "./inputs.service"
import { InputsStore } from "./inputs.store"

export class InputsModule {
  private readonly _inputsConfig: InputsConfig
  private readonly _inputsStore: InputsStore
  private readonly _inputsService: InputsService

  constructor(private readonly httpModule: HttpModule) {
    this._inputsConfig = new InputsConfig()
    this._inputsStore = new InputsStore()
    this._inputsService = new InputsService(
      this.httpModule.service,
      this._inputsConfig,
      this._inputsStore,
    )
  }

  public get store() {
    return this._inputsStore
  }

  public get service() {
    return this._inputsService
  }
}
