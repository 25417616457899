import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Company } from "./company.model"

type Err = string
type Ok = { companies: Company[] }

export class CompaniesStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("CompaniesStore", initial)
  }
}
