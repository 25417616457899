import { HttpModule } from "~/http/http.module"
import { NotificationsConfig } from "./notifications.config"
import { NotificationsService } from "./notifications.service"
import { NotificationStore } from "./notifications.store"

export class NotificationsModule {
  private readonly _config: NotificationsConfig
  private readonly _store: NotificationStore
  private readonly _service: NotificationsService

  constructor(private readonly httpModule: HttpModule) {
    this._config = new NotificationsConfig()
    this._store = new NotificationStore()
    this._service = new NotificationsService(
      this.httpModule.service,
      this._config,
      this._store,
    )
  }

  get store() {
    return this._store
  }

  get service() {
    return this._service
  }
}
