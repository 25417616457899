import { DatePicker } from "@mui/x-date-pickers-pro"
import dayjs from "dayjs"

type Props = {
  error: string | undefined
  label: string
  value: Date | null
  onChange: (value: Date | null) => void
}

export function YearField({ error, label, value, onChange }: Props) {
  return (
    <DatePicker
      label={label}
      views={["year"]}
      value={value ? dayjs(value) : null}
      minDate={dayjs("2000-01-01")}
      maxDate={dayjs().add(15, "year")}
      onChange={(date) => {
        onChange(date?.set("month", 5).set("date", 1).toDate() ?? null)
      }}
      slotProps={{
        textField: {
          fullWidth: true,
          error: Boolean(error),
          helperText: error,
          size: "small",
          required: true,
        },
      }}
    />
  )
}
