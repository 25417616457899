import { IsNotEmpty, IsNumber, IsString } from "class-validator"

export class Sdg {
  @IsNotEmpty()
  @IsString()
  readonly _id: string

  @IsNotEmpty()
  @IsString()
  readonly name: string

  @IsNumber()
  readonly goalNumber: number

  @IsNotEmpty()
  @IsString()
  readonly color: string
}
