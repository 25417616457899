import { io, type Socket } from "socket.io-client"
import { HttpDelete } from "~/http/http.models"
import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
  withSuccess,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { NotificationDto } from "./dtos/notification.dto"
import { NotificationsDto } from "./dtos/notifications.dto"
import { Notification } from "./notification.model"
import type { NotificationsConfig } from "./notifications.config"
import type { NotificationStore } from "./notifications.store"

export class NotificationsService {
  private readonly name = "NotificationsService"
  private socket: Socket

  constructor(
    private readonly httpService: HttpService,
    private readonly notificationsConfig: NotificationsConfig,
    private readonly notificationStore: NotificationStore,
  ) {
    this.connect()
  }

  public connect() {
    this.socket = io(this.notificationsConfig.url, {
      transports: ["websocket"],
      secure: import.meta.env.PROD === true,
    })
    this.socket.on("notifications", this.handleNewNotifications.bind(this))
    Logger.info(`${this.name} connected to socket`)
  }

  public disconnect() {
    this.socket.disconnect()
    Logger.info(`${this.name} disconnected from socket`)
  }

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.notificationStore.state)) {
      return Logger.info(
        `${id} notifications are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.notificationStore.state)) {
      return Logger.info(
        `${id} notifications found in cache, not fetching again.`,
      )
    }

    try {
      this.notificationStore.state = loading

      const { errors, data: notifications } = await this.httpService.get(
        "/api/notifications",
        NotificationsDto,
      )

      if (errors.length) {
        const msg = "Invalid notifications"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.notificationStore.state = failure(msg)
        return
      }

      this.notificationStore.state = success({
        notifications,
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to fetch notifications"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.notificationStore.state = failure(msg)
    }
  }

  public handleNewNotifications(notifications: Notification[]) {
    const prevNotifications = withSuccess(
      this.notificationStore.state,
      [],
      ({ notifications }) => notifications,
    )

    this.notificationStore.state = success({
      notifications: [...prevNotifications, ...notifications],
      updatedAt: Date.now(),
    })
  }

  public async markAsRead(id: string) {
    const existingNotifications = withSuccess(
      this.notificationStore.state,
      [],
      ({ notifications }) => notifications,
    )

    try {
      const { data: updatedNotification, errors } = await this.httpService.put(
        `/api/notifications/${id}`,
        { isRead: true },
        NotificationDto,
      )

      if (errors && errors.length) {
        const msg = "Failed to mark notification as read"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.notificationStore.state = failure(msg)
        return
      }

      this.notificationStore.state = success({
        notifications: existingNotifications.map((existingNotification) => {
          if (id === existingNotification._id) {
            return updatedNotification
          }

          return existingNotification
        }),
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to mark notification as read"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.notificationStore.state = failure(msg)
    }
  }

  public async deleteAll() {
    try {
      const { errors } = await this.httpService.delete(
        `/api/notifications`,
        HttpDelete,
      )
      if (errors && errors.length) {
        const msg = "Failed to mark notification as read"
        Logger.error(`${msg}`)
        Logger.error(errors)
        this.notificationStore.state = failure(msg)
        return
      }
      this.notificationStore.state = success({
        notifications: [],
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to mark notification as read"
      Logger.error(`${msg}`)
      Logger.error(error)
      this.notificationStore.state = failure(msg)
    }
  }

  public async delete(notificationId: string) {
    const prevNotifications = withSuccess(
      this.notificationStore.state,
      [],
      ({ notifications }) => notifications,
    )
    try {
      const { errors } = await this.httpService.delete(
        `/api/notifications/${notificationId}`,
        HttpDelete,
      )
      if (errors && errors.length) {
        const msg = "Failed to delete notification"
        Logger.error(`${notificationId} ${msg}`)
        Logger.error(errors)
        this.notificationStore.state = failure(msg)
        return
      }
      this.notificationStore.state = success({
        notifications: prevNotifications.filter(
          (notification) => notification._id !== notificationId,
        ),
        updatedAt: Date.now(),
      })
    } catch (error) {
      const msg = "Failed to mark notification as read"
      Logger.error(`${notificationId} ${msg}`)
      Logger.error(error)
      this.notificationStore.state = failure(msg)
    }
  }
}
