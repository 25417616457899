export class NotificationsConfig {
  private readonly _url: string

  constructor() {
    this._url = import.meta.env.VITE_SERVER_ADDRESS
  }

  public get url(): string {
    return this._url
  }
}
