import type { Color, PaletteColorOptions } from "@mui/material"

declare module "@mui/material/styles" {
  interface PaletteOptions {
    green?: PaletteColorOptions
    red?: PaletteColorOptions
    yellow?: PaletteColorOptions
    blue?: PaletteColorOptions
    purple?: PaletteColorOptions
    orange?: PaletteColorOptions
  }

  interface Palette {
    green: Color
    grey: Color
    red: Color
    yellow: Color
    blue: Color
    purple: Color
    orange: Color
  }
}

const green = {
  50: "#EDF2F0",
  100: "#D5DED9",
  200: "#BAC9C1",
  300: "#9EB2A8",
  400: "#88A094",
  500: "#738F81",
  600: "#667F72",
  700: "#556A60",
  800: "#45564E",
  900: "#33403A",
}

const red = {
  50: "#FFEBEF",
  100: "#FFCDD3",
  200: "#FD9A99",
  300: "#F87171",
  400: "#FF4D4B",
  500: "#FF3B2E",
  600: "#FA302F",
  700: "#E72329",
  800: "#DA1B22",
  900: "#CA0714",
}

const yellow = {
  50: "#FDF6E2",
  100: "#FBE8B6",
  200: "#F8D988",
  300: "#F5CC5C",
  400: "#F3C040",
  500: "#F1B62E",
  600: "#F0A92A",
  700: "#EE9826",
  800: "#ED8823",
  900: "#EB6B1C",
}

const blue = {
  50: "#E8F3F9",
  100: "#C6E2F2",
  200: "#A7D0EA",
  300: "#8BBDE0",
  400: "#78AFDA",
  500: "#6BA2D4",
  600: "#6194C7",
  700: "#5682B4",
  800: "#4D72A2",
  900: "#3E5581",
}

const purple = {
  50: "#F5F0F9",
  100: "#EAD6F3",
  200: "#E0B9EC",
  300: "#D59BE5",
  400: "#CC83E0",
  500: "#C46BDB",
  600: "#BD5DCD",
  700: "#B14DB9",
  800: "#A43DA5",
  900: "#8D1E80",
}

const orange = {
  50: "#FFF4E5",
  100: "#FFE2B9",
  200: "#FFCF8B",
  300: "#FFBB5F",
  400: "#FFA842",
  500: "#FF9A30",
  600: "#FF8D2B",
  700: "#FF7C25",
  800: "#FF6B1F",
  900: "#FF4F16",
}

const grey = {
  50: "#FAFAFA",
  100: "#F6F6F6",
  200: "#F0F0F0",
  300: "#E2E2E2",
  400: "#C0C0C0",
  500: "#A1A1A1",
  600: "#787878",
  700: "#636363",
  800: "#444444",
  900: "#232323",
}

const neutral = {
  50: "#EEEDEC",
  100: "#D8CFCF",
  200: "#BFAFAF",
  300: "#A78D8E",
  400: "#957475",
  500: "#835B5D",
  600: "#765254",
  700: "#654648",
  800: "#543A3E",
  900: "#442D32",
}

export const colors = {
  green,
  neutral,
  red,
  yellow,
  blue,
  purple,
  orange,
  grey,
  black: "#000000",
  white: "#FFFFFF",
  sdg: {
    1: "#e5233d",
    2: "#dda73a",
    3: "#4ca146",
    4: "#c5192d",
    5: "#ef402c",
    6: "#27bfe6",
    7: "#fbc412",
    8: "#a31c44",
    9: "#f26a2d",
    10: "#e01483",
    11: "#f89d2a",
    12: "#bf8d2c",
    13: "#407f46",
    14: "#1f97d4",
    15: "#59ba48",
    16: "#126a9f",
    17: "#13496b",
  },
}

export const colorsList = [green, red, yellow, blue, purple, orange]
