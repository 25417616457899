import { Type } from "class-transformer"
import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator"
import { Input } from "~/inputs/input.model"
import { Instrument } from "~/instruments/instrument.model"
import { Label } from "~/labels/label.model"
import { Registry } from "~/registries/registry.model"
import { Sdg } from "~/sdgs/sdg.model"
import { Technology } from "~/technologies/technology.model"
import { IsNullable } from "~/utils/utils.validation"

export enum ProductKind {
  BIOGAS = "BIOGAS",
  CARBON = "CARBON",
  GUARANTEE_OF_ORIGIN = "GUARANTEE_OF_ORIGIN",
  INT_REC = "INT_REC",
  US_REC = "US_REC",
  WHITE_CERTIFICATE = "WHITE_CERTIFICATE",
}

export enum GuaranteeOfOriginSupportType {
  SUPPORT = "SUPPORT",
  NO_SUPPORT = "NO_SUPPORT",
}

/**
 * English label mapping for GO support types.
 *
 * Since GO support type data does not come from an API endpoint like technologies and labels,
 * this map provides the human-readable labels for UI use.
 */
export const guaranteeOfOriginSupportTypeLabels = new Map<
  GuaranteeOfOriginSupportType,
  string
>([
  [GuaranteeOfOriginSupportType.SUPPORT, "Support"],
  [GuaranteeOfOriginSupportType.NO_SUPPORT, "No Support"],
])

export const productKinds = [
  ProductKind.BIOGAS,
  ProductKind.CARBON,
  ProductKind.WHITE_CERTIFICATE,
  ProductKind.GUARANTEE_OF_ORIGIN,
  ProductKind.INT_REC,
  ProductKind.US_REC,
]

export class BaseProduct {
  @IsEnum(ProductKind)
  readonly kind: ProductKind
}

export class BiogasProduct extends BaseProduct {
  declare readonly kind: ProductKind.BIOGAS

  @ValidateNested()
  @Type(() => ProductionYear)
  readonly productionYear: ProductionYear

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Label)
  readonly labels: Label[]

  @IsNotEmpty({ message: "Select an input" })
  @Type(() => Input)
  readonly input: Input
}

export class CarbonProduct extends BaseProduct {
  declare readonly kind: ProductKind.CARBON

  @IsArray()
  @ArrayNotEmpty({ message: "Select at least one instrument" })
  @ValidateNested({ each: true })
  @Type(() => Instrument)
  readonly instruments: Instrument[]

  @IsArray()
  @ArrayNotEmpty({ message: "Select at least one technology" })
  @ValidateNested({ each: true })
  @Type(() => Technology)
  readonly technologies: Technology[]

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Label)
  readonly labels: Label[]

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Sdg)
  readonly sdgs: Sdg[]

  @IsOptional()
  @IsBoolean()
  readonly transferability: boolean

  @ValidateNested()
  @Type(() => Vintage)
  readonly vintage: Vintage

  @IsOptional()
  @IsString()
  readonly projectId: string | undefined
}

export class GuaranteeOfOriginProduct extends BaseProduct {
  declare readonly kind: ProductKind.GUARANTEE_OF_ORIGIN

  @ValidateNested()
  @Type(() => ProductionYear)
  readonly productionYear: ProductionYear

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Label)
  readonly labels: Label[]

  @IsArray()
  @ArrayNotEmpty({ message: "Select at least one technology" })
  @ValidateNested({ each: true })
  @Type(() => Technology)
  readonly technologies: Technology[]

  @IsArray()
  readonly supportTypes: GuaranteeOfOriginSupportType[]
}

export class IntRecProduct extends BaseProduct {
  declare readonly kind: ProductKind.INT_REC

  @ValidateNested()
  @Type(() => ProductionYear)
  readonly productionYear: ProductionYear

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Label)
  readonly labels: Label[]

  @IsArray()
  @ArrayNotEmpty({ message: "Select at least one technology" })
  @ValidateNested({ each: true })
  @Type(() => Technology)
  readonly technologies: Technology[]
}

export class UsRecProduct extends BaseProduct {
  declare readonly kind: ProductKind.US_REC

  @ValidateNested()
  @Type(() => ProductionYear)
  readonly productionYear: ProductionYear

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Label)
  readonly labels: Label[]

  @IsArray()
  @ArrayNotEmpty({ message: "Select at least one technology" })
  @ValidateNested({ each: true })
  @Type(() => Technology)
  readonly technologies: Technology[]

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Registry)
  @ArrayNotEmpty({ message: "Select a registry" })
  readonly registries: Registry[]
}

export class WhiteCertProduct extends BaseProduct {
  declare readonly kind: ProductKind.WHITE_CERTIFICATE

  @ValidateNested()
  @Type(() => ProductionYear)
  readonly productionYear: ProductionYear

  @IsArray()
  @ArrayNotEmpty({ message: "Select at least one instrument" })
  @ValidateNested({ each: true })
  @Type(() => Instrument)
  readonly instruments: Instrument[]

  @IsArray()
  @ArrayNotEmpty({ message: "Select at least one technology" })
  @ValidateNested({ each: true })
  @Type(() => Technology)
  readonly technologies: Technology[]
}

export class Vintage {
  @IsDate({ message: "From must be a valid date" })
  @Type(() => Date)
  readonly from: Date

  @IsDate({ message: "To must be a valid date" })
  @Type(() => Date)
  @IsNullable()
  readonly to: Date | null
}

export class ProductionYear {
  @IsDate({ message: "From must be a valid date" })
  @Type(() => Date)
  readonly from: Date

  @IsDate({ message: "To must be a valid date" })
  @Type(() => Date)
  @IsNullable()
  readonly to: Date | null
}

export type Product =
  | BiogasProduct
  | CarbonProduct
  | GuaranteeOfOriginProduct
  | IntRecProduct
  | UsRecProduct
  | WhiteCertProduct
