import { Box, Alert as MuiAlert } from "@mui/material"
import { useUiStore } from "../ui.use-ui-store.hook"

export type AlertProps = {
  action: React.ReactNode
  message: string
  severity: "error" | "info" | "success" | "warning"
}

export function Alert() {
  const {
    state: { alert },
  } = useUiStore()

  if (!alert) {
    return null
  }

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        zIndex: 1,
      }}
    >
      <MuiAlert severity={alert.severity} action={alert.action}>
        {alert.message}
      </MuiAlert>
    </Box>
  )
}
