import type { HttpService } from "~/http/http.service"
import {
  failure,
  loading,
  success,
  withSuccess,
} from "~/store/async-store.utils"
import { debounce } from "~/utils/utils.debounce"
import { Logger } from "~/utils/utils.logger"
import type { CompaniesConfig } from "./companies.config"
import type { CompaniesStore } from "./companies.store"
import { CompaniesDto } from "./dtos/companies.dto"

export class CompaniesService {
  private cancelSearch?: () => void

  constructor(
    private readonly httpService: HttpService,
    private readonly companiesConfig: CompaniesConfig,
    private readonly companiesStore: CompaniesStore,
  ) {}

  public async search(query: string) {
    if (query.length < 3) return
    if (this.cancelSearch) this.cancelSearch()

    const [search, cancelSearch] = debounce(async () => {
      await this.debouncedSearch(query)
    }, 500)

    this.cancelSearch = cancelSearch
    await search()
  }

  private async debouncedSearch(query: string) {
    const id = "[CompaniesService::search]"
    const prev = withSuccess(
      this.companiesStore.state,
      [],
      ({ companies }) => companies,
    )

    try {
      this.companiesStore.state = loading

      const { errors, data: companies } = await this.httpService.get(
        `${this.companiesConfig.companyApiUrl}?nameOrDomain=${query}`,
        CompaniesDto,
      )

      if (errors.length) {
        const msg = "Invalid companies"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.companiesStore.state = failure(msg)
        return
      }

      this.companiesStore.state = success({
        companies: [
          ...prev.filter(
            ({ id }) => !companies.some((company) => company.id === id),
          ),
          ...companies,
        ],
      })
    } catch (error) {
      const msg = "Failed to search companies."
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.companiesStore.state = failure(msg)
    }
  }
}
