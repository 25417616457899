import { useContext, useEffect } from "react"
import { FilesUpload } from "~/files/files.upload.view"
import { useFilesStore } from "~/files/files.use-files-store.hook"
import { ProposalFormContext } from "../../proposals.context"

export function AttachmentsField() {
  const {
    state: { attachments },
  } = useFilesStore()
  const { register, setValue } = useContext(ProposalFormContext)

  register("attachments")

  useEffect(() => {
    setValue("attachments", attachments)
  }, [attachments])

  return <FilesUpload name="attachments" />
}
