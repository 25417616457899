import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { CompanyField } from "~/companies/company.form-input.view"
import { CountriesField } from "~/countries/views/countries.input-field"
import { useFilesStore } from "~/files/files.use-files-store.hook"
import {
  CreateProposalDto,
  UpdateProposalDto,
} from "~/proposals/dtos/proposal.dto"
import { ProductKind } from "~/proposals/models/product.model"
import {
  ProposalKind,
  State,
  type Proposal,
} from "~/proposals/models/proposal.model"
import { PriceRequestKind } from "~/proposals/price-requests/price-request.model"
import { PriceRequestFields } from "~/proposals/price-requests/views/price-request.create.view"
import { resolver } from "~/proposals/proposal.utils"
import { RegionsField } from "~/regions/views/regions.input-field.view"
import { useUiStore } from "~/ui/ui.use-ui-store.hook"
import { useUserStore } from "~/user/user.use-user.hook"
import { Logger } from "~/utils/utils.logger"
import { format } from "~/utils/utils.string"
import { ProposalFormContext, type FormMode } from "../../proposals.context"
import { useProposalsStore } from "../../proposals.use-proposals-store.hook"
import { AttachmentsField } from "./proposal.attachments-field.view"
import { BiogasFields } from "./proposal.biogas-fields.view"
import { CarbonFields } from "./proposal.carbon-fields.view"
import { GoFields } from "./proposal.go-fields.view"
import { IRecFields } from "./proposal.i-rec-fields.view"
import { NotesField } from "./proposal.notes-field.view"
import { ProductKindField } from "./proposal.product-field.view"
import { UsRecFields } from "./proposal.us-rec-fields.view"
import { WhiteCertFields } from "./proposal.white-cert-fields.view"

type FormProps = {
  kind: ProposalKind
  mode: FormMode
}

export function ProposalForm({ kind, mode }: FormProps) {
  const {
    state: { isLoading: isSubmitting, error: submissionError },
    actions: { createProposal, updateProposal },
  } = useProposalsStore()
  const {
    state: { quickFilters },
  } = useUiStore()
  const defaultValues =
    mode.kind === "create"
      ? getInitialDefaultValues(kind, quickFilters.productKind)
      : mode.proposal
  const {
    control,
    formState: { errors: validationErrors },
    getFieldState,
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<Proposal>({
    // @ts-expect-error initial values will be null
    defaultValues,
    resolver,
  })
  const productKind = watch("product.kind", getValues("product.kind"))

  const {
    state: { user },
  } = useUserStore()
  const {
    actions: { closeDrawer },
  } = useUiStore()
  const {
    actions: { clearFiles },
  } = useFilesStore()

  const [createMore, setCreateMore] = useState(false)

  useEffect(() => {
    if (Object.keys(validationErrors).length > 0) {
      Logger.error(validationErrors)
    }
  }, [validationErrors])

  useEffect(() => {
    if (user) {
      setValue("createdBy", user)
    }
  }, [user])

  const onSubmit: SubmitHandler<Proposal> = useCallback(
    async (data) => {
      console.log("Form onSubmit triggered, using data: ", data)
      try {
        if (mode.kind === "create") {
          console.log("Creating proposal")
          await createProposal(new CreateProposalDto(data))
        }

        if (mode.kind === "update") {
          console.log(`Updating proposal ${data._id}`)
          await updateProposal(data._id, new UpdateProposalDto(data))
        }

        if (mode.kind === "duplicate") {
          console.log("Duplicating proposal")
          await createProposal(new CreateProposalDto(data))
        }

        if (!createMore) {
          console.log("Not creating more proposals, closing drawer")
          closeDrawer()
          clearFiles()
        }
        console.log("End of onSubmit, all was good")
      } catch (error) {
        console.error("Form onSubmit failed!")
        console.error(error)
      }
    },
    [createMore, validationErrors, submissionError, closeDrawer],
  )

  const onSubmitError: SubmitErrorHandler<Proposal> = useCallback(
    async (errors) => {
      console.error("Form onSubmitError triggered!")
      console.error(errors)
    },
    [],
  )

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Toolbar
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <Typography variant="h5" color="white">
          {mode.kind === "update" ? "Update " : "Create "}
          {format(productKind, { case: "title", form: "singular" })}{" "}
          {format(kind, { case: "title", form: "singular" })}
        </Typography>
        <IconButton color="secondary" onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit, onSubmitError)}
        height="100%"
      >
        <Box
          sx={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
            px: 5,
            gap: 2,
            minHeight: "calc(100vh - 8rem)",
            maxHeight: "calc(100vh - 8rem)",
          }}
        >
          <ProposalFormContext.Provider
            value={{
              control,
              mode,
              getFieldState,
              getValues,
              register,
              setValue,
              trigger,
              watch,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              <ProductKindField />
              <CompanyField />
              <NotesField />
              <PriceRequestFields />
              {productKind === ProductKind.BIOGAS && <BiogasFields />}
              {productKind === ProductKind.CARBON && <CarbonFields />}
              {productKind === ProductKind.WHITE_CERTIFICATE && (
                <WhiteCertFields />
              )}
              {productKind === ProductKind.GUARANTEE_OF_ORIGIN && <GoFields />}
              {productKind === ProductKind.INT_REC && <IRecFields />}
              {productKind === ProductKind.US_REC && <UsRecFields />}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RegionsField />
                <CountriesField />
              </Box>
              <AttachmentsField />
            </Box>
          </ProposalFormContext.Provider>
        </Box>
        <Toolbar
          sx={(theme) => ({
            display: "flex",
            justifyContent:
              mode.kind === "create" ? "space-between" : "flex-end",
            backgroundColor: theme.palette.grey[100],
            borderTop: `1px solid ${theme.palette.divider}`,
          })}
        >
          {mode.kind === "create" && (
            <Box>
              <FormControlLabel
                label="Create more"
                control={
                  <Switch
                    checked={createMore}
                    onChange={(e) => {
                      setCreateMore(e.currentTarget.checked)
                    }}
                  />
                }
              />
            </Box>
          )}

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button variant="contained" color="secondary" onClick={closeDrawer}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
              disabled={isSubmitting}
            >
              {mode.kind === "update" ? "Update" : "Create"}
            </Button>
          </Box>
        </Toolbar>
      </Box>
    </Box>
  )
}

function getInitialDefaultValues(
  proposalKind: ProposalKind,
  productKind: ProductKind,
) {
  return {
    state: State.INITIAL,
    kind: proposalKind,
    attachments: [],
    createdBy: null,
    company: null,
    countries: [],
    notes: null,
    volume: null,
    priceRequest: {
      kind: PriceRequestKind.FIRM,
      deadline: null,
      delivery: null,
      validity: null,
      currency: null,
      price: null,
    },
    product: {
      kind: productKind,
      input: null,
      instruments: [],
      labels: [],
      productionYear: { from: null, to: null },
      projectId: null,
      registries: [],
      sdgs: [],
      technologies: [],
      supportTypes: [],
      transferability: false,
      vintage: { from: null, to: null },
    },
  }
}
