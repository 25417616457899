import { ReactNode, useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import { AlertProps } from "./components/ui.alert.component"
import type { ActionItem, QuickFilters } from "./ui.models"

export function useUiStore() {
  const {
    uiModule: { service, store },
  } = useAppContext()
  const [state, setState] = useState(store.state)

  store.addListener(setState)

  const openDrawer = useCallback(
    (content: ReactNode) => {
      service.openDrawer(content)
    },
    [service],
  )

  const closeDrawer = useCallback(() => {
    service.closeDrawer()
  }, [service])

  const triggerAlert = useCallback(
    (alert: AlertProps) => {
      service.triggerAlert(alert)
    },
    [service],
  )

  const clearAlert = useCallback(() => {
    service.clearAlert()
  }, [service])

  const setFooterContent = useCallback(
    (footerContent: ReactNode) => {
      service.setFooterContent(footerContent)
    },
    [service],
  )

  const setQuickFilters = useCallback(
    (filters: QuickFilters) => {
      service.setQuickFilters(filters)
    },
    [service],
  )

  const setMenuItems = useCallback(
    (items: Record<string, ActionItem[]>) => {
      service.setMenuItems(items)
    },
    [service],
  )

  const addMenuItem = useCallback(
    (id: string, item: ActionItem) => {
      service.addMenuItem(id, item)
    },
    [service],
  )

  const removeMenuItem = useCallback(
    (id: string, item: ActionItem) => {
      service.removeMenuItem(id, item)
    },
    [service],
  )

  const clearMenuItems = useCallback(() => {
    service.clearMenuItems()
  }, [service])

  const hydrateQuickFilters = useCallback(() => {
    return service.hydrateQuickFilters()
  }, [service])

  return {
    state,
    actions: {
      openDrawer,
      closeDrawer,
      triggerAlert,
      clearAlert,
      setFooterContent,
      setQuickFilters,
      hydrateQuickFilters,
      setMenuItems,
      addMenuItem,
      removeMenuItem,
      clearMenuItems,
    },
  }
}
