import { createSvgIcon } from "@mui/material"

export const Logo = createSvgIcon(
  <svg
    height="100%"
    strokeMiterlimit="10"
    version="1.1"
    viewBox="0 0 1024 1024"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g id="Layer-1">
      <path
        d="M992.811 283.154C1056.53 404.196 1016.15 555.548 897.845 628.04C868.368 646.08 856.631 645.346 824.52 650.748C760.797 529.706 781.271 390.525 899.612 318.066C929.089 300.026 960.733 288.556 992.845 283.154L992.811 283.154Z"
        fill="#60c49f"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M507.476 404.196C516.379 540.71 629.852 648.681 768.6 648.681C803.179 648.681 813.416 640.845 843.626 628.674C834.723 492.16 743.958 385.256 605.21 385.256C570.631 385.256 537.687 392.025 507.476 404.196Z"
        fill="#60c49f"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M883.44 624.039C871.869 459.782 757.696 345.242 605.243 345.242C566.43 345.242 528.517 352.578 492.538 367.083L465.662 377.92L467.529 406.83C477.833 564.918 610.078 688.728 768.6 688.728C770.934 688.728 773.168 688.695 775.336 688.628C750.26 734.577 715.482 775.325 673.534 807.136C608.845 856.186 531.585 882.128 450.156 882.128C246.052 882.128 80.0278 716.104 80.0278 512C80.0278 307.896 246.052 141.872 450.156 141.872C547.39 141.872 639.255 179.285 708.813 247.241C724.618 262.68 749.96 262.38 765.399 246.575C780.838 230.769 780.538 205.427 764.732 189.988C680.136 107.36 568.431 61.8438 450.156 61.8438C201.937 61.8438 0 263.781 0 512C0 760.219 201.937 962.156 450.156 962.156C549.191 962.156 643.157 930.612 721.884 870.925C793.709 816.472 848.328 740.379 876.938 655.416L876.938 655.416C876.938 655.416 883.607 638.711 883.607 632.042M551.458 431.906C568.964 427.537 586.937 425.303 605.177 425.303C721.384 425.303 784.806 510.8 800.411 603.398C790.374 607.266 783.239 608.7 768.567 608.7C662.43 608.7 572.332 533.174 551.458 431.906Z"
        fill="#ffffff"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </g>
  </svg>,
  "Logo",
)

export default Logo
