import { Box, CircularProgress } from "@mui/material"

export function LoadingSpinner() {
  return (
    <Box
      sx={{
        position: "fixed",
        inset: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color="primary" size="3rem" />
    </Box>
  )
}
