import { LicenseInfo } from "@mui/x-data-grid-pro"
import { AuthModule } from "~/auth/auth.module"
import { NavigationModule } from "~/navigation/navigation.module"
import { UserModule } from "~/user/user.module"
import { AppConfig } from "./app.config"
import { CompaniesModule } from "./companies/companies.module"
import { CountriesModule } from "./countries/countries.module"
import { CurrenciesModule } from "./currencies/currencies.module"
import { FeatureFlagsModule } from "./feature-flags/feature-flags.module"
import { FilesModule } from "./files/files.module"
import { HttpModule } from "./http/http.module"
import { InputsModule } from "./inputs/inputs.module"
import { InstrumentsModule } from "./instruments/instruments.module"
import { LabelsModule } from "./labels/labels.module"
import { LocaleModule } from "./locale/locale.module"
import { NotificationsModule } from "./notifications/notifications.module"
import { ProposalsModule } from "./proposals/proposals.module"
import { RegionsModule } from "./regions/regions.module"
import { RegistriesModule } from "./registries/registries.module"
import { SdgsModule } from "./sdgs/sdgs.module"
import { TechnologiesModule } from "./technologies/technologies.module"
import { UiModule } from "./ui/ui.module"

export class AppModule {
  private readonly _appConfig: AppConfig

  private readonly _navigationModule: NavigationModule
  private readonly _authModule: AuthModule
  private readonly _httpModule: HttpModule

  private readonly _companiesModule: CompaniesModule
  private readonly _countriesModule: CountriesModule
  private readonly _currenciesModule: CurrenciesModule
  private readonly _registriesModule: RegistriesModule
  private readonly _featureFlagsModule: FeatureFlagsModule
  private readonly _filesModule: FilesModule
  private readonly _inputsModule: InputsModule
  private readonly _instrumentsModule: InstrumentsModule
  private readonly _labelsModule: LabelsModule
  private readonly _localeModule: LocaleModule
  private readonly _regionsModule: RegionsModule
  private readonly _proposalsModule: ProposalsModule
  private readonly _sdgsModule: SdgsModule
  private readonly _technologiesModule: TechnologiesModule
  private readonly _uiModule: UiModule
  private readonly _userModule: UserModule

  private readonly _notificationsModule: NotificationsModule

  constructor() {
    this._appConfig = new AppConfig()

    this._navigationModule = new NavigationModule()
    this._authModule = new AuthModule(this._navigationModule)
    this._httpModule = new HttpModule(this._authModule)

    this._companiesModule = new CompaniesModule(this._httpModule)
    this._countriesModule = new CountriesModule(this._httpModule)
    this._currenciesModule = new CurrenciesModule(this._httpModule)
    this._registriesModule = new RegistriesModule(this._httpModule)
    this._featureFlagsModule = new FeatureFlagsModule(this._httpModule)
    this._filesModule = new FilesModule(this._httpModule)
    this._inputsModule = new InputsModule(this._httpModule)
    this._instrumentsModule = new InstrumentsModule(this._httpModule)
    this._labelsModule = new LabelsModule(this._httpModule)
    this._localeModule = new LocaleModule()
    this._proposalsModule = new ProposalsModule(this._httpModule)
    this._regionsModule = new RegionsModule(this._httpModule)
    this._sdgsModule = new SdgsModule(this._httpModule)
    this._technologiesModule = new TechnologiesModule(this._httpModule)
    this._uiModule = new UiModule()
    this._userModule = new UserModule(this._authModule, this._httpModule)
    this._notificationsModule = new NotificationsModule(this._httpModule)
  }

  public get navigationModule() {
    return this._navigationModule
  }

  public get authModule() {
    return this._authModule
  }

  public get companiesModule() {
    return this._companiesModule
  }

  public get countriesModule() {
    return this._countriesModule
  }

  public get currenciesModule() {
    return this._currenciesModule
  }

  public get registriesModule() {
    return this._registriesModule
  }

  public get featureFlagsModule() {
    return this._featureFlagsModule
  }

  public get filesModule() {
    return this._filesModule
  }

  public get inputsModule() {
    return this._inputsModule
  }

  public get instrumentsModule() {
    return this._instrumentsModule
  }

  public get labelsModule() {
    return this._labelsModule
  }

  public get localeModule() {
    return this._localeModule
  }

  public get proposalsModule() {
    return this._proposalsModule
  }

  public get regionsModule() {
    return this._regionsModule
  }

  public get sdgsModule() {
    return this._sdgsModule
  }

  public get technologiesModule() {
    return this._technologiesModule
  }

  public get uiModule() {
    return this._uiModule
  }

  public get userModule() {
    return this._userModule
  }

  public get notificationsModule() {
    return this._notificationsModule
  }

  public async initialize() {
    LicenseInfo.setLicenseKey(this._appConfig.muiLicenseKey)
    await this._authModule.service.initialize()
  }
}

export const app = new AppModule()
