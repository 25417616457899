import SelectAllIcon from "@mui/icons-material/Add"
import {
  Autocomplete,
  IconButton,
  TextField,
  type AutocompleteRenderInputParams,
  type FilterOptionsState,
} from "@mui/material"
import React, { useCallback, useContext, useMemo } from "react"
import { Controller, type FieldError } from "react-hook-form"
import { ProductKind } from "~/proposals/models/product.model"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { useCountriesStore } from "../countries.use-countries-store"
import type { Country } from "../country.model"

export const CountriesField = () => {
  const {
    state: { countries: allCountries },
  } = useCountriesStore()
  const { control, getValues, setValue, watch } =
    useContext(ProposalFormContext)
  const productKind = watch("product.kind", getValues("product.kind"))
  const selectedCountries = watch("countries", getValues("countries"))

  const productCountries = useMemo(() => {
    return allCountries.filter(({ regions }) => {
      switch (productKind) {
        case ProductKind.BIOGAS: {
          return regions.every((region) => {
            return (
              region.name !== "Developing Countries" &&
              region.name !== "Undeveloped Countries"
            )
          })
        }
        case ProductKind.CARBON: {
          return true
        }
        case ProductKind.WHITE_CERTIFICATE: {
          return regions.every((region) => {
            return (
              region.name !== "Developing Countries" &&
              region.name !== "Undeveloped Countries"
            )
          })
        }
        case ProductKind.GUARANTEE_OF_ORIGIN: {
          return regions.some(
            (region) =>
              region.acronym === "AIB" ||
              region.name === "Europe" ||
              region.name === "Grid Connected" ||
              region.name === "Nordics" ||
              region.name === "United Kingdom",
          )
        }
        case ProductKind.INT_REC: {
          return regions.every(
            (region) =>
              region.name !== "Europe" && region.name !== "North America",
          )
        }
        case ProductKind.US_REC: {
          return regions.some((region) => region.name === "North America")
        }
      }
    })
  }, [allCountries, productKind])

  const filterOptions = useCallback(
    (options: Country[], { inputValue }: FilterOptionsState<Country>) => {
      return options.filter((option) => {
        return (
          option.code.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.acronym?.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      })
    },
    [],
  )

  const selectAll = useCallback(() => {
    setValue("countries", productCountries)
  }, [productCountries])

  const renderInput = useCallback(
    (name: string, error: FieldError | undefined) =>
      (params: AutocompleteRenderInputParams) => {
        const showSelectAllBtn =
          selectedCountries.length < productCountries.length
        let endAdornment = null

        if (React.isValidElement(params.InputProps.endAdornment)) {
          endAdornment = React.cloneElement(params.InputProps.endAdornment, {
            ...params.InputProps.endAdornment.props,
            children: showSelectAllBtn
              ? [
                  <IconButton
                    key={`${name}-MuiAutocomplete-selectAllIndicator`}
                    size="medium"
                    onClick={selectAll}
                    aria-label="Select all"
                    title="Select all"
                    className="MuiAutocomplete-selectAllIndicator"
                    sx={{ visibility: "hidden", p: "3px", mr: "-2px" }}
                  >
                    <SelectAllIcon sx={{ fontSize: "1.35rem" }} />
                  </IconButton>,
                  ...params.InputProps.endAdornment.props.children,
                ]
              : params.InputProps.endAdornment.props.children,
          })
        }

        return (
          <TextField
            {...params}
            error={Boolean(error)}
            helperText={error?.message}
            label="Countries"
            required
            InputProps={{
              ...params.InputProps,
              endAdornment,
            }}
          />
        )
      },
    [productCountries, selectedCountries, selectAll],
  )

  return (
    <Controller
      control={control}
      name="countries"
      render={({
        fieldState: { error },
        field: { name, onChange, value: selectedCountries },
      }) => (
        <Autocomplete
          options={productCountries}
          size="small"
          fullWidth
          multiple
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option._id === value?._id}
          filterOptions={filterOptions}
          renderInput={renderInput(name, error)}
          onChange={(_, value) => {
            onChange(value)
          }}
          value={selectedCountries}
          sx={{
            width: "100%",
            ".Mui-focused .MuiAutocomplete-selectAllIndicator": {
              visibility:
                selectedCountries.length < productCountries.length
                  ? "visible"
                  : "hidden",
            },
            "&:hover .MuiAutocomplete-selectAllIndicator": {
              visibility:
                selectedCountries.length === productCountries.length
                  ? "hidden"
                  : "visible",
            },
          }}
        />
      )}
    />
  )
}
