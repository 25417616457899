import { IsBoolean, IsNotEmpty, IsString } from "class-validator"

export class Currency {
  @IsString()
  @IsNotEmpty()
  _id: string

  @IsString()
  @IsNotEmpty()
  code: string

  @IsString()
  @IsNotEmpty()
  name: string

  @IsString()
  @IsNotEmpty()
  symbol: string

  @IsBoolean()
  isCommon: boolean
}
