import ClockIcon from "@mui/icons-material/AccessTime"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import CloseIcon from "@mui/icons-material/Close"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useCallback } from "react"
import { useFilesStore } from "~/files/files.use-files-store.hook"
import { useLocaleStore } from "~/locale/locale.use-locale-store.hook"
import {
  currencyFormatter,
  dateFormatter,
  numberFormatter,
} from "~/locale/locale.utils"
import {
  guaranteeOfOriginSupportTypeLabels,
  ProductKind,
} from "~/proposals/models/product.model"
import { type Proposal } from "~/proposals/models/proposal.model"
import { PriceRequestKind } from "~/proposals/price-requests/price-request.model"
import {
  formatNotes,
  formatProductKind,
  formatProposalKind,
} from "~/proposals/proposal.utils"
import { useProposalsStore } from "~/proposals/proposals.use-proposals-store.hook"
import { useUiStore } from "~/ui/ui.use-ui-store.hook"
import { Avatar } from "~/user/user.avatar.component"
import { ProposalForm } from "../form-views/proposal.form.view"
import { Matches } from "./proposal.matches.view"

type Props = {
  proposal: Proposal
}

export function DetailsView({ proposal }: Props) {
  const {
    actions: { closeDrawer, openDrawer },
  } = useUiStore()
  const { state: locale } = useLocaleStore()
  const {
    actions: { downloadFile, setFiles },
  } = useFilesStore()
  const {
    actions: { archiveProposal, cloneProposal },
  } = useProposalsStore()
  const formatDate = dateFormatter(locale, { dateStyle: "medium" })
  const formatDelivery = dateFormatter(locale, {
    year: "numeric",
    month: "long",
  })
  const formatDateTime = dateFormatter(locale, {
    dateStyle: "medium",
    timeStyle: "short",
  })
  const formatCurrency = currencyFormatter(
    locale,
    proposal.priceRequest.currency?.code ?? "EUR",
  )
  const formatNumber = numberFormatter(locale)

  const handleEdit = useCallback(() => {
    const clone = cloneProposal(proposal, "update") as Proposal

    closeDrawer()
    setFiles(clone.attachments)
    openDrawer(
      <ProposalForm
        mode={{ kind: "update", proposal: clone }}
        kind={proposal.kind}
      />,
    )
  }, [proposal])

  const handleArchive = useCallback(() => {
    archiveProposal(proposal)
    closeDrawer()
  }, [proposal])

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(proposal.go2Id)
  }, [proposal])

  const renderProductDetails = useCallback(() => {
    switch (proposal.product.kind) {
      case ProductKind.BIOGAS: {
        return (
          <>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Input
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.input.name}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Labels
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.labels
                  .map((label) => {
                    return label.acronym
                      ? `${label.name} (${label.acronym})`
                      : label.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
          </>
        )
      }

      case ProductKind.CARBON: {
        return (
          <>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Instruments
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.instruments
                  .map((instrument) => {
                    return instrument.acronym
                      ? `${instrument.name} (${instrument.acronym})`
                      : instrument.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Labels
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.labels
                  .map((label) => {
                    return label.acronym
                      ? `${label.name} (${label.acronym})`
                      : label.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Technologies
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.technologies
                  .map((technology) => {
                    return technology.acronym
                      ? `${technology.name} (${technology.acronym})`
                      : technology.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                SDGs
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.sdgs
                  .map((sdg) => {
                    return `${sdg.goalNumber}. ${sdg.name}`
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
          </>
        )
      }

      case ProductKind.GUARANTEE_OF_ORIGIN: {
        return (
          <>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Labels
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.labels
                  .map((label) => {
                    return label.acronym
                      ? `${label.name} (${label.acronym})`
                      : label.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Technologies
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.technologies
                  .map((technology) => {
                    return technology.acronym
                      ? `${technology.name} (${technology.acronym})`
                      : technology.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Support
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.supportTypes
                  .map((supportType) =>
                    guaranteeOfOriginSupportTypeLabels.get(supportType),
                  )
                  .join(", ")}
              </Typography>
            </Grid2>
          </>
        )
      }

      case ProductKind.INT_REC: {
        return (
          <>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Labels
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.labels
                  .map((label) => {
                    return label.acronym
                      ? `${label.name} (${label.acronym})`
                      : label.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Technologies
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.technologies
                  .map((technology) => {
                    return technology.acronym
                      ? `${technology.name} (${technology.acronym})`
                      : technology.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
          </>
        )
      }

      case ProductKind.US_REC: {
        return (
          <>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Labels
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.labels
                  .map((label) => {
                    return label.acronym
                      ? `${label.name} (${label.acronym})`
                      : label.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Technologies
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.technologies
                  .map((technology) => {
                    return technology.acronym
                      ? `${technology.name} (${technology.acronym})`
                      : technology.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
          </>
        )
      }

      case ProductKind.WHITE_CERTIFICATE: {
        return (
          <>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Instruments
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.instruments
                  .map((instrument) => {
                    return instrument.acronym
                      ? `${instrument.name} (${instrument.acronym})`
                      : instrument.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Technologies
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.product.technologies
                  .map((technology) => {
                    return technology.acronym
                      ? `${technology.name} (${technology.acronym})`
                      : technology.name
                  })
                  .join(", ")}
              </Typography>
            </Grid2>
          </>
        )
      }
    }
  }, [proposal])

  const renderStatus = useCallback(() => {
    switch (proposal.priceRequest.kind) {
      case PriceRequestKind.FIRM: {
        return <Chip label="Firm" color="primary" size="small" />
      }

      case PriceRequestKind.RFQ: {
        return <Chip label="RFQ" color="primary" size="small" />
      }

      case PriceRequestKind.INDICATIVE: {
        return <Chip label="Indicative" color="primary" size="small" />
      }

      case PriceRequestKind.ONGOING: {
        return <Chip label="Ongoing" color="primary" size="small" />
      }
    }
  }, [proposal])

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.main,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          p: 2.5,
          maxHeight: "11.5rem",
        })}
      >
        <Box display="flex" flexDirection="column" gap={0.75}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" color="textSecondary">
              {formatProposalKind(proposal.kind)} ID: {proposal.go2Id}
            </Typography>
            <IconButton size="small" onClick={handleCopyClick}>
              <ContentCopyIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
          </Box>
          <Typography variant="h5" fontSize="1.25rem">
            <Box component="span" fontWeight="bold">
              {proposal.company.properties.name}
            </Box>{" "}
            {formatProposalKind(proposal.kind, { case: "lower", form: "verb" })}{" "}
            <Box component="span" fontWeight="bold">
              {formatNumber(proposal.volume)}{" "}
              {proposal.product.kind === ProductKind.CARBON ? "Tons" : "MWh"}{" "}
            </Box>
            of{" "}
            <Box component="span" fontWeight="bold">
              {formatProductKind(proposal.product.kind, {
                case: "lower",
                form: "noun",
              })}
            </Box>{" "}
            at{" "}
            {proposal.priceRequest.price ? (
              <Box component="span" fontWeight="bold">
                {formatCurrency(proposal.priceRequest.price)}/
                {proposal.product.kind === ProductKind.CARBON ? "Ton" : "MWh"}
              </Box>
            ) : (
              "best price"
            )}
          </Typography>
          <Box display="flex" gap={1} alignItems="center">
            {renderStatus()}
            <Divider orientation="vertical" flexItem />
            <Typography
              display="flex"
              variant="body1"
              color="textSecondary"
              gap={0.5}
              alignItems="center"
            >
              <ClockIcon sx={{ fontSize: "1.25rem" }} />
              {proposal.priceRequest.deadline
                ? formatDate(proposal.priceRequest.deadline)
                : "N/A"}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Box display="flex" gap={0.5} alignItems="center">
              {proposal.createdBy.avatar && (
                <Avatar user={proposal.createdBy} />
              )}
              <Typography variant="body1" color="textSecondary">
                {proposal.createdBy.name}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1" color="textSecondary">
              Last modified at {formatDateTime(proposal.updatedAt)}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box
        sx={{
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 2,
          px: 5,
          gap: 2,
          minHeight: "calc(100vh - 15.5rem)",
          maxHeight: "calc(100vh - 15.5rem)",
        }}
      >
        <Grid2 container rowSpacing={2} width="100%">
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              Company
            </Typography>
            <Typography variant="body1">
              {proposal.company.properties.name}
            </Typography>
          </Grid2>
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              Status
            </Typography>
            <Typography component="div" variant="body1">
              {renderStatus()}
            </Typography>
          </Grid2>
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              {formatProposalKind(proposal.kind)} ID
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">{proposal.go2Id}</Typography>
              <IconButton size="small" onClick={handleCopyClick}>
                <ContentCopyIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Box>
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="subtitle2" color="grey.600">
              Notes
            </Typography>
            <Typography variant="body1">
              <span
                dangerouslySetInnerHTML={{
                  __html: formatNotes(proposal.notes ?? "No notes provided"),
                }}
              />
            </Typography>
          </Grid2>
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              Price
            </Typography>
            <Box display="flex" alignItems="baseline" gap={0.5}>
              {proposal.priceRequest.price ? (
                <>
                  <Typography variant="body1">
                    {formatCurrency(proposal.priceRequest.price)}
                  </Typography>
                  <Typography variant="body1" color="grey.600">
                    ({proposal.priceRequest.currency?.code})
                  </Typography>
                </>
              ) : (
                "N/A"
              )}
            </Box>
          </Grid2>
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              Volume
            </Typography>
            <Typography variant="body1">
              {proposal.volume
                ? `${proposal.volume} ${proposal.product.kind === ProductKind.CARBON ? "Tons" : "MWh"}`
                : "N/A"}
            </Typography>
          </Grid2>
          <Grid2 xs={4}></Grid2>
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              Validity
            </Typography>
            <Typography variant="body1">
              {proposal.priceRequest.validity
                ? formatDateTime(proposal.priceRequest.validity)
                : "N/A"}
            </Typography>
          </Grid2>
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              Delivery
            </Typography>
            <Typography variant="body1">
              {proposal.priceRequest.delivery
                ? formatDelivery(proposal.priceRequest.delivery)
                : "N/A"}
            </Typography>
          </Grid2>
          <Grid2 xs={4}>
            <Typography variant="subtitle2" color="grey.600">
              {proposal.product.kind === ProductKind.CARBON
                ? "Vintage"
                : "Production year"}
            </Typography>
            <Typography variant="body1">
              {proposal.product.kind === ProductKind.CARBON
                ? proposal.product.vintage.from?.getFullYear() ?? "N/A"
                : proposal.product.productionYear.from?.getFullYear() ?? "N/A"}
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="subtitle2" color="grey.600">
              Attachments
            </Typography>
            {proposal.attachments.length > 0 ? (
              proposal.attachments.map((attachment) => {
                return (
                  <Box key={attachment.name} display="flex" alignItems="center">
                    <AttachFileIcon sx={{ fontSize: "1rem" }} />
                    <Typography variant="body1">
                      <Link onClick={() => downloadFile(attachment.name)}>
                        {attachment.name.split("-filename-")[1]}
                      </Link>
                    </Typography>
                  </Box>
                )
              })
            ) : (
              <Typography variant="body1">N/A</Typography>
            )}
          </Grid2>
        </Grid2>
        <Box bgcolor="grey.100" mt={1} width="100%">
          <Grid2 container rowSpacing={1} p={2}>
            {renderProductDetails()}
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Regions
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {Array.from(
                  new Set(
                    proposal.countries.flatMap((country) =>
                      country.regions.map(
                        (region) => region.acronym ?? region.name,
                      ),
                    ),
                  ),
                ).join(", ")}
              </Typography>
            </Grid2>
            <Grid2 xs={3}>
              <Typography variant="subtitle2" color="grey.600">
                Countries
              </Typography>
            </Grid2>
            <Grid2 xs={9}>
              <Typography variant="body1">
                {proposal.countries.map((country) => country.name).join(", ")}
              </Typography>
            </Grid2>
          </Grid2>
        </Box>
        <Divider />
        <Matches proposal={proposal} />
      </Box>
      <Toolbar
        sx={(theme) => ({
          display: "flex",
          width: "100%",
          gap: 1,
          justifyContent: "flex-end",
          borderTop: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Button variant="outlined" onClick={handleEdit}>
          Edit
        </Button>
        <Button variant="outlined" color="error" onClick={handleArchive}>
          Archive
        </Button>
      </Toolbar>
    </Box>
  )
}
