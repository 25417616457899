import { Box } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { LabelsField } from "~/labels/views/labels-field.view"
import { ProposalKind } from "~/proposals/models/proposal.model"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { TechnologiesField } from "~/technologies/views/technologies-field.view"
import { YearField } from "./proposal.year-field"

export function IRecFields() {
  const { control, getValues, watch } = useContext(ProposalFormContext)
  const proposalKind = watch("kind", getValues("kind"))

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <LabelsField />
        <TechnologiesField />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ width: "50%" }}>
          <Controller
            control={control}
            name="product.productionYear.from"
            render={({ fieldState, field: { value, onChange } }) => {
              return (
                <YearField
                  error={fieldState.error?.message}
                  label={
                    proposalKind === ProposalKind.REQUEST
                      ? "Production year (from)"
                      : "Production year"
                  }
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </Box>
        {proposalKind === ProposalKind.REQUEST ? (
          <Box sx={{ width: "50%" }}>
            <Controller
              control={control}
              name="product.productionYear.to"
              render={({ fieldState, field: { value, onChange } }) => {
                return (
                  <YearField
                    error={fieldState.error?.message}
                    label="Production year (to)"
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
          </Box>
        ) : (
          <Box sx={{ width: "50%" }} />
        )}
      </Box>
    </>
  )
}
