import { Box, Chip } from "@mui/material"
import {
  priceRequestKinds,
  type PriceRequestKind,
} from "~/proposals/price-requests/price-request.model"
import { useUiStore } from "~/ui/ui.use-ui-store.hook"

export function PriceRequestMenu() {
  const {
    state: { quickFilters },
    actions: { setQuickFilters },
  } = useUiStore()

  const handleChange = (kind: PriceRequestKind) => {
    const priceRequestKinds = quickFilters.priceRequestKinds.includes(kind)
      ? quickFilters.priceRequestKinds.filter((k) => k !== kind)
      : [...quickFilters.priceRequestKinds, kind]

    setQuickFilters({ ...quickFilters, priceRequestKinds })
  }

  return (
    <Box display="flex" gap={1}>
      {priceRequestKinds.map((kind) => (
        <Chip
          key={kind}
          color="primary"
          label={kind}
          variant={
            quickFilters.priceRequestKinds.includes(kind)
              ? "filled"
              : "outlined"
          }
          onClick={() => handleChange(kind)}
          sx={{ px: "0.5rem" }}
        />
      ))}
    </Box>
  )
}
