import { Type } from "class-transformer"
import {
  ArrayNotEmpty,
  IsArray,
  IsNotEmpty,
  IsObject,
  IsString,
  ValidateNested,
} from "class-validator"
import { Currency } from "~/currencies/currency.model"
import { IsNullable } from "~/utils/utils.validation"

export class Country {
  @IsNotEmpty()
  @IsString()
  _id: string

  @IsNotEmpty()
  @IsString()
  name: string

  @IsString()
  @IsNullable()
  acronym: string | null

  @ValidateNested()
  @Type(() => Currency)
  @IsObject()
  currency: Currency

  @IsNotEmpty()
  @IsString()
  unicodeFlag: string

  @IsNotEmpty()
  @IsString()
  code: string

  @ValidateNested({ each: true })
  @Type(() => CountryRegion)
  @ArrayNotEmpty()
  @IsArray()
  regions: CountryRegion[]
}

export class CountryRegion {
  @IsNotEmpty()
  @IsString()
  _id: string

  @IsNotEmpty()
  @IsString()
  name: string

  @IsString()
  @IsNullable()
  acronym: string | null
}
