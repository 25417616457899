import { Type } from "class-transformer"
import { IsArray, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Label } from "../label.model"

export class LabelsDto implements HttpDto<Label[]> {
  @Type(() => Label)
  @IsArray()
  @ValidateNested({ each: true })
  declare readonly data: Label[]
}
