import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { PriceRequestKind } from "../price-request.model"

export const DeliveryField = () => {
  const { control, getValues, watch } = useContext(ProposalFormContext)
  const priceRequestKind = watch(
    "priceRequest.kind",
    getValues("priceRequest.kind"),
  )

  return (
    <Controller
      control={control}
      name="priceRequest.delivery"
      render={({
        fieldState: { error },
        field: { name, value, ref, onChange },
      }) => {
        return (
          <DatePicker
            label="Delivery"
            value={value ? dayjs(value) : null}
            views={["month", "year"]}
            inputRef={ref}
            onChange={(value) => {
              onChange(value?.set("date", 15).set("hour", 12).toDate() ?? null)
            }}
            slotProps={{
              textField: {
                name,
                error: Boolean(error),
                helperText: error?.message,
                size: "small",
                fullWidth: true,
                required: priceRequestKind !== PriceRequestKind.ONGOING,
                sx: { width: "50%" },
              },
            }}
          />
        )
      }}
    />
  )
}
