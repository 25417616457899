import { SyncStore } from "~/store/sync-store"
import { type Locale } from "./locale.model"
import { toLocale } from "./locale.utils"

type State = Locale

export class LocaleStore extends SyncStore<State> {
  constructor() {
    super(toLocale(navigator.language))
  }
}
