import { GridColDef } from "@mui/x-data-grid-pro"
import { Country } from "~/countries/country.model"
import {
  Proposal,
  type InitialProposal,
} from "~/proposals/models/proposal.model"

const getRegionsFromCountries = (countries: Country[]): string => {
  const regions = countries.flatMap((country) => country.regions)
  return Array.from(
    new Set(regions.map((region) => region.acronym ?? region.name)),
  )
    .sort()
    .join(", ")
}

export const getRegionsGridColDef = <
  T extends Proposal = InitialProposal,
>(): GridColDef<T> => {
  return {
    field: "regions",
    headerName: "Regions",
    type: "string",
    sortable: true,
    valueGetter: ({ row: { countries } }) => {
      return getRegionsFromCountries(countries)
    },
    valueFormatter: ({ value }) => {
      return value
    },
    renderCell: ({ row: { countries } }) => {
      return getRegionsFromCountries(countries)
    },
  }
}
