import {
  Autocomplete,
  FilterOptionsState,
  ListItem,
  TextField,
} from "@mui/material"
import { useCallback, useContext, useEffect } from "react"
import { Controller } from "react-hook-form"
import { ProductKind } from "~/proposals/models/product.model"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { Tooltip } from "~/ui/ui.tooltip.component"
import { useCurrenciesStore } from "../currencies.use-currencies-store.hook"
import { Currency } from "../currency.model"

type Props = {
  required?: boolean
}

export const CurrencyField = ({ required = false }: Props) => {
  const {
    state: { currencies: allCurrencies },
  } = useCurrenciesStore()
  const { control, mode, getValues, setValue, watch } =
    useContext(ProposalFormContext)
  const productKind = watch("product.kind", getValues("product.kind"))

  const currencies = allCurrencies.filter((currency) => {
    switch (productKind) {
      case ProductKind.GUARANTEE_OF_ORIGIN: {
        return currency.code === "EUR" || currency.code === "GBP"
      }

      case ProductKind.US_REC: {
        return currency.code === "USD"
      }

      default: {
        return true
      }
    }
  })

  const filterOptions = useCallback(
    (options: Currency[], { inputValue }: FilterOptionsState<Currency>) => {
      return options.filter((option) => {
        return (
          option.symbol.includes(inputValue) ||
          option.code.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      })
    },
    [],
  )

  useEffect(() => {
    if (mode.kind !== "create") return

    switch (productKind) {
      case ProductKind.CARBON: {
        setValue(
          "priceRequest.currency",
          currencies.find((currency) => currency.code === "USD")!,
        )
        break
      }

      case ProductKind.GUARANTEE_OF_ORIGIN: {
        setValue(
          "priceRequest.currency",
          currencies.find((currency) => currency.code === "EUR")!,
        )
        break
      }

      case ProductKind.US_REC: {
        setValue(
          "priceRequest.currency",
          currencies.find((currency) => currency.code === "USD")!,
        )
        break
      }

      case ProductKind.WHITE_CERTIFICATE: {
        setValue(
          "priceRequest.currency",
          currencies.find((currency) => currency.code === "EUR")!,
        )
        break
      }

      default: {
        setValue("priceRequest.currency", null)
        break
      }
    }
  }, [productKind])

  return (
    <Controller
      name="priceRequest.currency"
      control={control}
      render={({
        fieldState: { error },
        field: { value: selectedCurrency, onChange },
      }) => {
        return (
          <Autocomplete
            options={currencies}
            size="small"
            getOptionLabel={({ code, symbol }) => `${code} (${symbol})`}
            groupBy={({ isCommon }) => (isCommon ? "Common" : "All")}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            filterOptions={filterOptions}
            value={selectedCurrency}
            disabled={productKind === ProductKind.US_REC}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                error={Boolean(error)}
                helperText={error?.message}
                label="Currency"
              />
            )}
            renderOption={(props, { _id, code, name, symbol }) => {
              return (
                <Tooltip key={_id} title={name} placement="right">
                  <ListItem {...props}>
                    {code} ({symbol})
                  </ListItem>
                </Tooltip>
              )
            }}
            onChange={(_, value) => {
              onChange(value)
            }}
            sx={{ width: "50%" }}
          />
        )
      }}
    />
  )
}
