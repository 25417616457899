import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { SdgsDto } from "./dtos/sdgs.dto"
import type { SdgsConfig } from "./sdgs.config"
import type { SdgsStore } from "./sdgs.store"

export class SdgsService {
  private readonly name = "SdgsService"

  constructor(
    private readonly httpService: HttpService,
    private readonly sdgsConfig: SdgsConfig,
    private readonly sdgsStore: SdgsStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.sdgsStore.state)) {
      return Logger.info(
        `${id} SDGs are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.sdgsStore.state)) {
      return Logger.info(`${id} SDGs found in cache, not fetching again.`)
    }

    try {
      this.sdgsStore.state = loading

      const { errors, data: sdgs } = await this.httpService.get(
        this.sdgsConfig.apiUrl,
        SdgsDto,
      )

      if (errors.length) {
        const msg = "Invalid sdgs"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.sdgsStore.state = failure(msg)
        return
      }

      this.sdgsStore.state = success({ sdgs })
    } catch (error) {
      const msg = "Failed to fetch sdgs"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.sdgsStore.state = failure(msg)
    }
  }
}
