import { NavigationModule } from "~/navigation/navigation.module"
import { AuthConfig } from "./auth.config"
import { AuthService } from "./auth.service"
import { AuthStore } from "./auth.store"

export class AuthModule {
  private readonly _authConfig: AuthConfig
  private readonly _authService: AuthService
  private readonly _authStore: AuthStore

  constructor(private readonly navigationModule: NavigationModule) {
    this._authConfig = new AuthConfig()
    this._authStore = new AuthStore()
    this._authService = new AuthService(
      this.navigationModule.service,
      this._authConfig,
      this._authStore,
    )
  }

  get service() {
    return this._authService
  }

  get store() {
    return this._authStore
  }
}
