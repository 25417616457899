export class AppConfig {
  private readonly _muiLicenseKey: string

  constructor() {
    this._muiLicenseKey =
      "baff3876286e13417a055d1c17dce879Tz04Mjk5OCxFPTE3Mzc4ODU0MjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  }

  get muiLicenseKey() {
    return this._muiLicenseKey
  }
}
