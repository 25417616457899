import { Type } from "class-transformer"
import { IsArray, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Sdg } from "../sdg.model"

export class SdgsDto implements HttpDto<Sdg[]> {
  @Type(() => Sdg)
  @IsArray()
  @ValidateNested({ each: true })
  declare readonly data: Sdg[]
}
