import { AuthModule } from "~/auth/auth.module"
import { HttpModule } from "~/http/http.module"
import { UserConfig } from "./user.config"
import { UserService } from "./user.service"
import { UserStore } from "./user.store"

export class UserModule {
  private readonly _userConfig: UserConfig
  private readonly _userService: UserService
  private readonly _userStore: UserStore

  constructor(
    private readonly authModule: AuthModule,
    private readonly httpModule: HttpModule,
  ) {
    this._userConfig = new UserConfig()
    this._userStore = new UserStore()
    this._userService = new UserService(
      this.authModule.service,
      this.authModule.store,
      this.httpModule.service,
      this._userConfig,
      this._userStore,
    )
  }

  get service() {
    return this._userService
  }

  get store() {
    return this._userStore
  }
}
