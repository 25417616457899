import LanguageIcon from "@mui/icons-material/Language"
import LogoutIcon from "@mui/icons-material/Logout"
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined"
import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Switch,
  useTheme,
  type SelectChangeEvent,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import { useFeatureFlags } from "~/feature-flags/feature-flags.use-feature-flags.hook"
import type { Language, Region } from "~/locale/locale.model"
import { useLocaleStore } from "~/locale/locale.use-locale-store.hook"
import { languages, regions, toLanguage, toRegion } from "~/locale/locale.utils"
import { useUserStore } from "~/user/user.use-user.hook"

export function UserMenu() {
  const {
    state: { user },
  } = useUserStore()
  const {
    actions: { setLanguage, setRegion },
    state: locale,
  } = useLocaleStore()
  const {
    state: { featureFlags },
    actions: { getFeatureFlag },
  } = useFeatureFlags()
  const { authModule } = useAppContext()

  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const [showFeedbackTool, setShowFeedbackTool] = useState(true)

  useEffect(() => {
    getFeatureFlag("isLanguageSelectorEnabled")
  }, [getFeatureFlag])

  useEffect(() => {
    const el = document.getElementsByTagName(
      "feedbucket-app",
    )[0] as HTMLElement | null

    if (!el) {
      return
    }

    el.style.visibility = showFeedbackTool ? "visible" : "hidden"

    return () => {
      el.style.visibility = "visible"
    }
  }, [showFeedbackTool])

  const handleLanguageChange = (event: SelectChangeEvent<Language["code"]>) => {
    setLanguage(toLanguage(event.target.value))
  }

  const handleRegionChange = (event: SelectChangeEvent<Region["code"]>) => {
    setRegion(toRegion(event.target.value))
  }

  const toggleFeedbackTool = () => {
    setShowFeedbackTool(!showFeedbackTool)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    authModule.service.logout()
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={isOpen ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
      >
        <Avatar
          alt={user?.name}
          src={user?.avatar ?? undefined}
          sx={{
            width: 28,
            height: 28,
            fontSize: "0.875rem",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {user?.email.substring(0, 2).toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isOpen}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={(theme) => ({
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          color: theme.palette.grey[700],
        })}
      >
        <Box
          sx={(theme) => ({
            display: "block",
            p: "6px 1rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: theme.palette.grey[700],
          })}
        >
          <Box
            component="span"
            sx={(theme) => ({
              display: "block",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              color: theme.palette.grey[700],
            })}
          >
            {user?.name}
          </Box>
          <Box
            component="span"
            sx={(theme) => ({
              display: "block",
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              color: theme.palette.grey[700],
            })}
          >
            {user?.email}
          </Box>
        </Box>
        <Divider sx={{ m: "0.5rem 0" }} />

        <MenuItem sx={{ display: "flex", gap: 0.5 }}>
          <ListItemIcon>
            <LanguageIcon fontSize="small" />
          </ListItemIcon>
          {featureFlags.isLanguageSelectorEnabled && (
            <Select
              value={locale.language.code}
              onChange={handleLanguageChange}
              size="small"
              sx={{
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                color: theme.palette.grey[700],
              }}
            >
              {languages.map((language) => (
                <MenuItem key={language.code} value={language.code}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <Select
            value={locale.region.code}
            onChange={handleRegionChange}
            size="small"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              color: theme.palette.grey[700],
            }}
          >
            {regions.map((region) => (
              <MenuItem key={region.code} value={region.code}>
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </MenuItem>

        <MenuItem sx={{ display: "flex", gap: 0.5 }}>
          <ListItemIcon>
            <RateReviewOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={showFeedbackTool}
                onChange={toggleFeedbackTool}
                sx={{ mx: 0.5 }}
              />
            }
            label="Show feedback tool"
            slotProps={{
              typography: {
                sx: {
                  fontSize: "0.875rem",
                  lineHeight: "1.25rem",
                  color: theme.palette.grey[700],
                },
              },
            }}
          />
        </MenuItem>

        <Divider
          flexItem
          sx={{
            "&.MuiDivider-root": {
              m: "0.5rem 0",
            },
          }}
        />

        <MenuItem sx={{ display: "flex", gap: 0.5 }}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Box
            component="span"
            onClick={handleLogout}
            sx={{
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              color: theme.palette.grey[700],
            }}
          >
            Sign out
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}
