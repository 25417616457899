import { nanoid } from "nanoid"
import { Logger } from "~/utils/utils.logger"

export type Initial = {
  phase: "initial"
}

export type Loading = {
  phase: "loading"
}

export type Success<Ok extends Record<string, unknown>> = {
  phase: "success"
  data: Ok
}

export type Failure<Err> = {
  phase: "failure"
  error: Err
}

export type AsyncState<Err, Ok extends Record<string, unknown>> =
  | Initial
  | Loading
  | Success<Ok>
  | Failure<Err>

export class AsyncStore<Err, Ok extends Record<string, unknown>> {
  private readonly _name: string
  private _state: AsyncState<Err, Ok>
  private readonly _listeners: Record<
    string,
    (state: AsyncState<Err, Ok>) => void
  > = {}

  constructor(name: string, state: AsyncState<Err, Ok>) {
    this._name = name
    this._state = state
  }

  public get name(): string {
    return this._name
  }

  public get state(): AsyncState<Err, Ok> {
    return Object.freeze(this._state)
  }

  public set state(state: AsyncState<Err, Ok>) {
    Logger.info(`${this._name} set state`, state)
    this._state = state
    this.notifyListeners()
  }

  public addListener(listener: (state: AsyncState<Err, Ok>) => void) {
    const key = nanoid()
    this._listeners[key] = listener
  }

  public removeListener(key: string) {
    delete this._listeners[key]
  }

  public notifyListeners() {
    for (const listener of Object.values(this._listeners)) {
      listener(this._state)
    }
  }
}
