import { Type } from "class-transformer"
import { IsArray, ValidateNested } from "class-validator"
import { HttpDto } from "~/http/http.models"
import { Currency } from "../currency.model"

export class CurrenciesDto implements HttpDto<Currency[]> {
  @Type(() => Currency)
  @IsArray()
  @ValidateNested({ each: true })
  readonly data: Currency[]
}
