export class CompaniesConfig {
  private readonly _hubSpotPortalId: string
  private readonly _hubSpotBaseUrl: string
  private readonly _hubSpotCompanyUrl: string
  private readonly _hubSpotCompaniesUrl: string
  private readonly _companyApiUrl: string

  constructor() {
    this._hubSpotPortalId = "139793106"
    this._hubSpotBaseUrl = `https://app-eu1.hubspot.com/contacts/${this._hubSpotPortalId}`
    this._hubSpotCompanyUrl = `${this._hubSpotBaseUrl}/company`
    this._hubSpotCompaniesUrl = `${this._hubSpotBaseUrl}/objects/0-2/views/all/list`
    this._companyApiUrl = "/api/companies"
  }

  public get hubSpotCompanyUrl() {
    return this._hubSpotCompanyUrl
  }

  public get hubSpotCompaniesUrl() {
    return this._hubSpotCompaniesUrl
  }

  public get companyApiUrl() {
    return this._companyApiUrl
  }
}
