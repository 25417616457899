import { Box, Button, Card, CircularProgress, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "~/app.use-app-context.hook"
import Logo from "~/ui/components/ui.logo.component"
import MicrosoftLogo from "~/ui/components/ui.microsoft-logo.component"
import { useIsAuthenticated } from "./auth.use-is-authenticated.hook"

export function Login() {
  const [isLoading, setIsLoading] = useState(false)
  const { authModule } = useAppContext()
  const { isAuthenticated } = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/proposals/offers")
    }
  }, [isAuthenticated, navigate])

  const handleLogin = useCallback(() => {
    setIsLoading(true)
    authModule.service.login()
  }, [authModule])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "grey.50",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          p: "3.5rem",
          width: "32rem",
          mx: "auto",
          height: "32rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Logo
            sx={{
              width: "3.5rem",
              height: "auto",
              mr: "1rem",
            }}
          />
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            TradingOS
          </Typography>
        </Box>
        <Box
          sx={{ visibility: isLoading ? "visible" : "hidden" }}
          role="status"
        >
          <CircularProgress color="primary" size="3rem" />
        </Box>
        <Box
          sx={{ visibility: isLoading ? "hidden" : "visible" }}
          role="status"
        >
          <Button
            variant="contained"
            startIcon={<MicrosoftLogo />}
            sx={(theme) => ({
              backgroundColor: theme.palette.common.black,
            })}
            onClick={handleLogin}
          >
            Login with Microsoft
          </Button>
        </Box>
      </Card>
    </Box>
  )
}
