import SelectAllIcon from "@mui/icons-material/Add"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FilterOptionsState,
  IconButton,
  ListItem,
  TextField,
} from "@mui/material"
import React, { useCallback, useContext } from "react"
import { Controller, FieldError } from "react-hook-form"
import { LabelsField } from "~/labels/views/labels-field.view"
import {
  GuaranteeOfOriginSupportType,
  guaranteeOfOriginSupportTypeLabels,
} from "~/proposals/models/product.model"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { TechnologiesField } from "~/technologies/views/technologies-field.view"
import { YearField } from "./proposal.year-field"

const allSupportTypes: GuaranteeOfOriginSupportType[] = [
  GuaranteeOfOriginSupportType.SUPPORT,
  GuaranteeOfOriginSupportType.NO_SUPPORT,
]

function SupportField() {
  const { control, getValues, setValue, watch } =
    useContext(ProposalFormContext)

  const selectedSupportTypes = watch(
    "product.supportTypes",
    getValues("product.supportTypes"),
  )

  const selectAll = useCallback(() => {
    setValue("product.supportTypes", allSupportTypes)
  }, [allSupportTypes])

  const filterOptions = useCallback(
    (
      options: GuaranteeOfOriginSupportType[],
      { inputValue }: FilterOptionsState<GuaranteeOfOriginSupportType>,
    ) => {
      return options.filter((option) => {
        return option.toLowerCase().includes(inputValue.toLowerCase())
      })
    },
    [],
  )

  const renderInput = useCallback(
    (name: string, error: FieldError | undefined) =>
      (params: AutocompleteRenderInputParams) => {
        const showSelectAllBtn =
          selectedSupportTypes.length < allSupportTypes.length
        let endAdornment = null

        if (React.isValidElement(params.InputProps.endAdornment)) {
          endAdornment = React.cloneElement(params.InputProps.endAdornment, {
            ...params.InputProps.endAdornment.props,
            children: showSelectAllBtn
              ? [
                  <IconButton
                    key={`${name}-MuiAutocomplete-selectAllIndicator`}
                    size="medium"
                    onClick={selectAll}
                    aria-label="Select all"
                    title="Select all"
                    className="MuiAutocomplete-selectAllIndicator"
                    sx={{ visibility: "hidden", p: "3px", mr: "-2px" }}
                  >
                    <SelectAllIcon sx={{ fontSize: "1.35rem" }} />
                  </IconButton>,
                  ...params.InputProps.endAdornment.props.children,
                ]
              : params.InputProps.endAdornment.props.children,
          })
        }

        return (
          <TextField
            {...params}
            name={name}
            label="Support Types"
            error={Boolean(error)}
            helperText={error?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment,
            }}
          />
        )
      },
    [allSupportTypes, selectedSupportTypes, selectAll],
  )

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: GuaranteeOfOriginSupportType,
    ) => {
      return (
        <ListItem key={option} {...props}>
          {guaranteeOfOriginSupportTypeLabels.get(option)}
        </ListItem>
      )
    },
    [],
  )

  return (
    <Controller
      control={control}
      name="product.supportTypes"
      render={({
        fieldState: { error },
        field: { name, value: selectedSupportTypes, onChange },
      }) => {
        return (
          <Autocomplete
            options={allSupportTypes}
            getOptionLabel={(supportType) =>
              guaranteeOfOriginSupportTypeLabels.get(supportType)!
            }
            filterOptions={filterOptions}
            renderInput={renderInput(name, error)}
            renderOption={renderOption}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            multiple
            disableCloseOnSelect
            size="small"
            fullWidth
            sx={{
              width: "50%",
              ".Mui-focused .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedSupportTypes.length < allSupportTypes.length
                    ? "visible"
                    : "hidden",
              },
              "&:hover .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedSupportTypes.length === allSupportTypes.length
                    ? "hidden"
                    : "visible",
              },
            }}
            value={selectedSupportTypes}
          />
        )
      }}
    />
  )
}

export function GoFields() {
  const { control } = useContext(ProposalFormContext)

  return (
    <>
      {/* <Box sx={{ display: "flex", gap: 2 }}>
        <VolumeField unit="MWh" />
        <Box sx={{ width: "50%" }} />
      </Box> */}
      <Box sx={{ display: "flex", gap: 2 }}>
        <LabelsField />
        <TechnologiesField />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <SupportField />
        <Controller
          control={control}
          name="product.productionYear.from"
          render={({ fieldState, field: { value, onChange } }) => {
            return (
              <Box sx={{ width: "50%" }}>
                <YearField
                  error={fieldState.error?.message}
                  label="Production year"
                  value={value}
                  onChange={onChange}
                />
              </Box>
            )
          }}
        />{" "}
      </Box>
    </>
  )
}
