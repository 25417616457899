import { useCallback, useEffect, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import type { Attachment } from "./attachment.model"

type State = {
  attachments: Attachment[]
  isLoading: boolean
  error: string | null
}

export function useFilesStore() {
  const {
    filesModule: { store, service },
  } = useAppContext()
  const [state, setState] = useState<State>({
    attachments: withSuccess(store.state, [], ({ attachments }) => attachments),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })
  const [download, setDownload] = useState<{
    fileId: string
    file: File
  } | null>(null)

  useEffect(() => {
    let url: string | null = null

    if (download) {
      const { fileId, file } = download
      url = URL.createObjectURL(file)
      const a = document.createElement("a")
      a.href = url
      a.download = fileId.split("-filename-")[1]
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
      setDownload(null)
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url)
        setDownload(null)
      }
    }
  }, [download])

  store.addListener(
    match({
      onInitial: () => {
        setState({
          attachments: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ attachments: fileNames }) => {
        setState({ attachments: fileNames, isLoading: false, error: null })
      },
    }),
  )

  const uploadFiles = useCallback(async (files: File[]) => {
    await service.uploadFiles(files)
  }, [])

  const downloadFile = useCallback(async (fileId: string) => {
    const file = await service.downloadFile(fileId)

    if (file) {
      setDownload({ fileId, file })
    }
  }, [])

  const setFiles = useCallback((attachments: Attachment[]) => {
    service.setFiles(attachments)
  }, [])

  const clearFiles = useCallback(() => {
    service.clearFiles()
  }, [])

  return { state, actions: { clearFiles, downloadFile, setFiles, uploadFiles } }
}
