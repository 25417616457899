import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { Sdg } from "./sdg.model"

type State = {
  sdgs: Sdg[]
  isLoading: boolean
  error: string | null
}

export const useSdgsStore = () => {
  const {
    sdgsModule: { service, store },
  } = useAppContext()
  const [state, setState] = useState<State>({
    sdgs: withSuccess(store.state, [], ({ sdgs }) => sdgs),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          sdgs: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ sdgs }) => {
        setState({ sdgs, isLoading: false, error: null })
      },
    }),
  )

  const fetchSdgs = useCallback(async () => {
    await service.fetchAll()
  }, [service])

  return {
    state,
    actions: { fetchSdgs },
  }
}
