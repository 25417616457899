import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { InstrumentsDto } from "./dtos/instruments.dto"
import type { InstrumentsConfig } from "./instruments.config"
import type { InstrumentsStore } from "./instruments.store"

export class InstrumentsService {
  private readonly name = "InstrumentsService"

  constructor(
    private readonly httpService: HttpService,
    private readonly instrumentsConfig: InstrumentsConfig,
    private readonly instrumentsStore: InstrumentsStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.instrumentsStore.state)) {
      return Logger.info(
        `${id} Instruments are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.instrumentsStore.state)) {
      return Logger.info(
        `${id} Instruments found in cache, not fetching again.`,
      )
    }

    try {
      this.instrumentsStore.state = loading

      const { errors, data: instruments } = await this.httpService.get(
        this.instrumentsConfig.apiUrl,
        InstrumentsDto,
      )

      if (errors.length) {
        const msg = "Invalid instruments"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.instrumentsStore.state = failure(msg)
        return
      }

      this.instrumentsStore.state = success({ instruments })
    } catch (error) {
      const msg = "Failed to fetch instruments"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.instrumentsStore.state = failure(msg)
    }
  }
}
