import { Type } from "class-transformer"
import {
  ArrayNotEmpty,
  Equals,
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsString,
  ValidateNested,
} from "class-validator"
import { Company } from "~/companies/company.model"
import { Country } from "~/countries/country.model"
import { Attachment } from "~/files/attachment.model"
import { User } from "~/user/user.model"
import { IsNullable } from "~/utils/utils.validation"
import {
  BasePriceRequest,
  FirmPriceRequest,
  IndicativePriceRequest,
  OngoingPriceRequest,
  PriceRequestKind,
  RfqPriceRequest,
  type PriceRequest,
} from "../price-requests/price-request.model"
import {
  BaseProduct,
  BiogasProduct,
  CarbonProduct,
  GuaranteeOfOriginProduct,
  IntRecProduct,
  ProductKind,
  UsRecProduct,
  WhiteCertProduct,
  type Product,
} from "./product.model"

export enum State {
  INITIAL = "INITIAL",
  MATCHED = "MATCHED",
  CLOSED_WON = "CLOSED_WON",
  CLOSED_LOST = "CLOSED_LOST",
  ARCHIVED = "ARCHIVED",
}

export enum ProposalKind {
  OFFER = "OFFER",
  REQUEST = "REQUEST",
}

export class BaseProposal {
  @IsEnum(State)
  readonly state: State

  @IsEnum(ProposalKind)
  readonly kind: ProposalKind

  @ValidateNested({ each: true })
  @Type(() => Attachment)
  @IsArray()
  readonly attachments: Attachment[]

  @ValidateNested()
  @Type(() => User)
  @IsNotEmpty()
  readonly createdBy: User

  @ValidateNested()
  @Type(() => Company)
  @IsNotEmpty({ message: "Select a company" })
  readonly company: Company

  @ValidateNested({ each: true })
  @Type(() => Country)
  @ArrayNotEmpty({ message: "Select at least one country" })
  @IsArray()
  readonly countries: Country[]

  @IsString()
  @IsNullable()
  readonly notes: string | null

  @IsNumber()
  @IsNotEmpty({ message: "Enter a volume" })
  readonly volume: number

  @ValidateNested()
  @Type(() => BasePriceRequest, {
    discriminator: {
      property: "kind",
      subTypes: [
        { value: FirmPriceRequest, name: PriceRequestKind.FIRM },
        { value: RfqPriceRequest, name: PriceRequestKind.RFQ },
        { value: IndicativePriceRequest, name: PriceRequestKind.INDICATIVE },
        { value: OngoingPriceRequest, name: PriceRequestKind.ONGOING },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @IsNotEmpty()
  readonly priceRequest: PriceRequest

  @ValidateNested()
  @Type(() => BaseProduct, {
    discriminator: {
      property: "kind",
      subTypes: [
        { value: BiogasProduct, name: ProductKind.BIOGAS },
        { value: CarbonProduct, name: ProductKind.CARBON },
        { value: WhiteCertProduct, name: ProductKind.WHITE_CERTIFICATE },
        {
          value: GuaranteeOfOriginProduct,
          name: ProductKind.GUARANTEE_OF_ORIGIN,
        },
        { value: IntRecProduct, name: ProductKind.INT_REC },
        { value: UsRecProduct, name: ProductKind.US_REC },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @IsNotEmpty()
  readonly product: Product
}

export class InitialProposal extends BaseProposal {
  @IsNotEmpty()
  @IsString()
  readonly _id: string

  @IsNotEmpty()
  @IsString()
  readonly go2Id: string

  @Type(() => Date)
  readonly createdAt: Date

  @Type(() => Date)
  readonly updatedAt: Date

  @Equals(State.INITIAL)
  declare readonly state: State.INITIAL
}

export class MatchedProposal extends BaseProposal {
  @IsNotEmpty()
  @IsString()
  readonly _id: string

  @IsNotEmpty()
  @IsString()
  readonly go2Id: string

  @Type(() => Date)
  readonly createdAt: Date

  @Type(() => Date)
  readonly updatedAt: Date

  @Equals(State.MATCHED)
  declare readonly state: State.MATCHED

  @IsString({ each: true })
  @IsArray()
  readonly matches: string[]
}

export type Proposal = InitialProposal | MatchedProposal
