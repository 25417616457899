import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Label } from "./label.model"

type Err = string
type Ok = {
  labels: Label[]
}

export class LabelsStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("LabelsStore", initial)
  }
}
