import { AsyncStore } from "~/store/async-store"
import { initial } from "~/store/async-store.utils"
import { Sdg } from "./sdg.model"

type Err = string
type Ok = {
  sdgs: Sdg[]
}

export class SdgsStore extends AsyncStore<Err, Ok> {
  constructor() {
    super("SdgsStore", initial)
  }
}
