import { NavigationClient, NavigationOptions } from "@azure/msal-browser"
import { NavigateFunction } from "react-router-dom"

export class NavigationService extends NavigationClient {
  private navigate: NavigateFunction = () => {}

  constructor() {
    super()
  }

  public set navigateFunction(navigate: NavigateFunction) {
    this.navigate = navigate
  }

  public async navigateInternal(url: string, options?: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, "")

    if (options?.noHistory) {
      this.navigate(relativePath, { replace: true })
    } else {
      this.navigate(relativePath)
    }

    return false
  }
}
