import { Type } from "class-transformer"
import { ArrayNotEmpty, IsArray, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Notification } from "../notification.model"

export class NotificationsDto implements HttpDto<Notification[]> {
  @ValidateNested({ each: true })
  @ArrayNotEmpty()
  @IsArray()
  @Type(() => Notification)
  declare readonly data: Notification[]
}
