export class RegistriesConfig {
  private readonly _registriesApiUrl: string

  constructor() {
    this._registriesApiUrl = "/api/registries"
  }

  public get registriesApiUrl() {
    return this._registriesApiUrl
  }
}
