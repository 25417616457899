import SelectAllIcon from "@mui/icons-material/Add"
import {
  Autocomplete,
  Chip,
  IconButton,
  ListItem,
  TextField,
  type AutocompleteRenderGetTagProps,
  type AutocompleteRenderInputParams,
} from "@mui/material"
import React, { useCallback, useContext } from "react"
import { Controller, type FieldError } from "react-hook-form"
import { Tooltip } from "~/ui/ui.tooltip.component"
import { ProposalFormContext } from "../../proposals/proposals.context"
import type { Instrument } from "../instrument.model"
import { useInstrumentsStore } from "../instruments.use-instruments-store.hook"

export function InstrumentsField() {
  const { control, getValues, setValue, watch } =
    useContext(ProposalFormContext)
  const {
    state: { instruments: allInstruments },
  } = useInstrumentsStore()
  const productKind = watch("product.kind", getValues("product.kind"))
  const productInstruments = allInstruments.filter((instrument) => {
    return instrument.products.includes(productKind)
  })
  const selectedInstruments = watch(
    "product.instruments",
    getValues("product.instruments"),
  )

  const selectAll = useCallback(() => {
    setValue("product.instruments", productInstruments)
  }, [productInstruments])

  const renderInput = useCallback(
    (name: string, error: FieldError | undefined) =>
      (params: AutocompleteRenderInputParams) => {
        const showSelectAllBtn =
          selectedInstruments.length < productInstruments.length
        let endAdornment = null

        if (React.isValidElement(params.InputProps.endAdornment)) {
          endAdornment = React.cloneElement(params.InputProps.endAdornment, {
            ...params.InputProps.endAdornment.props,
            children: showSelectAllBtn
              ? [
                  <IconButton
                    key={`${name}-MuiAutocomplete-selectAllIndicator`}
                    size="medium"
                    onClick={selectAll}
                    aria-label="Select all"
                    title="Select all"
                    className="MuiAutocomplete-selectAllIndicator"
                    sx={{ visibility: "hidden", p: "3px", mr: "-2px" }}
                  >
                    <SelectAllIcon sx={{ fontSize: "1.35rem" }} />
                  </IconButton>,
                  ...params.InputProps.endAdornment.props.children,
                ]
              : params.InputProps.endAdornment.props.children,
          })
        }

        return (
          <TextField
            {...params}
            name={name}
            label="Instruments"
            error={Boolean(error)}
            helperText={error?.message}
            required
            InputProps={{
              ...params.InputProps,
              endAdornment,
            }}
          />
        )
      },
    [productInstruments, selectedInstruments, selectAll],
  )

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, label: Instrument) => {
      if (label.acronym) {
        return (
          <Tooltip key={label._id} title={label.name} placement="left">
            <ListItem {...props}>{label.acronym}</ListItem>
          </Tooltip>
        )
      }

      return (
        <ListItem {...props} key={label._id}>
          {label.name}
        </ListItem>
      )
    },
    [],
  )

  const renderTags = useCallback(
    (instruments: Instrument[], getTagProps: AutocompleteRenderGetTagProps) => {
      return instruments.map(({ acronym, name }, index) => {
        const tagProps = getTagProps({ index })
        return (
          <Chip {...tagProps} key={name} label={acronym ?? name} size="small" />
        )
      })
    },
    [],
  )

  return (
    <Controller
      control={control}
      name="product.instruments"
      render={({
        fieldState: { error },
        field: { name, value: selectedInstruments, onChange },
      }) => {
        return (
          <Autocomplete
            options={productInstruments}
            disableCloseOnSelect
            getOptionLabel={(instrument) => instrument.name}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            renderInput={renderInput(name, error)}
            renderOption={renderOption}
            renderTags={renderTags}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            multiple
            size="small"
            fullWidth
            sx={{
              width: "50%",
              ".Mui-focused .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedInstruments.length < productInstruments.length
                    ? "visible"
                    : "hidden",
              },
              "&:hover .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedInstruments.length === productInstruments.length
                    ? "hidden"
                    : "visible",
              },
            }}
            value={selectedInstruments}
          />
        )
      }}
    />
  )
}
