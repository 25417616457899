import { AuthModule } from "~/auth/auth.module"
import { HttpService } from "./http.service"

export class HttpModule {
  private readonly _service: HttpService

  constructor(private readonly authModule: AuthModule) {
    this._service = new HttpService(this.authModule.service)
  }

  get service() {
    return this._service
  }
}
