import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "../../proposals.context"
import { ProductMenu } from "../product-menu.view"

export const ProductKindField = () => {
  const { control } = useContext(ProposalFormContext)

  return (
    <Controller
      control={control}
      name="product.kind"
      render={({ field: { onChange } }) => {
        return <ProductMenu onChange={onChange} />
      }}
    />
  )
}
