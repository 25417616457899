import { Box, useTheme } from "@mui/material"
import { PropsWithChildren } from "react"
import { Alert } from "./components/ui.alert.component"
import { Footer } from "./components/ui.footer.component"
import { Header } from "./components/ui.header.component"
import { Main } from "./components/ui.main.component"

export function AppShell({ children }: PropsWithChildren) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        margin: "0 auto",
        maxWidth: "1920px",
        minHeight: "100vh",
        color: theme.palette.grey[900],
        overflow: "hidden",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Header />
      <Main>
        <Alert />
        {children}
      </Main>
      <Footer />
    </Box>
  )
}
