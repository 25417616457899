import { createContext } from "react"
import {
  Control,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form"
import { type BaseProposal, type Proposal } from "./models/proposal.model"

export type FormMode =
  | { kind: "create" }
  | { kind: "update"; proposal: Proposal }
  | {
      kind: "duplicate"
      proposal: BaseProposal
    }

type ProposalFormContext = {
  control: Control<Proposal>
  mode: FormMode
  getFieldState: UseFormGetFieldState<Proposal>
  getValues: UseFormGetValues<Proposal>
  register: UseFormRegister<Proposal>
  setValue: UseFormSetValue<Proposal>
  trigger: UseFormTrigger<Proposal>
  watch: UseFormWatch<Proposal>
}

/**
 * HERE THERE BE DRAGONS
 *
 * This context can only be used inside a form that uses react-hook-form.
 * Using it outside of a form will result in an error.
 */
export const ProposalFormContext = createContext<ProposalFormContext>(
  {} as ProposalFormContext,
)
