import { useEffect } from "react"
import { useIsAuthenticated } from "~/auth/auth.use-is-authenticated.hook"
import { useUserStore } from "~/user/user.use-user.hook"

declare global {
  interface Window {
    feedbucketConfig: {
      reporter: {
        name?: string
        email?: string
        token?: string
      }
    }
  }
}

export default function FeedBucket() {
  const { isAuthenticated } = useIsAuthenticated()
  const {
    state: { user },
  } = useUserStore()

  useEffect(() => {
    if (!isAuthenticated || !user) {
      return
    }

    window.feedbucketConfig = {
      reporter: {
        name: user?.name,
        email: user?.email,
        token: user?._id,
      },
    }
  }, [isAuthenticated, user])

  useEffect(() => {
    if (!isAuthenticated || !user) {
      return
    }

    const s = document.createElement("script")
    s.defer = true
    s.src = "https://cdn.feedbucket.app/assets/feedbucket.js"
    s.dataset.feedbucket = "dbxCHCA0516Qqtkseccl"
    document.head.appendChild(s)

    return () => {
      document.head.removeChild(s)
    }
  }, [isAuthenticated, user])

  return null
}
