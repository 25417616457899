import AttachFileIcon from "@mui/icons-material/AttachFile"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { CircularProgress, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { styled, useTheme } from "@mui/material/styles"
import { useCallback, useRef, useState } from "react"
import { useFilesStore } from "./files.use-files-store.hook"

type Props = {
  // Required
  name: string

  // Optional
  acceptedTypes?: string[]
  label?: string
  maxFileSize?: number // Maximum file size in MB
  optional?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

type Valid = {
  valid: true
  file: File
}

type Invalid = {
  valid: false
  error: string
}

type Result = Valid | Invalid

function isInvalid(result: Result): result is Invalid {
  return result.valid === false
}

const MAX_FILE_SIZE = 30
const toMB = (bytes: number) => bytes / 1024 / 1024

const ACCEPTED_FILE_TYPES = [
  // Documents
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

  // Spreadsheets
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

  // PDF
  "application/pdf",

  // Images
  "image/jpeg",
  "image/png",
  "image/gif",
]

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
})

export function FilesUpload({
  // Required
  name,

  // Optional
  accept = ACCEPTED_FILE_TYPES.join(","),
  acceptedTypes = ACCEPTED_FILE_TYPES,
  disabled,
  maxFileSize = MAX_FILE_SIZE,
  ...props
}: Props) {
  const {
    state: { attachments, isLoading },
    actions: { uploadFiles },
  } = useFilesStore()
  const [error, setError] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [dragIsOver, setDragIsOver] = useState(false)
  const theme = useTheme()

  const validateFile = useCallback(
    (file: File): Result => {
      if (toMB(file.size) > maxFileSize) {
        return {
          valid: false,
          error: `File size must be less than ${maxFileSize}MB`,
        }
      }

      const acceptableType = acceptedTypes.some((acceptedType) => {
        return acceptedType === file.type
      })

      if (!acceptableType) {
        return {
          valid: false,
          error: `File must be of type ${acceptedTypes}`,
        }
      }

      return { valid: true, file }
    },
    [acceptedTypes, maxFileSize],
  )

  const upload = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const validated = files.map(validateFile)

        if (validated.some((result) => result.valid === false)) {
          const failures = validated.filter(isInvalid).map(({ error }) => error)

          setError(failures.join(", "))
          return
        }

        await uploadFiles(files)
      }
    },
    [uploadFiles, validateFile],
  )

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      await upload(Array.from(event.currentTarget.files ?? []))
    },
    [upload],
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault()
      event.stopPropagation()
      !disabled && setDragIsOver(true)
    },
    [disabled],
  )

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault()
      event.stopPropagation()
      !disabled && setDragIsOver(false)
    },
    [disabled],
  )

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault()
      event.stopPropagation()

      if (disabled) {
        return
      }

      setDragIsOver(false)
      upload(Array.from(event.dataTransfer.files))
    },
    [disabled, upload],
  )

  const getContent = useCallback(() => {
    if (isLoading) {
      return <CircularProgress color="primary" size="3rem" />
    }

    if (attachments.length > 0) {
      return (
        <>
          <AttachFileIcon sx={{ mb: "1rem" }} />
          {attachments.map(({ name }) => (
            <Typography
              component="p"
              key={name}
              sx={{
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                textAlign: "center",
              }}
            >
              {name.split("-filename-")[1]}
            </Typography>
          ))}
        </>
      )
    }

    return (
      <>
        <CloudUploadIcon />
        <Typography
          component="p"
          sx={{
            py: "1rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <Typography component="span" fontSize="inherit" fontWeight="bold">
            Click to upload
          </Typography>
          <span> or drag and drop</span>
        </Typography>
        <Typography
          component="p"
          sx={{
            color: theme.palette.grey[500],
            fontSize: "0.75rem",
            fontWeight: "bold",
            lineHeight: "1rem",
          }}
        >
          Max. File Size: {maxFileSize}MB
        </Typography>
      </>
    )
  }, [isLoading, attachments, maxFileSize, theme.palette.grey])

  return (
    <>
      <Box
        component="label"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Box
          sx={{
            backgroundColor: dragIsOver
              ? theme.palette.green[50]
              : theme.palette.common.white,
            border: "1px solid",
            borderColor: error
              ? theme.palette.red[500]
              : dragIsOver
                ? theme.palette.green[500]
                : theme.palette.grey[400],
            borderRadius: "0.25rem",
            color: theme.palette.grey[600],
            cursor: disabled ? "default" : "pointer",
            height: "100%",
            pb: "1.5rem",
            pt: "1.25rem",
            width: "100%",
            ":hover": {
              borderColor: disabled
                ? theme.palette.grey[400]
                : theme.palette.common.black,
            },
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              opacity: disabled ? 0.5 : 1,
              width: "100%",
            }}
          >
            {getContent()}
          </Box>
        </Box>

        <VisuallyHiddenInput
          accept={accept}
          disabled={disabled}
          id={name}
          ref={fileInputRef}
          type="file"
          multiple
          onChange={handleChange}
          {...props}
        />
      </Box>
    </>
  )
}
