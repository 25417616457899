import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import type { Language, Region } from "./locale.model"

export function useLocaleStore() {
  const { localeModule } = useAppContext()
  const [state, setState] = useState(localeModule.store.state)

  localeModule.store.addListener(setState)

  const setLanguage = useCallback(
    (language: Language) => {
      localeModule.service.setLanguage(language)
    },
    [localeModule.service],
  )

  const setRegion = useCallback(
    (region: Region) => {
      localeModule.service.setRegion(region)
    },
    [localeModule.service],
  )

  return { state, actions: { setLanguage, setRegion } }
}
