import { InputAdornment, TextField } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { NumericFormat } from "react-number-format"
import { useLocaleStore } from "~/locale/locale.use-locale-store.hook"
import { getSeparators } from "~/locale/locale.utils"
import { ProposalFormContext } from "../../proposals.context"

type Props = {
  unit: "Tons" | "MWh" | "KWh"
}

export function VolumeField({ unit }: Props) {
  const { state: locale } = useLocaleStore()
  const { thousands, decimal } = getSeparators(locale)
  const { control } = useContext(ProposalFormContext)

  return (
    <Controller
      control={control}
      name="volume"
      render={({ fieldState: { error }, field: { name, value, onChange } }) => {
        return (
          <NumericFormat
            customInput={TextField}
            decimalScale={0}
            decimalSeparator={decimal}
            error={Boolean(error)}
            helperText={error?.message}
            label="Volume"
            name={name}
            required
            size="small"
            fullWidth
            thousandSeparator={thousands}
            value={value ?? null}
            onValueChange={({ floatValue }) => {
              onChange(floatValue ?? null)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
            }}
            sx={{ width: "50%" }}
          />
        )
      }}
    />
  )
}
