import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { RegistriesDto } from "./dtos/registries.dto"
import type { RegistriesConfig } from "./registries.config"
import type { RegistriesStore } from "./registries.store"

export class RegistriesService {
  private readonly name = "RegistriesService"

  constructor(
    private readonly httpService: HttpService,
    private readonly registriesConfig: RegistriesConfig,
    private readonly registriesStore: RegistriesStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.registriesStore.state)) {
      return Logger.info(
        `${id} Registries are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.registriesStore.state)) {
      return Logger.info(`${id} Registries found in cache, not fetching again.`)
    }

    try {
      this.registriesStore.state = loading

      const { errors, data: registries } = await this.httpService.get(
        this.registriesConfig.registriesApiUrl,
        RegistriesDto,
      )

      if (errors.length) {
        const msg = "Invalid registries"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.registriesStore.state = failure(msg)
        return
      }

      this.registriesStore.state = success({ registries })
    } catch (error) {
      const msg = "Failed to fetch registries"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.registriesStore.state = failure(msg)
    }
  }
}
