import { Button, ButtonGroup } from "@mui/material"
import { useCallback } from "react"
import {
  productKinds,
  type ProductKind,
} from "~/proposals/models/product.model"
import { formatProductKind } from "~/proposals/proposal.utils"
import { useUiStore } from "~/ui/ui.use-ui-store.hook"

type Props = {
  onChange?: (kind: ProductKind) => void
}

export function ProductMenu({ onChange = () => {} }: Props) {
  const {
    state: { quickFilters },
    actions: { setQuickFilters },
  } = useUiStore()

  const handleChange = useCallback(
    (productKind: ProductKind) => {
      setQuickFilters({ ...quickFilters, productKind })
      onChange(productKind)
    },
    [quickFilters, onChange, setQuickFilters],
  )

  return (
    <ButtonGroup>
      {productKinds.map((productKind) => (
        <Button
          key={productKind}
          value={productKind}
          onClick={() => handleChange(productKind)}
          variant={
            productKind === quickFilters.productKind ? "contained" : "outlined"
          }
        >
          {formatProductKind(productKind)}
        </Button>
      ))}
    </ButtonGroup>
  )
}
