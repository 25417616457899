import MuiAvatar, { AvatarProps } from "@mui/material/Avatar"
import { Tooltip } from "~/ui/ui.tooltip.component"
import { User } from "./user.model"

type Props = {
  user: User
} & AvatarProps

export function Avatar({ user, ...props }: Props) {
  return (
    <Tooltip title={user.name}>
      <MuiAvatar
        alt={user.name}
        src={user.avatar ?? undefined}
        sx={{
          width: 28,
          height: 28,
          fontSize: "0.875rem",
          fontWeight: "bold",
          color: "white",
        }}
        {...props}
      >
        {user.email.substring(0, 2).toUpperCase()}
      </MuiAvatar>
    </Tooltip>
  )
}
