import { Box, useTheme } from "@mui/material"
import { useUiStore } from "../ui.use-ui-store.hook"

export function Footer() {
  const {
    state: { footerContent },
  } = useUiStore()
  const theme = useTheme()

  return (
    <Box
      component="footer"
      sx={{
        margin: "0 auto",
        maxWidth: "1920px",
        position: "fixed",
        bottom: 0,
        maxHeight: "4rem",
        width: "100%",
        backgroundColor: theme.palette.grey[50],
        padding: "0 1.5rem",
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      {footerContent}
    </Box>
  )
}
