import { Type } from "class-transformer"
import { ArrayNotEmpty, IsArray, ValidateNested } from "class-validator"
import type { HttpDto } from "~/http/http.models"
import { Registry } from "../registry.model"

export class RegistriesDto implements HttpDto<Registry[]> {
  @Type(() => Registry)
  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  readonly data: Registry[]
}
