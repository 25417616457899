import { Type } from "class-transformer"
import { IsEnum, IsNotEmpty, IsNumber, ValidateNested } from "class-validator"
import { Currency } from "~/currencies/currency.model"
import { IsNullable } from "~/utils/utils.validation"

export enum PriceRequestKind {
  FIRM = "FIRM",
  RFQ = "RFQ",
  INDICATIVE = "INDICATIVE",
  ONGOING = "ONGOING",
}

export const priceRequestKinds = [
  PriceRequestKind.FIRM,
  PriceRequestKind.RFQ,
  PriceRequestKind.INDICATIVE,
  PriceRequestKind.ONGOING,
]

export class BasePriceRequest {
  @IsEnum(PriceRequestKind)
  readonly kind: PriceRequestKind
}

export class FirmPriceRequest extends BasePriceRequest {
  readonly kind = PriceRequestKind.FIRM

  @Type(() => Date)
  @IsNotEmpty({ message: "Select a deadline date" })
  readonly deadline: Date

  @Type(() => Date)
  @IsNotEmpty({ message: "Select a delivery date" })
  readonly delivery: Date

  @Type(() => Date)
  @IsNotEmpty({ message: "Select a validity date" })
  readonly validity: Date

  @ValidateNested()
  @Type(() => Currency)
  @IsNotEmpty({ message: "Select a currency" })
  readonly currency: Currency

  @IsNumber()
  @IsNotEmpty({ message: "Enter a price" })
  readonly price: number
}

export class RfqPriceRequest extends BasePriceRequest {
  readonly kind = PriceRequestKind.RFQ

  @Type(() => Date)
  @IsNotEmpty({ message: "Select a deadline date" })
  readonly deadline: Date

  @Type(() => Date)
  @IsNotEmpty({ message: "Select a delivery date" })
  readonly delivery: Date

  @Type(() => Date)
  @IsNotEmpty({ message: "Select a validity date" })
  readonly validity: Date

  @IsNullable()
  readonly currency: null

  @IsNullable()
  readonly price: null
}

export class IndicativePriceRequest extends BasePriceRequest {
  readonly kind = PriceRequestKind.INDICATIVE

  @Type(() => Date)
  @IsNullable()
  readonly deadline: Date | null

  @Type(() => Date)
  @IsNotEmpty({ message: "Select a delivery date" })
  readonly delivery: Date

  @Type(() => Date)
  @IsNullable()
  readonly validity: Date | null

  @ValidateNested()
  @Type(() => Currency)
  @IsNullable()
  readonly currency: Currency | null

  @IsNumber()
  @IsNullable()
  readonly price: number | null
}

export class OngoingPriceRequest extends BasePriceRequest {
  readonly kind = PriceRequestKind.ONGOING

  @Type(() => Date)
  @IsNullable()
  readonly deadline: Date | null

  @Type(() => Date)
  @IsNullable()
  readonly delivery: Date | null

  @Type(() => Date)
  @IsNullable()
  readonly validity: Date | null

  @ValidateNested()
  @Type(() => Currency)
  @IsNullable()
  readonly currency: Currency | null

  @IsNumber()
  @IsNullable()
  readonly price: number | null
}

export type PriceRequest =
  | FirmPriceRequest
  | RfqPriceRequest
  | IndicativePriceRequest
  | OngoingPriceRequest
