import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"

type State = {
  featureFlags: Record<string, boolean>
  isLoading: boolean
  error: string | null
}

export function useFeatureFlags() {
  const {
    featureFlagsModule: { service, store },
  } = useAppContext()
  const [state, setState] = useState<State>({
    featureFlags: withSuccess(
      store.state,
      {},
      ({ featureFlags }) => featureFlags,
    ),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          featureFlags: {},
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ featureFlags }) => {
        setState({ featureFlags, isLoading: false, error: null })
      },
    }),
  )

  const getFeatureFlag = useCallback(
    async (flag: string) => {
      return service.findOne(flag)
    },
    [service],
  )

  return { state, actions: { getFeatureFlag } }
}
