import { DateTimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "~/proposals/proposals.context"
import { PriceRequestKind } from "../price-request.model"

export const ValidityField = () => {
  const { control, getValues, watch } = useContext(ProposalFormContext)
  const priceRequestKind = watch(
    "priceRequest.kind",
    getValues("priceRequest.kind"),
  )

  return (
    <Controller
      control={control}
      name="priceRequest.validity"
      render={({
        fieldState: { error },
        field: { name, ref, value, onChange },
      }) => {
        return (
          <DateTimePicker
            label="Validity"
            value={value ? dayjs(value) : null}
            inputRef={ref}
            onChange={(value) => onChange(value?.toDate() ?? null)}
            slotProps={{
              textField: {
                name,
                error: Boolean(error),
                helperText: error?.message,
                size: "small",
                fullWidth: true,
                required: priceRequestKind === PriceRequestKind.FIRM,
                sx: { width: "50%" },
              },
            }}
          />
        )
      }}
    />
  )
}
