import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { Technology } from "./technology.model"

type State = {
  technologies: Technology[]
  isLoading: boolean
  error: string | null
}

export const useTechnologiesStore = () => {
  const {
    technologiesModule: { service, store },
  } = useAppContext()
  const [state, setState] = useState<State>({
    technologies: withSuccess(
      store.state,
      [],
      ({ technologies }) => technologies,
    ),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          technologies: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ technologies }) => {
        setState({ technologies, isLoading: false, error: null })
      },
    }),
  )

  const fetchTechnologies = useCallback(async () => {
    await service.fetchAll()
  }, [service])

  return {
    state,
    actions: { fetchTechnologies },
  }
}
