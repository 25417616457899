import { ValidationError } from "class-validator"

export class Logger {
  public static info<T>(msg: string, value?: T): void {
    if (import.meta.env.MODE === "production") return

    if (typeof msg !== "string") {
      console.info(`TradingOS - [INFO] - ${JSON.stringify(msg)}`)
    } else {
      if (value == null) {
        console.info(`TradingOS - [INFO] - ${msg}`)
      } else {
        console.info(`TradingOS - [INFO] - ${msg}`, value)
      }
    }
  }

  public static warn<T>(msg: string | unknown, value?: T): void {
    if (import.meta.env.MODE === "production") return

    if (typeof msg !== "string") {
      console.warn(`TradingOS - [WARN] - ${JSON.stringify(msg)}`)
    } else {
      if (value == null) {
        console.warn(`TradingOS - [WARN] - ${msg}`)
      } else {
        console.warn(`TradingOS - [WARN] - ${msg}`, value)
      }
    }
  }

  public static error(error: Error | ValidationError[] | unknown): void {
    if (import.meta.env.MODE === "production") return

    if (error instanceof Error) {
      console.error(
        `TradingOS - [ERROR] - ${error.name} - ${error.message} - ${error.stack}`,
      )
    } else {
      console.error(`TradingOS - [ERROR]`)
      console.error(error)
    }
  }
}
