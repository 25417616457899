import { Navigate, Outlet } from "react-router-dom"
import { useIsAuthenticated } from "./auth.use-is-authenticated.hook"

export function ProtectedRoutes() {
  const { isAuthenticated } = useIsAuthenticated()

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  return <Outlet />
}
