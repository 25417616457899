import { ValidateIf, ValidationOptions } from "class-validator"

export function IsNullable(validationOptions?: ValidationOptions) {
  return ValidateIf((_object, value) => value !== null, validationOptions)
}

export function extractId({
  value,
}: {
  value: { _id?: string; id?: string } | null
}) {
  if (value === null) {
    return null
  }

  if (value._id) {
    return value._id
  } else if (value.id) {
    return value.id
  } else {
    return null
  }
}

export function extractIds({
  value = [],
}: {
  value: Array<{ _id?: string; id?: string }>
  key: string
}) {
  return value.map((value) => {
    if (value._id) {
      return value._id
    } else if (value.id) {
      return value.id
    } else {
      return null
    }
  })
}
