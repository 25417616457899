import { TextField } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { ProposalFormContext } from "../../proposals.context"

export function NotesField() {
  const { control } = useContext(ProposalFormContext)

  return (
    <Controller
      control={control}
      name="notes"
      render={({ field: { name, value, onChange } }) => {
        return (
          <TextField
            name={name}
            label="Notes"
            fullWidth
            multiline
            rows={5}
            value={value ?? ""}
            onChange={onChange}
          />
        )
      }}
    />
  )
}
