import type { HttpService } from "~/http/http.service"
import {
  failure,
  isLoading,
  isSuccess,
  loading,
  success,
} from "~/store/async-store.utils"
import { Logger } from "~/utils/utils.logger"
import { TechnologiesDto } from "./dtos/technologies.dto"
import type { TechnologiesConfig } from "./technologies.config"
import type { TechnologiesStore } from "./technologies.store"

export class TechnologiesService {
  private readonly name = "TechnologiesService"

  constructor(
    private readonly httpService: HttpService,
    private readonly technologiesConfig: TechnologiesConfig,
    private readonly technologiesStore: TechnologiesStore,
  ) {}

  public async fetchAll() {
    const id = `[${this.name}::fetchAll]`

    if (isLoading(this.technologiesStore.state)) {
      return Logger.info(
        `${id} Technologies are already being fetched, not fetching again.`,
      )
    }

    if (isSuccess(this.technologiesStore.state)) {
      return Logger.info(
        `${id} Technologies found in cache, not fetching again.`,
      )
    }

    try {
      this.technologiesStore.state = loading

      const { errors, data: technologies } = await this.httpService.get(
        this.technologiesConfig.apiUrl,
        TechnologiesDto,
      )

      if (errors.length) {
        const msg = "Invalid technologies"
        Logger.error(`${id} ${msg}`)
        Logger.error(errors)
        this.technologiesStore.state = failure(msg)
        return
      }

      this.technologiesStore.state = success({ technologies })
    } catch (error) {
      const msg = "Failed to fetch technologies"
      Logger.error(`${id} ${msg}`)
      Logger.error(error)
      this.technologiesStore.state = failure(msg)
    }
  }
}
