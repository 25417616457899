import { useCallback, useState } from "react"
import { useAppContext } from "~/app.use-app-context.hook"
import {
  isLoading,
  match,
  withFailure,
  withSuccess,
} from "~/store/async-store.utils"
import { Input } from "./input.model"

type State = {
  inputs: Input[]
  isLoading: boolean
  error: string | null
}

export const useInputsStore = () => {
  const {
    inputsModule: { service, store },
  } = useAppContext()
  const [state, setState] = useState<State>({
    inputs: withSuccess(store.state, [], ({ inputs: inputs }) => inputs),
    isLoading: isLoading(store.state),
    error: withFailure(store.state, null, (error) => error),
  })

  store.addListener(
    match({
      onInitial: () => {
        setState({
          inputs: [],
          isLoading: false,
          error: null,
        })
      },
      onLoading: () => {
        setState({ ...state, isLoading: true })
      },
      onFailure: (error) => {
        setState({ ...state, error, isLoading: false })
      },
      onSuccess: ({ inputs: inputs }) => {
        setState({ inputs, isLoading: false, error: null })
      },
    }),
  )

  const fetchInputs = useCallback(async () => {
    await service.fetchAll()
  }, [service])

  return {
    state,
    actions: { fetchInputs },
  }
}
