import SelectAllIcon from "@mui/icons-material/Add"
import {
  Autocomplete,
  Chip,
  IconButton,
  ListItem,
  TextField,
  type AutocompleteRenderGetTagProps,
  type AutocompleteRenderInputParams,
  type FilterOptionsState,
} from "@mui/material"
import React, { useCallback, useContext } from "react"
import { Controller, type FieldError } from "react-hook-form"
import { Tooltip } from "~/ui/ui.tooltip.component"
import { ProposalFormContext } from "../../proposals/proposals.context"
import { useTechnologiesStore } from "../technologies.use-technologies-store.hook"
import type { Technology } from "../technology.model"

export function TechnologiesField() {
  const { control, getValues, setValue, watch } =
    useContext(ProposalFormContext)
  const {
    state: { technologies: allTechnologies },
  } = useTechnologiesStore()
  const productKind = watch("product.kind", getValues("product.kind"))
  const productTechnologies = allTechnologies.filter((technology) => {
    return technology.products.includes(productKind)
  })
  const selectedTechnologies = watch(
    "product.technologies",
    getValues("product.technologies"),
  )

  const selectAll = useCallback(() => {
    setValue("product.technologies", productTechnologies)
  }, [productTechnologies])

  const filterOptions = useCallback(
    (options: Technology[], { inputValue }: FilterOptionsState<Technology>) => {
      return options.filter((option) => {
        return (
          option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.acronym?.toLowerCase().includes(inputValue.toLowerCase())
        )
      })
    },
    [],
  )

  const renderInput = useCallback(
    (name: string, error: FieldError | undefined) =>
      (params: AutocompleteRenderInputParams) => {
        const showSelectAllBtn =
          selectedTechnologies.length < productTechnologies.length
        let endAdornment = null

        if (React.isValidElement(params.InputProps.endAdornment)) {
          endAdornment = React.cloneElement(params.InputProps.endAdornment, {
            ...params.InputProps.endAdornment.props,
            children: showSelectAllBtn
              ? [
                  <IconButton
                    key={`${name}-MuiAutocomplete-selectAllIndicator`}
                    size="medium"
                    onClick={selectAll}
                    aria-label="Select all"
                    title="Select all"
                    className="MuiAutocomplete-selectAllIndicator"
                    sx={{ visibility: "hidden", p: "3px", mr: "-2px" }}
                  >
                    <SelectAllIcon sx={{ fontSize: "1.35rem" }} />
                  </IconButton>,
                  ...params.InputProps.endAdornment.props.children,
                ]
              : params.InputProps.endAdornment.props.children,
          })
        }

        return (
          <TextField
            {...params}
            name={name}
            label="Technologies"
            error={Boolean(error)}
            helperText={error?.message}
            required
            InputProps={{
              ...params.InputProps,
              endAdornment,
            }}
          />
        )
      },
    [productTechnologies, selectedTechnologies, selectAll],
  )

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: Technology) => {
      if (option.acronym) {
        return (
          <Tooltip key={option._id} title={option.name} placement="left">
            <ListItem {...props}>{option.acronym}</ListItem>
          </Tooltip>
        )
      }

      return (
        <ListItem key={option._id} {...props}>
          {option.name}
        </ListItem>
      )
    },
    [],
  )

  const renderTags = useCallback(
    (
      technologies: Technology[],
      getTagProps: AutocompleteRenderGetTagProps,
    ) => {
      return technologies.map(({ acronym, name }, index) => {
        const tagProps = getTagProps({ index })
        return (
          <Chip {...tagProps} key={name} label={acronym ?? name} size="small" />
        )
      })
    },
    [],
  )

  return (
    <Controller
      control={control}
      name="product.technologies"
      render={({
        fieldState: { error },
        field: { name, value: selectedTechnologies, onChange },
      }) => {
        return (
          <Autocomplete
            options={productTechnologies}
            getOptionLabel={(technology) => technology.name}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            filterOptions={filterOptions}
            renderInput={renderInput(name, error)}
            renderOption={renderOption}
            renderTags={renderTags}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            multiple
            disableCloseOnSelect
            size="small"
            fullWidth
            sx={{
              width: "50%",
              ".Mui-focused .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedTechnologies.length < productTechnologies.length
                    ? "visible"
                    : "hidden",
              },
              "&:hover .MuiAutocomplete-selectAllIndicator": {
                visibility:
                  selectedTechnologies.length === productTechnologies.length
                    ? "hidden"
                    : "visible",
              },
            }}
            value={selectedTechnologies}
          />
        )
      }}
    />
  )
}
